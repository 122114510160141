import { rem } from 'polished';
import { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { viewAppBreakpoints } from '@src/styles/breakpoints';

import { CardStyles, WalCard } from '../Card/Card';

interface TabElementProps {
  selected: boolean;
  status?: 'base' | 'alert' | 'warning';
  $noPadding?: boolean;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  $arrowPosition?: 'bottom' | 'right';
  $overrideBorder?: boolean;
  $removed?: boolean;
  cardStyle?: CardStyles;
  /** a custom arrow component that will replace the default one */
  customArrow?: ReactNode;
  /** Specific case where `cardStyle=default` has a border. It doesn't on the regular Card */
  link?: Pick<LinkProps, 'to' | 'state'>;
  dataTestId?: string;
  tabIndex?: number;
  ariaLabel?: string;
}

interface WalTabProps
  extends Omit<
    TabElementProps,
    '$arrowPosition' | '$overrideBorder' | '$minWidth' | '$maxWidth' | '$removed'
  > {
  children?: ReactNode;
  arrowPosition?: 'bottom' | 'right';
  overrideBorder?: boolean;

  removed?: boolean;
  onClick?: (e: MouseEvent) => void;
  disableArrow?: boolean;
}

interface ArrowProps {
  selected: boolean;
  status?: 'base' | 'alert' | 'warning';
}

const Tab = styled(WalCard)<TabElementProps>`
  cursor: pointer;
  border-color: ${({ theme, $overrideBorder }) => $overrideBorder && theme.color.baseOutline};
  padding: ${rem(8)} ${rem(12)};
  &:hover {
    transition: all 200ms ease;
    .arrow {
      transition: all 200ms ease;
    }
  }

  &:not(:disabled):hover {
    border-color: ${({ theme }) => theme.color.main};
  }

  @media ${viewAppBreakpoints.only.md} {
    display: flex;
    flex: 1;
    margin-right: 0;
  }

  ${({ selected, $removed, status, theme, $noPadding }) => css`
    ${selected &&
    !$removed &&
    css`
      border-color: ${theme.color.mainBrighter};
      background-color: ${theme.color.mainTransparent};
      &:not(:disabled):hover {
        border-color: ${theme.color.mainBrighter};
        .arrow {
          background-color: ${theme.color.mainBrighter};
        }
      }
    `};

    ${status === 'alert' &&
    css`
      border-color: ${theme.color.alertBrighter};
      background-color: ${theme.color.baseBrighter};
      &:not(:disabled):hover {
        border-color: ${theme.color.alertBrightest};
        .arrow {
          background-color: ${theme.color.alertBrightest};
        }
      }
    `};

    ${status === 'alert' &&
    !selected &&
    css`
      background-color: ${theme.color.baseBrighter};
    `}

    ${status === 'alert' &&
    selected &&
    css`
      background-color: ${theme.color.alertTransparent};
    `}

    ${status === 'warning' &&
    css`
      border-color: ${theme.color.yellow};
      &:not(:disabled):hover {
        border-color: ${theme.color.yellow};
        .arrow {
          background-color: ${theme.color.yellow};
        }
      }
    `}

    ${status === 'warning' &&
    !selected &&
    css`
      background-color: ${theme.color.baseBrighter};
    `}

    ${status === 'warning' &&
    selected &&
    css`
      background-color: ${theme.color.yellowTransparent};
    `}

    ${$noPadding &&
    css`
      padding: 0;
    `};
  `};
`;

const ArrowParent = styled.div<{ arrowPosition?: 'right' | 'bottom' }>`
  position: absolute;
  left: 50%;
  bottom: -7px;
  display: flex;
  overflow: hidden;
  width: 16px;
  height: 7px;
  margin-left: -7px;
  justify-content: center;
  align-items: center;

  ${({ arrowPosition }) =>
    arrowPosition === 'right' &&
    css`
      left: auto;
      top: 50%;
      right: -11px;
      bottom: auto;
      width: 15px;
      height: 7px;
      margin-top: -3px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    `}
`;

const Arrow = styled.div<ArrowProps>`
  width: 8px;
  height: 8px;
  margin-top: -5px;
  background-color: ${({ theme }) => theme.color.main};
  -webkit-transform: scale3d(1.4, 1, 1) rotate(45deg);
  transform: scale3d(1.4, 1, 1) rotate(45deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  ${({ status }) =>
    status === 'alert' &&
    css`
      background-color: ${({ theme }) => theme.color.alert};
    `};

  ${({ status }) =>
    status === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.color.yellow};
    `};
`;

export const WalTab = ({
  arrowPosition,
  overrideBorder,
  removed,
  customArrow,
  disableArrow,
  ...rest
}: WalTabProps) => (
  <Tab
    {...rest}
    $arrowPosition={arrowPosition}
    $overrideBorder={overrideBorder}
    $removed={removed}
    cardStyle={rest.cardStyle || 'transparent'}>
    {!disableArrow &&
      rest.selected &&
      (customArrow ? (
        customArrow
      ) : (
        <ArrowParent arrowPosition={arrowPosition}>
          <Arrow className={'arrow'} selected={rest.selected} status={rest.status} />
        </ArrowParent>
      ))}
    {rest.children}
  </Tab>
);
