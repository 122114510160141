import { Button, FormGenerator, INPUT_LABEL_MARGIN } from '@humanitec/ui-components';
import { rem } from 'polished';
import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import { IngressPathTypes } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto ${rem(40)};
  grid-column-gap: ${units.margin.md};
`;

const DeleteButton = styled(Button)<{ hideLabel?: boolean }>`
  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${INPUT_LABEL_MARGIN};
    `}
`;

interface IngressPathFormProps {
  appendNewRowState: [boolean, Dispatch<SetStateAction<boolean>>];
  index: number;
  removeEntry: (index: number) => void;
  defaultOptionExists?: boolean;
  pathType?: IngressPathTypes | '';
  hideLabels?: boolean;
  path?: string;
  port?: string;
  className?: string;
}

const IngressPathForm = ({
  index,
  pathType,
  hideLabels,
  className,
  defaultOptionExists,
  path,
  removeEntry,
}: IngressPathFormProps) => (
  <Wrapper>
    <FormGenerator
      dataTestId={'ingress-path-form'}
      gridTemplate={css`
        grid-template-columns: ${rem(200)} ${rem(150)} ${rem(70)};
      `}
      className={className}
      hideLabels={hideLabels}
      fields={[
        {
          type: 'dropdown',
          props: {
            defaultValue: pathType,
            defaultText: 'Select path type',
            label: 'Path type',
            name: `ingress.${index}.path_type`,
            items: [
              {
                id: 'exact',
                label: 'Exact',
                value: 'exact',
                dataTestId: 'path-type-exact',
              },
              {
                id: 'prefix',
                label: 'Prefix',
                value: 'prefix',
                dataTestId: 'path-type-prefix',
              },
              {
                id: 'implementation_specific',
                label: 'ImplementationSpecific',
                value: 'implementation_specific',
                dataTestId: 'path-type-implementation_specific',
              },
              {
                id: 'default',
                label: 'Default',
                value: 'default',
                dataTestId: 'path-type-default',
                disabled: defaultOptionExists && pathType !== 'default',
              },
            ],
          },
        },
        {
          type: 'input',
          hide: !pathType || (pathType === 'default' && { maintainSpace: true }),
          props: {
            name: `ingress.${index}.ingress_path`,
            defaultValue: path || '/',
            label: 'Path',
            required: true,
            standardValidation: [{ type: 'urlPath' }],
          },
        },
        {
          type: 'input',
          hide: !pathType,
          props: {
            name: `ingress.${index}.ingress_port`,
            type: 'number',
            label: 'Port',
            required: true,
            min: 1,
            max: 65535,
          },
        },
      ]}
    />
    {pathType ? (
      <DeleteButton
        iconLeft={'remove'}
        variant={'secondary'}
        dataTestid={'remove-ingress-path'}
        hideLabel={hideLabels}
        onClick={() => removeEntry(index)}
      />
    ) : (
      <div />
    )}
  </Wrapper>
);

export default IngressPathForm;
