import { Input, ViewEdit } from '@humanitec/ui-components';
import { rem } from 'polished';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { MatchParams } from '@src/models/routing';
import { useWalhallForm } from '@src/utilities/form';

const ServiceAccountWrapper = styled.div<{ hasMargin: boolean }>`
  ${({ hasMargin }) => css`
    margin-top: ${hasMargin ? rem(10) : rem(-10)};
  `}
`;

interface ServiceAccountProps {
  deltaPath: string;
}

const ServiceAccount = ({ deltaPath }: ServiceAccountProps) => {
  // Form
  const methods = useWalhallForm();
  const { clearErrors } = methods;

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // Router hooks
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const { updateWorkload, data: existingServiceAccountName = '' } =
    useDeltaUtils<string>(deltaPath);

  // Component state
  const inputName = `${moduleId}-service-account`;

  const submitServiceAccount = async (formValues: any) => {
    const newServiceAccountName = formValues[inputName];

    if (newServiceAccountName === '') {
      clearErrors();
      updateWorkload([
        {
          op: 'remove',
        },
      ]);
    } else if (existingServiceAccountName !== newServiceAccountName) {
      updateWorkload([
        {
          op: existingServiceAccountName ? 'replace' : 'add',
          value: newServiceAccountName,
        },
      ]);
    }
  };

  const renderInput = (
    <Input
      label={sectionsTranslations.SERVICE_ACCOUNT}
      name={inputName}
      readonly={!draftModeActive}
      defaultValue={existingServiceAccountName}
      standardValidation={[{ type: 'id' }]}
      placeholder={sectionsTranslations.SERVICE_ACCOUNT_PLACEHOLDER_TEXT}
      noMargin
    />
  );

  return (
    <ServiceAccountWrapper hasMargin>
      {!existingServiceAccountName && !draftModeActive && (
        <span className={'txt-md'}>{sectionsTranslations.NO_SERVICE_ACCOUNT}</span>
      )}
      <FormProvider {...methods}>
        {draftModeActive && (
          <ViewEdit
            viewModeContent={
              existingServiceAccountName === '' ? 'default' : existingServiceAccountName
            }
            editModeContent={renderInput}
            onConfirm={submitServiceAccount}
            hasCheckmark
            key={inputName}
            sectionName={'service-accounts'}
            readonly={!draftModeActive}
          />
        )}
        {!draftModeActive && <span className={'txt-md'}>{existingServiceAccountName}</span>}
      </FormProvider>
    </ServiceAccountWrapper>
  );
};
export default ServiceAccount;
