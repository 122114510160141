import { TabGroup } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import useUserDetailQuery from '@src/hooks/react-query/user/queries/useUserDetailQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import { AllRoles } from '@src/models/role';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { capitalize } from '@src/utilities/string-utility';

import OrgRoleModal from '../../components/role-modals/OrgRoleModal/OrgRoleModal';

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  margin-top: ${units.margin.xl};
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 50%;
  grid-gap: ${units.padding.md};
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoGroup = ({ label, value }: { label: string; value: string | AllRoles | undefined }) => (
  <div className={'flex flex-column mb-md'}>
    <span className={'txt-translucent txt-sm pb-xs'}>{label}</span>
    <span className={'pb-md txt-md'}>{value}</span>
  </div>
);

const OrgMemberDetails = () => {
  // i18n
  const { t } = useTranslation('orgMembers');
  const orgMembersTranslation = t('ORG_MEMBERS_LIST').ORG_ROLE_MODAL;

  // React Query
  const { data: userDetail } = useUserDetailQuery();

  // RBAC
  const canCreateUpdateOrgMembers = useRBAC('createUpdateOrgMembers');

  // Optimizely
  const [userGroupsDecision] = useDecision('user-groups');

  const showGroupsTab = userGroupsDecision.enabled && userDetail?.type === 'user';

  return (
    <Container>
      <MenuWrapper>
        <div className={'flex flex-column'}>
          <span className={'txt-translucent txt-sm pb-xs'}>
            {userDetail?.type === 'group' ? t('GROUP') : t('USER')}
          </span>
          <span className={'txt-xl mb-xl'}>{userDetail?.name || '...'}</span>
        </div>
        {userDetail && canCreateUpdateOrgMembers && (
          <OrgRoleModal mode={{ name: 'change', user: userDetail, buttonVariant: 'main' }} />
        )}
      </MenuWrapper>
      {(userDetail?.type === 'user' || userDetail?.type === 'invitation') && (
        <InfoGroup label={t('EMAIL_ADDRESS')} value={userDetail?.email} />
      )}
      <DetailsGrid>
        {(userDetail?.type === 'user' || userDetail?.type === 'invitation') && (
          <InfoGroup
            label={t('STATUS')}
            value={userDetail?.type === 'user' ? t('ACTIVE') : t('INVITATION_PENDING')}
          />
        )}
        <InfoGroup
          label={userDetail?.type === 'group' ? t('CREATED') : t('JOINED')}
          value={formatDate(userDetail?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        />
        <InfoGroup
          label={t('ORGANIZATION_ROLE')}
          value={
            userDetail?.role === 'orgViewer'
              ? orgMembersTranslation.ORG_VIEWER
              : capitalize(userDetail?.role ?? '')
          }
        />
      </DetailsGrid>
      <TabGroup
        options={[
          {
            label: t('USERS_TITLE'),
            value: 'users',
            link: { to: 'users' },
            hide: userDetail?.type !== 'group',
          },
          {
            label: t('APPLICATIONS_TITLE'),
            value: 'applications',
            link: { to: 'applications' },
          },
          {
            label: t('ENVIRONMENT_TYPES_TITLE'),
            value: 'environment-types',
            link: { to: 'environment-types' },
          },
          {
            label: t('GROUPS_TITLE'),
            value: 'groups',
            link: { to: 'groups' },
            hide: !showGroupsTab,
          },
          {
            label: userDetail?.type === 'group' ? t('REMOVE_GROUP_TITLE') : t('REMOVE_USER_TITLE'),
            value: 'remove',
            hide: !canCreateUpdateOrgMembers,
            link: { to: 'remove' },
          },
        ]}
      />
      <Outlet />
    </Container>
  );
};

export default OrgMemberDetails;
