import {
  Button,
  CardStyles,
  Checkbox,
  EmptyStateCard,
  SearchInput,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getVersionStatus } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/resource-definition-versions.utils';
import { ResourceDefinitionVersion } from '@src/models/resources';
import { useWalhallForm } from '@src/utilities/form';

import { VersionLink } from '../../ResourceDefinitionUsage/components/VersionLink';
import { ResourceDefinitionVersionActions } from './ResourceDefinitionVersionActions/ResourceDefinitionVersionActions';

interface ResourceDefinitionVersionsTableProps {
  resourceDefinitionVersions: ResourceDefinitionVersion[];
  /* If the table is used to select version to diff */
  selectDiff?: boolean;
  showArchiveButton?: boolean;
  showActivateButton?: boolean;
  onSelectVersion?: (version: ResourceDefinitionVersion) => void;
  showDiffButton?: boolean;
  onPinVersion?: (version: ResourceDefinitionVersion) => void;
  tableRowStyle?: CardStyles;
  isDefaultResourceDefinition?: boolean;
}

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResourceDefinitionVersionsTable = ({
  resourceDefinitionVersions,
  selectDiff,
  onSelectVersion,
  showActivateButton,
  showArchiveButton,
  showDiffButton,
  onPinVersion,
  tableRowStyle,
  isDefaultResourceDefinition,
}: ResourceDefinitionVersionsTableProps) => {
  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;

  const latestVersion = resourceDefinitionVersions[0];

  // React from
  const methods = useWalhallForm();
  const { watch } = methods;

  const showArchivedChecked = watch('show-archived');

  // State
  const [filterValue, setFilterValue] = useState<string>();

  const filteredRows = useMemo(() => {
    let filteredVersions = resourceDefinitionVersions;
    filteredVersions = filteredVersions?.filter((defVersion) =>
      !showArchivedChecked ? !defVersion.archived : true
    );
    if (filterValue) {
      filteredVersions = filteredVersions?.filter(
        (defVersion) =>
          defVersion.id?.includes(filterValue) ||
          getVersionStatus(defVersion, latestVersion?.id === defVersion.id)
            .toLowerCase()
            .includes(filterValue?.toLowerCase())
      );
    }
    return filteredVersions;
  }, [filterValue, showArchivedChecked, resourceDefinitionVersions, latestVersion]);

  const columns: WalTableColumn<ResourceDefinitionVersion>[] = [
    {
      label: versionsTranslations.VERSION,
      prop: 'version',
      fixedWidth: 200,
      template: (row) => <VersionLink resourceDefinitionVersion={row.data} />,
    },
    {
      label: versionsTranslations.STATUS,
      prop: 'status',
      template: (row) => <p>{getVersionStatus(row.data, latestVersion?.id === row.data.id)}</p>,
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        return selectDiff ? (
          <Button
            size={'small'}
            variant={'secondary'}
            onClick={(e) => {
              e.stopPropagation();
              if (onSelectVersion) {
                onSelectVersion(row.data);
              }
            }}>
            {versionsTranslations.SELECT}
          </Button>
        ) : (
          <ResourceDefinitionVersionActions
            resourceDefinitionVersion={row.data}
            showActivateButton={showActivateButton}
            showArchiveButton={showArchiveButton}
            showDiffButton={showDiffButton}
            onPinVersion={onPinVersion}
            isDefaultResourceDefinition={isDefaultResourceDefinition}
          />
        );
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <FiltersWrapper className={'mb-md'}>
        <Checkbox name={'show-archived'} label={versionsTranslations.SHOW_ARCHIVED} />
        <SearchInput
          name={'filter-input'}
          placeholder={versionsTranslations.FIND_BY_VERSION_ID_OR_STATUS}
          onChange={(value) => setFilterValue(value)}
        />
      </FiltersWrapper>

      {filteredRows.length > 0 ? (
        <WalTable
          tableRowStyle={selectDiff ? 'base' : tableRowStyle}
          rows={
            filteredRows?.map((defVersion) => ({
              data: defVersion,
            })) || []
          }
          columns={columns}
          caption={versionsTranslations.RESOURCE_VERSIONS_TABLE}
        />
      ) : (
        <EmptyStateCard>
          {versionsTranslations.NO_RESOURCE_DEFINITION_VERSION_AVAILABLE}
        </EmptyStateCard>
      )}
    </FormProvider>
  );
};
export default ResourceDefinitionVersionsTable;
