import { SearchInput } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useRBAC } from '@src/hooks/useRBAC';
import { OrgRoles } from '@src/models/role';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import OrgRoleModal from '../../components/role-modals/OrgRoleModal/OrgRoleModal';
import { OrgMembersTable } from './components/OrgMembersTable';

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.lg};
  justify-content: space-between;
`;

export interface FormProps {
  'email-wrapper': string[];
  email: string;
  role: OrgRoles;
  errorInputPlaceholder: string;
}

interface OrgMembersListProps {
  mode: 'users' | 'groups';
}

const OrgMembersList = ({ mode }: OrgMembersListProps) => {
  // Component state
  const [filterValue, setFilterValue] = useState('');

  const handleFilterInputChange = (value: string) => {
    setFilterValue(value?.toLowerCase());
  };

  // i18n
  const { t } = useTranslation();
  const orgSettingsTranslations = t('ORG_SETTINGS');

  // RBAC
  const canCreateUpdateOrgMembers = useRBAC('createUpdateOrgMembers');

  // Form
  const methods = useWalhallForm<FormProps>({
    defaultValues: { 'email-wrapper': [], role: 'member', email: '', errorInputPlaceholder: '' },
  });

  return (
    <>
      <TopWrapper>
        {canCreateUpdateOrgMembers && mode === 'users' && (
          <OrgRoleModal mode={{ name: 'invite' }} />
        )}
        <SearchInput
          name={'member-filter'}
          placeholder={orgSettingsTranslations.SEARCH_FOR_USERS}
          onChange={handleFilterInputChange}
        />
      </TopWrapper>
      <FormProvider {...methods}>
        <OrgMembersTable filterValue={filterValue} mode={mode} />
      </FormProvider>
    </>
  );
};

export default OrgMembersList;
