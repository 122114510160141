import { Input } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AzureIdentityFieldsProps {
  isNew?: boolean;
}

const AzureIdentityFields = ({ isNew }: AzureIdentityFieldsProps) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AZURE_IDENTITY;

  return (
    <>
      <Input
        name={'azure_identity_tenant_id'}
        className={'mb-md'}
        labelAbove={!isNew}
        label={formTranslations.TENANT_ID}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
      <Input
        name={'azure_identity_client_id'}
        className={'mb-md'}
        labelAbove={!isNew}
        label={formTranslations.CLIENT_ID}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
    </>
  );
};

export default AzureIdentityFields;
