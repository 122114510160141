import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { Group } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../userQueryKeys';

export const useGetUserGroupsQuery = () => {
  const { orgId, userId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: userQueryKeys.userGroups(orgId, userId),
    queryFn: () => makeRequest<Group[] | null>('GET', `/orgs/${orgId}/users/${userId}/groups`),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};
