import { DatePicker, DropdownItem, Input, WalDropdownMenu } from '@humanitec/ui-components';
import { addDays } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { OrgRoles, Role } from '@src/models/role';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

type ExpiryDateOptions = '7' | '30' | '60' | '90' | 'custom' | 'no-expiration';
export interface AddNewApiTokenFormProps {
  /* list of existing token ids to validate against because you can"t add duplicate token ids */
  existingTokens?: string[];
  user: Role<OrgRoles>;
}

const Wrapper = styled.div`
  label {
    color: ${({ theme }) => theme.color.text};
  }
`;

const AddNewApiTokenForm = ({ existingTokens, user }: AddNewApiTokenFormProps) => {
  // i18n
  const { t } = useTranslation();
  const apiTokensTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS;
  const expirationDateTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS_EXPIRATION_DATE;
  const roleItems = useRoleDropdownOptions(true);
  const { watch } = useFormContext();
  const expiresAt: ExpiryDateOptions = watch('expires_at');

  const dateRoleDropdownOptions: DropdownItem<ExpiryDateOptions>[] = [
    {
      label: expirationDateTranslations.SEVEN_DAYS,
      id: '7',
      value: '7',
    },
    {
      label: expirationDateTranslations.THIRTY_DAYS,
      id: '30',
      value: '30',
    },
    {
      label: expirationDateTranslations.SIXTY_DAYS,
      id: '60',
      value: '60',
    },
    {
      label: expirationDateTranslations.NINETY_DAYS,
      id: '90',
      value: '90',
    },
    {
      label: expirationDateTranslations.CUSTOM,
      id: 'custom',
      value: 'custom',
    },
    {
      label: expirationDateTranslations.NO_EXPIRATION,
      id: 'no-expiration',
      value: 'no-expiration',
    },
  ];
  const getExpiryDate = (expiry: number) =>
    formatDate(
      addDays(new Date(), expiry).toString(),
      DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
      true
    ) as string;

  const expiryDateDescriptions = {
    'no-expiration': 'Your token will not expire',
    '7': `Your token will expire on ${getExpiryDate(7)}`,
    '30': `Your token will expire on ${getExpiryDate(30)}`,
    '60': `Your token will expire on ${getExpiryDate(60)}`,
    '90': `Your token will expire on ${getExpiryDate(90)}`,
    custom: `You will set token expiration date below`,
  };

  const { clearErrors } = useFormContext();

  return (
    <Wrapper>
      <div className={'txt-translucent mb-md'}>{apiTokensTranslations.ADD_API_TOKEN_SUBTITLE}</div>
      <Input
        className={'mb-md'}
        labelAbove
        name={'id'}
        label={apiTokensTranslations.API_TOKEN_ID}
        required
        standardValidation={[{ type: 'id' }, { type: 'existingId', ids: existingTokens || [] }]}
        onChange={() => clearErrors('id')}
      />
      <Input
        className={'mb-md'}
        labelAbove
        name={'description'}
        label={apiTokensTranslations.API_TOKEN_DESCRIPTION}
      />

      <WalDropdownMenu
        label={apiTokensTranslations.API_TOKEN_EXPIRY_DATE}
        items={dateRoleDropdownOptions}
        fullWidth
        name={'expires_at'}
        defaultValue={'no-expiration'}
      />
      <></>
      {expiryDateDescriptions[expiresAt] && (
        <div className={'mt-md mb-md'}>{expiryDateDescriptions[expiresAt]}</div>
      )}
      {expiresAt === 'custom' && (
        <DatePicker
          className={'mb-md'}
          name={'expiry-date'}
          label={apiTokensTranslations.SET_EXPIRY_DATE}
          minDate={formatDate(new Date().toString(), DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY)}
          onChange={() => clearErrors('expiry-date')}
          required
          fullWidth
        />
      )}
      <WalDropdownMenu
        className={'mb-xl'}
        label={apiTokensTranslations.API_TOKEN_PERMISSION}
        items={roleItems}
        defaultValue={user.role}
        fullWidth
        name={'role'}
        disabled
      />
    </Wrapper>
  );
};

export default AddNewApiTokenForm;
