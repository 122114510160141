export const groupsQueryKeys = {
  // e.g. ['orgs', 'my-org', 'resources']
  all: (orgId: string | undefined) => ['orgs', orgId, 'groups'],
  list: (orgId: string | undefined) => [...groupsQueryKeys.all(orgId), 'list'],
  detail: (orgId: string | undefined, groupId: string | undefined) => [
    ...groupsQueryKeys.all(orgId),
    'detail',
    groupId,
  ],
};
