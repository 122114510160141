/* eslint-disable jsx-a11y/anchor-has-content */
import {
  Button,
  Input,
  SearchInput,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { artefactVersionsQueryOptions } from '@src/hooks/react-query/artefacts/queries/useArtefactVersionsQuery';
import { Artefact, ArtefactVersion } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { PUBLIC_REGISTRY_IMAGE_REGEX } from '@src/utilities/string-utility';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${units.margin.xl} 0;
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const SeparatorLine = styled.div`
  width: 100%;
  margin: ${units.padding.xxl} 0;
  background-color: ${({ theme }) => theme.color.baseOutline};
  height: 2px;
`;

const SubTitle = styled.h2`
  font-size: ${units.fontSize.lg};
  margin-bottom: ${units.margin.md};
`;

const InformationText = styled.p`
  width: 100%;
  margin-bottom: ${units.margin.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ImageNameInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: ${units.margin.md};
  margin: ${units.margin.md} 0;
`;

interface SelectImageStepProps {
  artefacts: Artefact[];
  currentImageIsExternal?: boolean;
  onUpdateImageName: (imageName: string) => void;
  existingImageName?: string;
}

const SelectImageStep = ({
  artefacts,
  currentImageIsExternal,
  onUpdateImageName,
  existingImageName,
}: SelectImageStepProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const viewModuleTranslations = t('VIEW_MODULE');

  // state
  const [filterTerm, setFilterTerm] = useState<string>();

  const { handleSubmit, setValue } = useFormContext<{ imageName: string }>();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  const filteredArtefacts = useMemo(
    () =>
      filterTerm
        ? artefacts?.filter((artefact: Artefact) => artefact.name?.includes(filterTerm))
        : artefacts,
    [artefacts, filterTerm]
  );

  useEffect(() => {
    if (currentImageIsExternal && existingImageName) {
      setValue('imageName', existingImageName);
    }
  }, [currentImageIsExternal, existingImageName, setValue]);

  const submitImageName = ({ imageName }: { imageName: string }) => {
    onUpdateImageName(imageName);
  };

  const selectImage = async (e: MouseEvent, artefact: Artefact) => {
    e.stopPropagation();
    const versions = (
      await queryClient.ensureQueryData<AxiosResponse<ArtefactVersion[]>>(
        artefactVersionsQueryOptions(orgId, artefact.id, true)
      )
    ).data;
    if (versions) {
      onUpdateImageName(
        `${artefact?.name}${versions?.[0]?.version !== '' ? ':' : ''}${versions?.[0]?.version}`
      );
    }
  };

  const tableColumns: WalTableColumn[] = [
    {
      label: imageTranslations.IMAGE_NAME,
      prop: 'name',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 40,
        text: (row) => row.data.name,
        invertEllipsis: true,
      },
    },
    {
      label: imageTranslations.IMPORT_DATE,
      prop: 'created_at',
      ellipsisTooltip: {
        maxWidth: 100,
        text: (row) => row.data.ref,
        invertEllipsis: true,
      },
      template: (row: WalTableRow<Artefact>) =>
        formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE),
    },
    {
      prop: 'actions',
      template: (row: WalTableRow<Artefact>) =>
        existingImageName?.includes(row.data.name) ? (
          <span>{imageTranslations.SELECTED_IMAGE}</span>
        ) : (
          <Button
            onClick={(e) => selectImage(e, row.data)}
            size={'small'}
            ariaLabel={`${imageTranslations.SELECT_IMAGE} ${row.data.name}`}>
            {imageTranslations.SELECT_IMAGE}
          </Button>
        ),
    },
  ];

  return (
    <>
      <FilterWrapper>
        <SearchInput
          className={'my-md'}
          name={'filterImages'}
          hideLabel
          placeholder={imageTranslations.IMAGES_SEARCH_PLACEHOLDER}
          onChange={setFilterTerm}
        />
      </FilterWrapper>
      {filteredArtefacts?.length === 0 && (
        <NoResultsWrapper>
          <span className={'txt-lg'}>{imageTranslations.NO_RESULTS_FOUND}</span>
          <span className={'txt-sm'}>
            {imageTranslations.NO_RESULTS} {`"${filterTerm}"`}
          </span>
        </NoResultsWrapper>
      )}
      {filteredArtefacts && (
        <WalTable
          caption={imageTranslations.IMAGES_TABLE_CAPTION}
          disableScrolling
          tableRowStyle={'transparent'}
          rows={filteredArtefacts.map((artefact) => ({
            data: artefact,
          }))}
          columns={tableColumns}
        />
      )}
      <SeparatorLine />
      <SubTitle>{imageTranslations.ADD_IMAGE_NAME}</SubTitle>
      <InformationText>
        <Trans defaults={imageTranslations.EXTERNAL_IMAGE_INFO_1} />
      </InformationText>
      <InformationText>
        <Trans defaults={imageTranslations.EXTERNAL_IMAGE_INFO_2}>
          <a
            href={
              'https://developer.humanitec.com/platform-orchestrator/working-with/workloads/#update-a-container'
            }
            target={'_blank'}
            rel={'noreferrer'}
          />
        </Trans>
      </InformationText>
      <form onSubmit={handleSubmit(submitImageName)}>
        <ImageNameInputWrapper>
          <Input
            name={'imageName'}
            hideLabel
            required
            placeholder={'e.g. myregistry:5000/postgres/httpd:1.0'}
            pattern={{
              value: PUBLIC_REGISTRY_IMAGE_REGEX,
              message: viewModuleTranslations.REGISTRY_PATH_ERROR,
            }}
          />
          <div>
            <Button type={'submit'} ariaLabel={`${imageTranslations.ADD_IMAGE}`}>
              {imageTranslations.ADD_IMAGE}
            </Button>
          </div>
        </ImageNameInputWrapper>
      </form>
    </>
  );
};

export default SelectImageStep;
