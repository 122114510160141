import { CodeSnippet, TabGroup, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

import CurlSnippet from './CurlSnippet';
import { DescriptionLink } from './styles';
import WgetSnippet from './WgetSnippet';

const Description = styled.p`
  margin-bottom: ${units.margin.lg};
`;

interface AddRegistryModalProps {
  /** The open state of the modal */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const ConnectCIPipelineModal = ({ openState }: AddRegistryModalProps) => {
  const [open, setOpen] = openState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const manageImagesTranslations = t('ACCOUNT_SETTINGS').MANAGE_IMAGES;

  // Component state

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <WalModal
      openState={[open, setOpen]}
      size={'medium'}
      title={manageImagesTranslations.CONNECT_CI_PIPELINE}
      content={
        <>
          <Description>
            <Trans i18nKey={'ACCOUNT_SETTINGS.MANAGE_IMAGES.CONNECT_CI_PIPELINE_MODAL_SUBTEXT'}>
              <DescriptionLink
                target={'_blank'}
                rel={'noopener noreferrer'}
                href={
                  'https://developer.humanitec.com/platform-orchestrator/automation/integrate/#add-new-image-sources'
                }
              />
            </Trans>
          </Description>
          <TabGroup
            options={[
              {
                label: 'cURL',
                value: 'curl',
                element: (
                  <CodeSnippet>
                    <CurlSnippet />
                  </CodeSnippet>
                ),
              },
              {
                label: 'Wget',
                value: 'wget',
                element: (
                  <CodeSnippet>
                    <WgetSnippet />
                  </CodeSnippet>
                ),
              },
            ]}
            size={'large'}
            stretchWidth
          />
        </>
      }
      actions={{
        main: {
          text: uiTranslations.CLOSE,
          props: {
            onClick: closeModal,
            className: 'qa-gcp-authorize',
          },
        },
      }}
    />
  );
};

export default ConnectCIPipelineModal;
