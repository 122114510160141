import { Input, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useWalhallForm } from '@src/utilities/form';

import { PageHeaderProps } from '../PageHeader';

interface RenameModalProps extends Required<Pick<PageHeaderProps, 'renameTitle'>> {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  existingName?: string;
}

const RenameModal = ({ openState, existingName, renameTitle }: RenameModalProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm({
    values: { name: renameTitle?.defaultValueEmpty ? '' : existingName },
  });

  // Component state
  const [, setOpen] = openState;

  return (
    <FormProvider {...formMethods}>
      <WalModal
        handleFormSubmit={({ name }: { name: string }) => {
          renameTitle.submitCallback(name);
          setOpen(false);
        }}
        openState={openState}
        title={renameTitle.modalTitle}
        content={<Input name={'name'} label={'Name'} required />}
        actions={{
          main: {
            props: {
              type: 'submit',
            },
            text: uiTranslations.RENAME,
          },
          cancel: {},
        }}
      />
    </FormProvider>
  );
};

export default RenameModal;
