import { rem } from 'polished';
import React, { KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import { Icon, IconSizes } from '../../base/Icon/Icon';

const CopyTextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

// Position fake textarea for clipboard copy.
const FakeTextArea = styled.textarea`
  position: absolute;
  left: -1000vh;
  top: -100vw;
`;

const CopyIcon = styled(Icon)<{ mainColor?: boolean }>`
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.color.mainBrighter};
      }
    }
  }
`;

const CopiedToast = styled.div`
  position: absolute;
  top: ${rem(-40)};
  right: ${rem(-20)};
  display: block;
  color: ${({ theme }) => theme.color.text};
  font-size: ${units.fontSize.sm};
  font-weight: normal;
  background-color: ${({ theme }) => theme.color.baseLayer};
  padding: ${units.padding.lg};
  border-radius: ${rem(5)};
  z-index: 2;
  box-shadow: 0 ${rem(4)} ${rem(8)} 0 rgba(0, 0, 0, 0.15);
`;

const DisplayedText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

const TextIconWrapper = styled.div<{ iconSize?: IconSizes }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr ${({ iconSize }) => rem(iconSize || 12)};
  &:hover {
    color: ${({ theme }) => theme.color.mainBrighter};
    svg {
      path {
        fill: ${({ theme }) => theme.color.main};
      }
    }
  }
`;

interface CopyTextProps {
  text: string; // this truncates with an ellipse when text is longer than avalaible space.
  showText?: boolean;
  iconSize?: IconSizes;
  copyButtonRef?: React.Ref<any>;
  onClick?: () => void;
  textClassName?: string;
  className?: string; // classname of outermost wrapper.
  overrideCopyButtonColor?: 'main-brighter' | 'text-translucent';
}
interface CopyButtonProps {
  iconSize?: IconSizes;
  copyButtonRef?: React.Ref<any>;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  overrideColor?: 'main-brighter' | 'text-translucent';
}

const CopyButton = ({
  iconSize,
  copyButtonRef,
  onClick,
  overrideColor,
  onKeyDown,
}: CopyButtonProps) => (
  <CopyIcon
    name={'copy'}
    size={iconSize || 12}
    iconRef={copyButtonRef}
    onClick={onClick}
    onKeyDown={onKeyDown}
    disableInvert
    marginLeft={'sm'}
    tabIndex={0}
    overrideColor={overrideColor}
  />
);
export const CopyText = ({
  text,
  showText,
  iconSize,
  copyButtonRef,
  onClick,
  textClassName,
  className,
  overrideCopyButtonColor,
}: CopyTextProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [showCopiedToast, setShowCopiedToast] = useState<boolean>(false);
  const copyTextAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const copySnippet = () => {
    copyTextAreaRef?.current?.select();
    document.execCommand('copy');
    setShowCopiedToast(true);
    setTimeout(() => {
      setShowCopiedToast(false);
    }, 1000);
  };

  const onCopyClick = () => {
    copySnippet();
    if (onClick) {
      onClick();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      onCopyClick();
    }
  };

  return (
    <CopyTextWrapper className={className}>
      {showCopiedToast && <CopiedToast>{uiTranslations.COPIED}</CopiedToast>}
      {showText ? (
        <TextIconWrapper
          onClick={onCopyClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className={'copy-text-container'}>
          <DisplayedText className={textClassName}>{text}</DisplayedText>
          <CopyButton
            iconSize={iconSize}
            copyButtonRef={copyButtonRef}
            overrideColor={overrideCopyButtonColor}
          />
        </TextIconWrapper>
      ) : (
        <CopyButton
          iconSize={iconSize}
          copyButtonRef={copyButtonRef}
          onClick={onCopyClick}
          onKeyDown={handleKeyDown}
        />
      )}
      <FakeTextArea readOnly ref={copyTextAreaRef} value={text} />
    </CopyTextWrapper>
  );
};
