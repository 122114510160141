import { rem } from 'polished';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { viewAppBreakpoints } from '@src/styles/breakpoints';

export const WORKFLOW_LAYOUT_COLUMN_GAP = 30;

const Wrapper = styled.div<{ customSidebarGridColumn?: string; disableColumnGap?: boolean }>`
  flex: 1;
  display: grid;
  ${({ disableColumnGap }) =>
    !disableColumnGap &&
    css`
      column-gap: ${rem(WORKFLOW_LAYOUT_COLUMN_GAP)};
    `};
  grid-template-columns: ${({ customSidebarGridColumn }) => customSidebarGridColumn || '35%'} 1fr;
  overflow: auto;

  @media ${viewAppBreakpoints.only.sm} {
    overflow-y: auto;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Main = styled.div<SectionProps>`
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${viewAppBreakpoints.only.sm} {
    max-width: unset;
    order: 1;
    overflow: unset;
  }
`;

const Sidebar = styled.div<SectionProps>`
  flex-direction: column;

  ${({ overflowSidebar }) =>
    overflowSidebar &&
    css`
      overflow: auto;
    `}
`;

interface WorkflowLayoutProps {
  /** Main content */
  mainContent: ReactNode;
  /** Sidebar content */
  sidebarContent?: ReactNode;
  /** Adds overflow auto to sidebar */
  overflowSidebar?: boolean;
  /* Add a custom width for the sidebar. Shoulkd be in grid-column format */
  customSidebarGridColumn?: string;
  disableColumnGap?: boolean;
  className?: string;
}

interface SectionProps {
  overflowSidebar?: boolean;
}

const WorkflowLayout = ({
  mainContent,
  sidebarContent,
  overflowSidebar = false,
  customSidebarGridColumn,
  disableColumnGap,
  className,
}: WorkflowLayoutProps) => (
  <Wrapper
    className={className}
    disableColumnGap={disableColumnGap}
    customSidebarGridColumn={customSidebarGridColumn}>
    <Sidebar className={'workflow-sidebar'} overflowSidebar={overflowSidebar}>
      {sidebarContent}
    </Sidebar>
    <Main className={'workflow-main'}>{mainContent}</Main>
  </Wrapper>
);

export default WorkflowLayout;
