import { Icon } from '@humanitec/ui-components';

import {
  ResourceDependency,
  ResourceTypeItem,
} from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';

const mapCallback = (option: ResourceDependency) => ({
  id: option.id,
  label: option.id,
  value: option,
  component: (
    <div className={'flex-centered'}>
      <ResourceIcon hasMargin size={18} type={option.type} />
      <span>{option.typeName}</span>
    </div>
  ),
});

export const getResourceTypeItems = (
  externalResourceOptions: ResourceDependency[],
  resourcesTranslations: any
): ResourceTypeItem[] => {
  const volumes = externalResourceOptions.filter((o) => o.type === 'volume');
  const otherDatastores = externalResourceOptions.filter(
    (o) => o.type !== 'volume' && o.category === 'datastore'
  );
  const routingResources = externalResourceOptions.filter(
    (o) => o.category === 'dns' || o.category === 'ingress'
  );
  const autoscalerResources = externalResourceOptions.filter((o) => o.category === 'autoscaler');
  const messaging = externalResourceOptions.filter((o) => o.category === 'messaging');
  const awsResourceOptions = externalResourceOptions.filter((o) => o.category === 'aws');
  const otherResourceOptions = externalResourceOptions.filter((o) => !o.category);

  const messagingSection = [
    {
      id: 'messaging',
      value: 'messaging',
      label: resourcesTranslations.MESSAGING,
      hideFromList: messaging.length === 0,
      separatorItem: true,
    },
    ...messaging.map(mapCallback),
  ];

  return [
    {
      id: 'volumes',
      value: 'volumes',
      label: resourcesTranslations.VOLUMES,
      separatorItem: true,
      hideFromList: false,
    },
    ...volumes.map(mapCallback),
    {
      id: 'emptyDir',
      value: {
        id: 'emptyDir',
        type: 'emptyDir',
        typeName: 'emptyDir',
      },
      component: (
        <div className={'flex-centered'}>
          <Icon marginRight={'sm'} name={'emptyDir'} size={18} />
          <span>emptyDir</span>
        </div>
      ),
      label: 'emptyDir',
      hideFromList: false,
    },
    {
      id: 'datastores',
      value: 'datastores',
      label: resourcesTranslations.DATASTORES,
      separatorItem: true,
      hideFromList: otherDatastores.length === 0,
    },
    ...otherDatastores.map(mapCallback),
    {
      id: 'routing',
      value: 'routing',
      label: resourcesTranslations.ROUTING,
      hideFromList: routingResources.length === 0,
      separatorItem: true,
    },
    ...routingResources.map(mapCallback),
    {
      id: 'autoscaler',
      value: 'autoscaler',
      label: resourcesTranslations.AUTOSCALER,
      hideFromList: autoscalerResources.length === 0,
      separatorItem: true,
    },
    ...autoscalerResources.map(mapCallback).map((option) => ({
      ...option,
      hideFromList: false,
    })),
    ...messagingSection,
    {
      id: 'aws',
      value: 'aws',
      label: resourcesTranslations.AWS,
      hideFromList: awsResourceOptions.length === 0,
      separatorItem: true,
    },
    ...awsResourceOptions.map(mapCallback),
    {
      id: 'other',
      value: 'other',
      label: resourcesTranslations.OTHER,
      hideFromList: otherResourceOptions.length === 0,
      separatorItem: true,
    },
    ...otherResourceOptions.map(mapCallback),
  ];
};
