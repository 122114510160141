import { TabGroup } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ResumeEnvText from '@src/components/shared/PauseEnvironment/components/ResumeEnvText';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useCheckLatestValueSetDeployed from '@src/hooks/useCheckLatestValueSetDeployed';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/layout/PageContainer';

import ViewEnvironmentHeader from './ViewEnvironmentHeader';

const Container = styled.div`
  ${containerStyle(false)};
  flex-direction: column;
  flex: 1;
`;

const ViewEnvironment = () => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const tabsTranslations = t('TABS');

  // Router hooks
  const { envId } = useParams<keyof MatchParams>() as MatchParams;

  // React query
  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery();

  const { valueSetDeployedIsOutdated, environment } = useCheckLatestValueSetDeployed({ envId });

  const envPauseStatus = envPauseStatusMap?.[envId];

  // RBAC
  const canAccessDraftURL = useRBAC('accessDraftURL');
  const canDeleteEnvironment = useRBAC('deleteEnvironment');

  return (
    <Container>
      <ViewEnvironmentHeader
        environment={environment}
        showEnvironmentValueSetOutdatedWarning={Boolean(valueSetDeployedIsOutdated)}
      />
      {envPauseStatus && <ResumeEnvText />}
      <TabGroup
        options={[
          {
            label: tabsTranslations.STATUS,
            value: 'status',
            link: { to: 'status' },
          },
          {
            label: tabsTranslations.DEPLOYMENTS,
            value: 'deploys',
            link: { to: 'deploys' },
          },
          {
            label: tabsTranslations.DRAFT_DELTAS,
            value: 'draft',
            hide: !canAccessDraftURL,
            link: { to: 'draft' },
          },
          {
            label: tabsTranslations.AUTOMATION,
            value: 'automation',
            link: { to: 'automation' },
          },
          {
            label: tabsTranslations.VALUES_AND_SECRETS_OVERRIDES,
            value: 'values-and-secrets-overrides',
            link: { to: 'values-and-secrets-overrides' },
          },
          {
            label: tabsTranslations.DELETE_ENVIRONMENT,
            value: 'delete',
            link: { to: 'delete' },
            hide: !canDeleteEnvironment,
          },
        ]}
      />
      <Outlet />
    </Container>
  );
};

export default ViewEnvironment;
