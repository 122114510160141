import { useQuery } from '@tanstack/react-query';
import { mapValues, merge } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { AppRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import { User } from '@src/models/user';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import makeRequest from '@src/utilities/make-request';

import useAppRolesQuery from '../../roles/queries/useAppRolesQuery';
import useGetCurrentUserQuery from '../../user/queries/useGetCurrentUserQuery';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';
import {
  AppValueVersionBase,
  AppValueVersionItem,
  OperationType,
  ValueSet,
} from '../sharedValueVersionsTypes';

/**
 * Get App value version history from API response. This methods does the following:
 * - change the `change` format to map structure to make it easy to query
 * - Breaks versions into multiple versions specific to key.
 * API response is of the form [ {changes: [key1change, key2change1]}, {changes: [key2change2]}]
 * In this function we transform that to [key1 : [key1Change], key2: [key2Change1, key2Change2]}
 */
const getAppValueVersionHistory = (
  apiResponse: ValueSet[],
  user: User | undefined,
  appRoles: Role<AppRoles>[]
): Record<string, AppValueVersionItem[]> => {
  // Final result object initialization
  const keyVersionsMap: Record<string, AppValueVersionItem[]> = {};

  // ignore when there is no response
  if (!apiResponse) return {};

  // For each version in response , iterate and seperate changes based on key values and add them to result object
  apiResponse.forEach((responseItem: ValueSet) => {
    // in case of purge, the change is null. We can ignore this change. Purge details are included in another version
    if (responseItem.change === null) {
      return;
    }

    /* changesTree contains changes in the format :
     * {
     *    [key]: {
     *        [operation]: {
     *           [fieldName]: [value]
     *        }
     *    }
     * }
     */
    const changesTree = responseItem.change.reduce<
      Record<string, Record<OperationType, undefined | Record<string, string>>>
    >((accumulator, change) => {
      // change path in response is of the form `/keyName/fieldName`.
      // In case of operation = `add` it is of the form `/keyName`, fieldName would be undefined in that case
      const [_, keyName, fieldName] = change.path.split('/');
      return keyName
        ? merge(accumulator, {
            [keyName]: {
              [change.op]: fieldName
                ? {
                    [fieldName]: change.value,
                  }
                : {}, // specifically setting empty object as it might lead to bugs if its undefined and  checked if operation exists like `changesTree.${keyname}.${operation}`
            },
          })
        : {};
    }, {});

    Object.keys(changesTree).forEach((keyValue) => {
      // initialise empty array to the key
      keyVersionsMap[keyValue] = keyVersionsMap[keyValue] || [];

      const responseValue = responseItem.values[keyValue];
      // Add base data common to purged and non-purged versions
      const versionDataBase: AppValueVersionBase = {
        source: responseValue?.source || 'app',
        changeId: responseItem.id,
        isSecret: responseValue ? responseValue.is_secret : false,
        secretId:
          responseValue && responseValue.secret_version ? responseValue.secret_version : undefined,
        editedBy:
          responseItem.created_by && user?.id === responseItem.created_by
            ? user?.name
            : appRoles?.find((role) => role.id === responseItem.created_by)?.name,
        editedDate: formatDate(
          responseItem.created_at,
          DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
          true
        ) as string,
        // secretId
        keyValue,
        updatedFields: changesTree[keyValue]!,
        purgeChangeId: responseItem.id, // this will be updated later if only description is updated
      };

      // add extra data for purged versions and push to final object
      if (
        responseItem.result_of === 'app_value_set_version_purge' ||
        responseItem.result_of === 'env_value_set_version_purge'
      ) {
        keyVersionsMap[keyValue].push({
          ...versionDataBase,
          isPurged: true,
          value: null,
          description: null,
          isDeleted: false,
          purgeDetails: {
            comment: responseItem.comment,
            purgedDate: formatDate(
              responseItem.updated_at,
              DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
              true
            ) as string,
            purgedId: responseItem.id,
          },
        });

        // add extra data for rest of the unpurged versions and push to final object
      } else if (responseItem.result_of === 'app_value_delete') {
        keyVersionsMap[keyValue].push({
          ...versionDataBase,
          isDeleted: true,
          value: null,
          description: null,
        });
      } else if (responseValue) {
        // responseValue is undefined in case of delete
        keyVersionsMap[keyValue].push({
          ...versionDataBase,
          isDeleted: false,
          isPurged: false,
          value: responseValue.value,
          description: responseValue.description,
        });
      }
    });
  });

  return keyVersionsMap;
};

export const getEnvValueVersionsURL = (orgId: string, appId: string, env: string) =>
  `/orgs/${orgId}/apps/${appId}/envs/${env}/value-set-versions`;

export const getSharedAppValueVersionsURL = (orgId: string, appId: string) =>
  `/orgs/${orgId}/apps/${appId}/value-set-versions`;

/**
 * Set purgeChangeId to each version. If the value is same for a set of consecutive versions, the changeId of the first version is set as
 * purgeChangeId for all the versions with that value. That way, anytime a version is attempted to purge, we can pass the right purgeChangeId
 * and we can also use this to let user know which other versions will be purged.
 * purgeChangeId is also used to display `Current` text to all versions with value same as the current version  and this prevent it from revert or purge
 *
 * @param sharedAppValueVersions list of app value versions
 */
export const updatePurgeChangeId =
  (isEnvironmentOverride: boolean) => (sharedAppValueVersions: AppValueVersionItem[]) => {
    // First update all versions with their changeId as their purgeChangeId
    const updatedList = sharedAppValueVersions.reverse();

    for (let i = 0; i < updatedList.length - 1; i++) {
      const currentVersion = updatedList?.[i];
      const nextVersion = updatedList[i + 1];
      if (
        currentVersion?.isDeleted ||
        currentVersion?.isPurged ||
        (isEnvironmentOverride && currentVersion?.source === 'app') // In case of Env Override, ignore versions with source 'app'
      ) {
        // ignore changing purgeChangeId if its already purged
        continue;
      }
      // check if value or secret_version changed between versions. Change = replace | remove | add
      if (
        !nextVersion?.updatedFields?.replace?.hasOwnProperty(
          nextVersion?.isSecret ? 'secret_version' : 'value'
        ) &&
        !nextVersion?.updatedFields?.remove?.hasOwnProperty(
          nextVersion.isSecret ? 'secret_version' : 'value'
        ) &&
        !nextVersion?.updatedFields?.add?.hasOwnProperty(
          nextVersion.isSecret ? 'secret_version' : 'value'
        )
      ) {
        nextVersion!.purgeChangeId = currentVersion?.purgeChangeId;
      }
    }
    // We first reversed the list as its easier to go left to right , setting parent version to all versions with the same value
    // now we are revesing it back to its original order
    return updatedList.reverse();
  };

export const getSharedAppValuesHistoryQueryOptions = (
  orgId: string,
  appId: string,
  envId: string,
  isEnvironmentOverride?: boolean,
  enabled = true
) => {
  // generate URL from orgId and appId
  // these two values should never be null ideally, if they are we get wrong url and the call fails
  const URL = isEnvironmentOverride
    ? getEnvValueVersionsURL(orgId || '', appId || '', envId || '')
    : getSharedAppValueVersionsURL(orgId || '', appId || '');
  return {
    queryKey: isEnvironmentOverride
      ? sharedValuesQueryKeys.envValuesList(orgId, appId, envId)
      : sharedValuesQueryKeys.appValuesList(orgId, appId),
    queryFn: () => makeRequest<ValueSet[]>('GET', URL),
    enabled: isEnvironmentOverride
      ? Boolean(orgId && appId && envId && enabled)
      : Boolean(orgId && appId && enabled),
  };
};

/**
 * Custom hook to fetch Shared App values history
 */
export const useSharedAppValuesHistoryQuery = (
  isEnvironmentOverride: boolean,
  customParams?: {
    appId?: string;
    envId?: string;
  },
  enabled?: boolean
): QueryResponse<Record<string, AppValueVersionItem[]>, ValueSet[]> => {
  const {
    orgId,
    appId: routerAppId,
    envId: routerEnvId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const envId = customParams?.envId || routerEnvId;
  const appId = customParams?.appId || routerAppId;

  // React Query
  const { data: user } = useGetCurrentUserQuery();
  const { data: appRoles = [] } = useAppRolesQuery({ appId });

  const { data, ...queryResult } = useQuery(
    getSharedAppValuesHistoryQueryOptions(orgId, appId, envId, isEnvironmentOverride, enabled)
  );

  const mappedValues = useMemo(() => {
    return mapValues(
      getAppValueVersionHistory(data?.data || [], user, appRoles),
      updatePurgeChangeId(isEnvironmentOverride)
    );
  }, [appRoles, data?.data, isEnvironmentOverride, user]);

  return {
    ...queryResult,
    data: mappedValues,
    responseData: data?.data,
  };
};
export default useSharedAppValuesHistoryQuery;
