import { useQuery } from '@tanstack/react-query';

import { Invite } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../userQueryKeys';

const useLoadInviteQuery = (token: string | null) =>
  useQuery({
    queryKey: userQueryKeys.invite(token),
    queryFn: () => makeRequest<Invite>('GET', `/invitations/${token}`),
    enabled: Boolean(token),
    select: (data) => data.data,
  });

export default useLoadInviteQuery;
