import { Outlet, useMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ErrorBoundary from '@src/components/shared/ErrorBoundary/ErrorBoundary';
import HTMLDocumentTitle from '@src/components/shared/HTMLDocumentTitle';
import { cl } from '@src/styles/global-styles';

import AuthHeader from './components/AuthHeader';

const AuthBody = styled.div<{ disablePaddingTop: boolean }>`
  ${({ disablePaddingTop }) =>
    !disablePaddingTop &&
    css`
      padding-top: 20vh;
    `}
  overflow-y: auto;
`;

const Auth = () => {
  // Router hooks
  const isSignUpPage = useMatch('/auth/signup');
  const isRegisterMVPPage = useMatch('/auth/registermvp');

  const showAuthHeader = !(isSignUpPage || isRegisterMVPPage);

  return (
    <div {...cl('flex-column', 'full-width')}>
      {showAuthHeader && <AuthHeader />}
      <HTMLDocumentTitle />
      <ErrorBoundary>
        <AuthBody
          disablePaddingTop={!Boolean(showAuthHeader)}
          {...cl('flex-column', 'flex-1', 'align-center')}>
          <Outlet />
        </AuthBody>
      </ErrorBoundary>
    </div>
  );
};

export default Auth;
