import { get, has } from 'lodash';
import { rem } from 'polished';
import React, {
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import { ErrorOption, useFormContext, Validate, ValidationValueMessage } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { ErrorMoreInformationLink, InputExtraError } from '@src/components/shared-types/input';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import {
  capitalize,
  GranularRegexMatch,
  ID_START_WITH_APLHABETIC_VALIDATIONS,
  ID_VALIDATIONS,
  KEY_REGEXP,
  lowercaseFirstLetter,
  NAME_VALIDATIONS,
  PATH_VALIDATIONS,
  RESOURCE_ID_REGEXP,
  SUB_PATH_VALIDATIONS,
  URL_PATH_REGEXP,
  VOLUME_PATH_REGEXP,
} from '@src/utilities/string-utility';

import { CopyText } from '../../molecules/CopyText/CopyText';
import { FormFieldGeneratorDefinition } from '../../molecules/FormGenerator/FormGenerator';
import Tooltip from '..//Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import { TextareaProps } from '../Textarea/Textarea';

export const InputIcon = styled.span`
  position: absolute;
  top: 1.75rem;
  right: 0.5rem;
  display: inline-flex;
  z-index: 10;
`;

export const INPUT_LABEL_MARGIN = units.margin.lg;

export type InputSize = 'small' | 'medium' | 'large';

type ValidationNames =
  | 'id'
  | 'key'
  | 'existingId'
  | 'path'
  | 'volume-path'
  | 'sub-path'
  | 'name'
  | 'urlPath'
  | 'idStartWithAlphabetic';

interface StandardValidations {
  type: 'key' | 'path' | 'volume-path' | 'sub-path' | 'name' | 'idStartWithAlphabetic';
}

interface IDValidation {
  type: 'id';
  /** Exceptions to the ID rule */
  exceptions?: string[];
}

interface ResourceIDValidation {
  type: 'resourceId';
  /** Exceptions to the ID rule */
  exceptions?: string[];
}

interface ExistingIDValidation {
  type: 'existingId';
  /** The ids which already exist */
  ids: string[];
  customMessage?: string;
}

interface SingleLineValidations {
  type: 'urlPath';
}

export type ValidationTypes =
  | StandardValidations
  | ExistingIDValidation
  | IDValidation
  | ResourceIDValidation
  | SingleLineValidations;

interface InputFieldProps {
  noLabelOverflow: boolean;
  noMargin: boolean;
  hideLabel: boolean;
  minHeight: number;
}

interface InputLabelProps {
  size: InputSize;
  readOnly?: boolean;
  labelExtensionSet?: boolean;
  hideLabel?: boolean;
  $error?: boolean;
  valueSet?: boolean;
}

export const InputField = styled.div<InputFieldProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ noLabelOverflow }) =>
    noLabelOverflow &&
    css`
      overflow: unset;
    `}
  ${({ noMargin, hideLabel }) => {
    return noMargin || hideLabel
      ? css`
          padding-top: 0;
        `
      : css`
          padding-top: ${INPUT_LABEL_MARGIN};
        `;
  }}
  
  ${({ minHeight }) =>
    minHeight &&
    css`
      & > .monaco-editor-wrapper {
        min-height: ${minHeight}px;
      }
    `}
}
`;

export const InputLabel = styled.label<InputLabelProps>`
  width: calc(100% - 10px);
  ${ellipsisStyle()}
  position: absolute;
  color: ${({ theme }) => theme.color.textTranslucent};
  top: ${({ size }) => (size === 'large' ? rem(30) : size === 'medium' ? rem(28) : rem(25))};
  left: ${rem(8)};
  font-size: ${({ size }) =>
    size === 'large'
      ? units.fontSize.base
      : size === 'medium'
        ? units.fontSize.sm
        : units.fontSize.sm};
  margin: 0px;
  display: block;
  white-space: nowrap;
  transition: all linear 0.2s;
  pointer-events: none;
  z-index: 2;
  &:hover {
    cursor: text;
  }
  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
    `}
  ${({ labelExtensionSet, $error, hideLabel, valueSet }) => {
    if ((labelExtensionSet && hideLabel) || (hideLabel && valueSet)) {
      return css`
        display: none !important;
      `;
    }

    if ($error) {
      return css`
        top: ${rem(3)};
        left: ${rem(0)};
        color: ${({ theme }) => theme.color.alertBrighter};
        font-size: ${units.fontSize.sm};
      `;
    } else if (labelExtensionSet) {
      return css`
        top: ${rem(3)};
        left: ${rem(0)};
        color: ${({ theme }) => theme.color.textTranslucent};
        font-size: ${units.fontSize.sm};
      `;
    }
  }}

  ${({ hideLabel, size }) =>
    hideLabel &&
    css`
      top: ${rem(size === 'small' ? 5 : 8)};
    `}

    ${({ labelExtensionSet }) =>
    labelExtensionSet &&
    css`
      top: 0px;
    `}
`;

export const InputDescription = styled.div`
  width: 100%;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.xs};
  margin-bottom: ${rem(3)};
  grid-column: 1/-1;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const ViewModeText = styled.span`
  color: ${({ theme }) => theme.color.text};
  font-size: ${units.fontSize.lg};
  margin-bottom: ${units.margin.md};
  white-space: pre-wrap;
  line-break: anywhere;
`;

const validationMessages = (
  label?: string
): { type: ValidationNames; message: Validate<string, string> }[] => [
  {
    type: 'key',
    message: (value: string) =>
      KEY_REGEXP.test(value) ||
      `${label ? `${label} can` : 'Can'} only contain letters, numbers, '-', '_' or '.'`,
  },
  {
    type: 'volume-path',
    message: (value: string) =>
      value
        ? VOLUME_PATH_REGEXP.test(value) ||
          `${label ? `${label} is an invalid path` : 'Invalid path'}`
        : true,
  },
  {
    type: 'urlPath',
    message: (value: string) =>
      value
        ? URL_PATH_REGEXP.test(value) ||
          (label ? `${label} should start with /` : 'Path should start with / /d')
        : true,
  },
];

const NewErrorWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.alertInput};
  border-radius: ${rem(4)};
  padding: ${rem(8)};
  margin-top: ${rem(4)};
  font-size: ${rem(11)};
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
`;

const ListUL = styled.ul<{ oneField?: boolean }>`
  margin: 0;
  ${({ oneField }) =>
    oneField
      ? css`
          list-style: none;
          padding-left: 0;
        `
      : css`
          padding-left: ${units.padding.xl};
        `}
`;

interface DisplayFieldErrorProps {
  fieldError: ErrorOption;
  oneField: boolean;
  label: string;
  errorMoreInformationLink?: ErrorMoreInformationLink;
}

const ErrorLink = ({ href }: { href: string }) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  return (
    <a href={href} target={'_blank'} rel={'noopener noreferrer'}>
      {uiTranslations.MORE_INFORMATION}
    </a>
  );
};

const replaceLabelAndCapitalize = (type?: string, message?: string, label?: string) =>
  capitalize(
    (label ? message?.replace(label, type === 'required' ? 'This field' : '') : message)?.trim() ||
      ''
  );

const DisplayFieldError = ({
  fieldError,
  oneField,
  label,
  errorMoreInformationLink,
}: DisplayFieldErrorProps) => {
  const href =
    typeof errorMoreInformationLink !== 'string' &&
    fieldError.type &&
    errorMoreInformationLink?.[fieldError.type];

  return Object.keys(fieldError.types || {}).length > 1 ? (
    <>
      {!oneField && <b>{label}</b>}
      <ListUL oneField={oneField}>
        {Object.entries(fieldError.types || {}).map(([type, message]) => (
          <li key={type}>
            {typeof message === 'string'
              ? replaceLabelAndCapitalize(type, message, label)
              : message}{' '}
            {typeof errorMoreInformationLink !== 'string' && errorMoreInformationLink?.[type] && (
              <ErrorLink href={errorMoreInformationLink?.[type]} />
            )}
          </li>
        ))}
      </ListUL>
    </>
  ) : (
    <>
      <span>
        {!oneField && <b>{label}</b>}{' '}
        {oneField
          ? replaceLabelAndCapitalize(fieldError.type, fieldError.message, label)
          : lowercaseFirstLetter(fieldError.message?.replace(label, '') || '')}{' '}
        {href && <ErrorLink href={href} />}
      </span>
    </>
  );
};

interface DisplayFieldErrorsProps {
  fieldErrors: ErrorOption | Record<string, ErrorOption>;
  /** Specifies if it's a single field & will result in no nesting of errors (list items). Only specify this in the individual form components. It is automatically set to true for FormGenerator when the number of fields is 1  */
  oneField: { label?: string } | boolean;
  // Only for FormGenerator
  fields?: FormFieldGeneratorDefinition[];
  testId?: string;
  errorMoreInformationLink?: ErrorMoreInformationLink;
  extraErrors?: InputExtraError[];
}

export const DisplayFieldErrors = ({
  fieldErrors,
  oneField,
  fields,
  testId,
  errorMoreInformationLink,
  extraErrors = [],
}: DisplayFieldErrorsProps) => (
  <NewErrorWrapper data-testid={`${testId}-error-wrapper`}>
    {fields ? (
      // it's formGenerator
      <>
        <ListUL oneField={Boolean(oneField)}>
          {[
            ...fields.map((field) => ({
              name: field.props.name,
              label: (field.props as SharedInputProps).label || '',
              errorMoreInformationLink:
                (field.props as SharedInputProps)?.errorMoreInformationLink || undefined,
            })),
            ...extraErrors,
          ]
            ?.map(({ name, label, errorMoreInformationLink: inputErrorMoreInformationLink }) =>
              name && has(fieldErrors, name) ? (
                <React.Fragment key={name}>
                  <li>
                    <DisplayFieldError
                      fieldError={get(fieldErrors, name) as ErrorOption}
                      oneField={Boolean(oneField)}
                      label={label}
                      errorMoreInformationLink={inputErrorMoreInformationLink}
                    />
                  </li>
                  {typeof inputErrorMoreInformationLink === 'string' && (
                    <ErrorLink href={inputErrorMoreInformationLink} />
                  )}
                </React.Fragment>
              ) : undefined
            )
            .filter((f) => f !== undefined)}
        </ListUL>
        {typeof errorMoreInformationLink === 'string' && (
          <ErrorLink href={errorMoreInformationLink} />
        )}
      </>
    ) : (
      // It's a single input
      <p>
        <DisplayFieldError
          fieldError={fieldErrors}
          oneField={Boolean(oneField)}
          label={typeof oneField !== 'boolean' ? oneField.label || '' : ''}
          errorMoreInformationLink={errorMoreInformationLink}
        />
        {typeof errorMoreInformationLink === 'string' && (
          <ErrorLink href={errorMoreInformationLink} />
        )}
      </p>
    )}
  </NewErrorWrapper>
);

export const renderSecretIcon = (secretsInfoText: string) => (
  <InputIcon>
    <Tooltip
      tabIndex={-1}
      width={300}
      triggerComponent={<Icon name={'padlock'} tabIndex={0} />}
      text={secretsInfoText}
      position={'left'}
    />
  </InputIcon>
);

export const renderDeleteIcon = (deleteAction: () => void) => (
  <InputIcon>
    <Icon pointer name={'delete'} tabIndex={0} onClick={deleteAction} onKeyDown={deleteAction} />
  </InputIcon>
);

export interface SharedInputProps {
  /** The name of the input */
  name: string;
  /** The input type */
  type?: 'text' | 'number' | 'password';
  /** The label attached to the input */
  label?: string;
  /** The label extension text */
  labelExtensionText?: string;
  /** The placeholder text */
  placeholder?: string;
  /** The size of the input */
  size?: InputSize;
  /** The id of the input */
  id?: string;
  /** If the input is readonly */
  readonly?: boolean;
  /** if the input should be scrollable when it is readonly */
  allowArrowScrolling?: boolean;
  /** whether the input content should be displayed as text instead on input */
  viewMode?: boolean;
  /** If the input is required */
  required?: boolean;
  /** Max length of input */
  maxLength?: number;
  /** Min length of input */
  minLength?: number;
  /** Maximum number */
  max?: number;
  /** Minimum number */
  min?: number;
  /** Pattern to match */
  pattern?: ValidationValueMessage<RegExp>;
  /** Default input value */
  defaultValue?: string;
  /** Custom class */
  className?: string;
  /** onChange html event */
  onChange?: (value: string) => void;
  /** onInput html event */
  onInput?: (element: Element) => void;
  /** onBlur html event */
  onBlur?: (value: string) => void;
  /** onBlur html event */
  onFocus?: (value: string | null) => void;
  /** onClick html event */
  onClick?: (event: MouseEvent) => void;
  /** onMouseDown html event */
  onMouseDown?: (event: MouseEvent) => void;
  /** onKeyDown html event */
  onKeyDown?: (event: KeyboardEvent) => void;
  /** onKeyUp html event */
  onKeyUp?: (event: KeyboardEvent) => void;
  /** onPaste html event for content in the fake input div */
  onPaste?: (event: ClipboardEvent) => void;
  /** Optional ref. e.g. for focusing on input from parent component */
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement | null>;
  /** Custom error validation */
  validate?: Record<string, Validate<string, any>>;
  /** A set of standard validation for inputs */
  standardValidation?: ValidationTypes[];
  /** if the input has a top margin */
  noMargin?: boolean;
  /** Hide the label field */
  hideLabel?: boolean;
  /** If you want a div that looks like an input */
  fakeInput?: boolean;
  /** Minimum height of the fake input*/
  fakeInputMinHeight?: number;
  /** Only for use with 'fakeInput' */
  children?: any;
  /** force the input to propagate click event to children */
  propagateClickEvent?: boolean;
  noHover?: boolean;
  /** no border radius */
  noBorderRadius?: 'all' | 'right' | 'left';
  noLabelOverflow?: boolean;
  /** Makes the input transparent */
  transparent?: boolean;
  /** Set to true if the label should always be above of the input, and not act as placeholder */
  labelAbove?: boolean;
  description?: string;
  dataTestId?: string;
  ariaControls?: string;
  fontFamily?: 'default' | 'source-code';
  valueAsNumber?: boolean;
  /** https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
  autoComplete?: string;
  tabIndex?: number;
  /** If using formgenerator */
  hideError?: boolean;
  /**
   * More information link for error.
   * To display error for the whole input, pass a string.
   * To display errors for individual error types, use object. The key is the error type e.g. 'required', the value is the link.
   */
  errorMoreInformationLink?: ErrorMoreInformationLink;
  /**
   * Set true if the input is a secret. In that case:
   * * `type` is changed to `password` to mask input text
   * * A padlock icon is displayed to the right end of input
   */
  secret?: boolean;
  /** Should only be used when having an input inside an input e.g. ComboSelect */
  hideOutline?: boolean;
  /** text that appears as input placeholder */
  placeholderText?: string;
  /** This is used to inform input to get focussed. Its used mainly in KeyValueEdit component*/
  focusOnInputState?: [boolean, (setFocus: boolean) => void];
  deleteAction?: () => void;
}

interface InputWrapperProps extends TextareaProps {
  children: (formRegister: UseFormRegisterReturn) => React.ReactNode;
}

/**
 * Returns a wrapper for text input components (Input, MonacoInput and Textarea) that includes shared components the label, description and error sections
 */
export const InputWrapper = ({
  name,
  id,
  label,
  placeholder = '',
  size = 'medium',
  readonly = false,
  viewMode = false,
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  rows,
  className,
  defaultValue,
  validate,
  standardValidation,
  noMargin,
  hideLabel,
  children,
  labelExtensionText,
  noLabelOverflow,
  labelAbove,
  description,
  dataTestId,
  valueAsNumber,
  secret,
  hideError,
  errorMoreInformationLink,
}: InputWrapperProps) => {
  // Component state
  const [labelExtension, setLabelExtension] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [newValidation, setNewValidation] = useState<
    Validate<string, any> | Record<string, Validate<string, any>> | undefined
  >(validate);

  const minHeight = rows ? rows * 30 : 30;

  // Form
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const formRegister = register(name, {
    validate: {
      ...newValidation,
      ...validate,
    },
    required: required && `${!label ? 'This field' : label} is required`,
    maxLength: maxLength && {
      value: maxLength,
      message: `Cannot exceed ${maxLength} characters`,
    },
    minLength: minLength && {
      value: minLength,
      message: `Must be at least ${minLength} characters`,
    },
    max: max && { value: max, message: `Max is ${max}` },
    min: min && { value: min, message: `Min is ${min}` },
    /* Pattern validation can only be active when valueAsNumber is not true. See node_modules/react-hook-form/dist/types/validator.d.ts */
    ...(valueAsNumber === true
      ? { valueAsNumber }
      : {
          /**
           * Pattern is required to be passed to this component in value & message format.
           * If we can't be sure that the message is defined (e.g. DynamicForm), just pass empty string
           */
          pattern: pattern && {
            ...pattern,
            message: pattern.message || `Must match pattern ${pattern.value}`,
          },
        }),
  });
  const fieldError = get(errors, name) as ErrorOption;
  const hasValue = Boolean(watch(name));

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  useEffect(() => {
    if (name) {
      setInputValue(watch(name));
    }
  }, [name, watch]);

  useEffect(() => {
    if (!inputValue) {
      setLabelExtension(null);
    } else {
      setLabelExtension(`${label}`);
    }
  }, [inputValue, label]);

  useEffect(() => {
    if (defaultValue && name) {
      setLabelExtension(`${label}`);
      setValue(name, defaultValue);
    }
  }, [defaultValue, label, name, setValue]);

  useEffect(() => {
    if (readonly && fieldError) {
      clearErrors(name);
    }
  }, [readonly, clearErrors, name, fieldError]);

  useEffect(() => {
    const newValidations: Record<string, Validate<string, string>> = {};

    const mapErrors = (validations: Record<string, GranularRegexMatch>, valType: string) => {
      Object.entries(validations).forEach(([key, r]) => {
        newValidations[`${valType}-${key}`] = (value: string) => {
          if (!required && !value) {
            return true;
          } else {
            if (r.negativeMatch) {
              return r.regex.test(value) ? r.transkey : true;
            } else {
              return r.regex.test(value) || r.transkey;
            }
          }
        };
      });
    };

    for (const val of standardValidation || []) {
      const found = validationMessages(label).find((v) => v.type === val.type);

      if (val.type === 'id') {
        Object.entries(ID_VALIDATIONS).forEach(([key, r]) => {
          newValidations[`id${key}`] = (value: string) => {
            if (value?.length > 0) {
              return r.negativeMatch
                ? val.exceptions?.includes(value) || (r.regex.test(value) ? r.transkey : true)
                : r.regex.test(value) || val.exceptions?.includes(value) || r.transkey;
            }
          };
        });
      } else if (val.type === 'resourceId') {
        newValidations.resourceId = (value: string) =>
          value
            ? RESOURCE_ID_REGEXP.test(value) ||
              val.exceptions?.includes(value) ||
              uiTranslations.RESOURCE_ID_INSTRUCTIONS_NEW_ERRORS
            : true;
      } else if (val.type === 'existingId') {
        newValidations[val.type] = (value: string) =>
          !val.ids.includes(value) ||
          val.customMessage ||
          (label ? `${label} already exists` : 'ID already exists');
      } else if (val.type === 'name') {
        mapErrors(NAME_VALIDATIONS, val.type);
      } else if (val.type === 'sub-path') {
        mapErrors(SUB_PATH_VALIDATIONS, val.type);
      } else if (val.type === 'idStartWithAlphabetic') {
        mapErrors(ID_START_WITH_APLHABETIC_VALIDATIONS, val.type);
      } else if (val.type === 'path') {
        mapErrors(PATH_VALIDATIONS, val.type);
      } else if (found) {
        newValidations[val.type] = found.message;
      }
    }

    setNewValidation(newValidations);
  }, [
    standardValidation,
    label,
    uiTranslations.RESOURCE_ID_INSTRUCTIONS,
    uiTranslations.RESOURCE_ID_INSTRUCTIONS_NEW_ERRORS,
    required,
  ]);

  const handleInputFieldFocus = () => {
    if (labelExtensionText) {
      setLabelExtension(`${label}${labelExtensionText}`);
    } else {
      setLabelExtension(`${label}`);
    }
  };

  const handleInputFieldBlur = (evt: any) => {
    if (evt.target.value === '' || evt.target.textContent === '') {
      setLabelExtension(null);
    } else {
      setLabelExtension(`${label}`);
    }
  };

  return (
    <InputField
      data-testid={dataTestId || 'input'}
      hideLabel={Boolean(hideLabel)}
      onFocus={handleInputFieldFocus}
      onBlur={handleInputFieldBlur}
      className={`${className} input-wrapper`}
      noLabelOverflow={Boolean(noLabelOverflow)}
      minHeight={minHeight}
      noMargin={Boolean(noMargin)}>
      {label && (!placeholder || labelAbove) && (
        <InputLabel
          size={size}
          hideLabel={Boolean(hideLabel)}
          htmlFor={id || name}
          valueSet={Boolean(hasValue)}
          labelExtensionSet={Boolean(labelExtension || hasValue || labelAbove || viewMode)}
          readOnly={readonly}>
          {`${labelExtension || label}${required ? '*' : ''}`}
        </InputLabel>
      )}
      {viewMode ? (
        <ViewModeText data-testid={'view-mode-text'}>
          {secret ? (
            '••••••••••'
          ) : defaultValue && defaultValue?.length > 50 ? (
            <CopyText text={defaultValue} showText />
          ) : (
            defaultValue || '-'
          )}
        </ViewModeText>
      ) : (
        children(formRegister)
      )}
      {!hideError && fieldError && !readonly && !viewMode && (
        <DisplayFieldErrors
          testId={dataTestId}
          fieldErrors={fieldError}
          oneField={{ label }}
          errorMoreInformationLink={errorMoreInformationLink}
        />
      )}
      {description && !viewMode && <InputDescription>{description}</InputDescription>}
    </InputField>
  );
};
