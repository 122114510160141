import { Button, WalCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Card = styled(WalCard)`
  justify-content: center;
  min-height: 87px;
`;
interface NoDeploymentSelectedCardProps {
  selectButtonClicked: () => void;
}
const NoDeploymentSelectedCard = ({ selectButtonClicked }: NoDeploymentSelectedCardProps) => {
  // i18n
  const { t } = useTranslation();
  const compareDeploymentsTranslations = t('COMPARE_DEPLOYMENTS');
  return (
    <Card cardStyle={'transparent'}>
      <ContentWrapper>
        <span className={'txt-translucent'}>
          {compareDeploymentsTranslations.NO_DEPLOYMENT_SELECTED}
        </span>
        <Button variant={'primary'} onClick={selectButtonClicked}>
          {compareDeploymentsTranslations.SELECT}
        </Button>
      </ContentWrapper>
    </Card>
  );
};
export default NoDeploymentSelectedCard;
