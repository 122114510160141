import { Button, WalModal } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MembersRoleDropdown from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/components/MemberRolesDropdown';
import RoleDescriptions from '@src/containers/Orgs/OrgMembers/components/RoleDescriptions/RoleDescriptions';
import useRoleCreateMutation from '@src/hooks/react-query/roles/mutations/useRoleCreateMutation';
import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import { AppRoles, OrgRoles, Role } from '@src/models/role';
import { useWalhallForm } from '@src/utilities/form';

interface ChangeApplicationRoleModalProps {
  user: Role<OrgRoles>;
  appRole: AppRoles | undefined;
  appId: string;
}

const ChangeApplicationRoleModal = ({ user, appRole, appId }: ChangeApplicationRoleModalProps) => {
  // Component state
  const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('APPLICATIONS_TAB');

  // Form
  const formMethods = useWalhallForm();

  // React Query
  const {
    mutate: updateRole,
    isSuccess: isUpdated,
    isPending: isUpdating,
  } = useRoleUpdateMutation({
    id: appId,
    type: 'apps',
  });

  const {
    mutate: createRole,
    isSuccess: isCreated,
    isPending: isCreating,
  } = useRoleCreateMutation({
    id: appId,
    type: 'apps',
  });

  useEffect(() => {
    if (isUpdated || isCreated) {
      setChangeRoleModalOpen(false);
    }
  }, [isUpdated, isCreated]);

  return (
    <>
      <Button variant={'secondary'} size={'small'} onClick={() => setChangeRoleModalOpen(true)}>
        {translations.CHANGE_ROLE}
      </Button>
      <FormProvider {...formMethods}>
        {changeRoleModalOpen && (
          <WalModal
            openState={[changeRoleModalOpen, setChangeRoleModalOpen]}
            title={translations.CHANGE_APPLICATION_ROLE}
            handleFormSubmit={(formValues) => {
              if (appRole) {
                updateRole({ roleId: user.id, newRole: formValues.role });
              } else {
                createRole({ role: formValues.role, id: user.id });
              }
            }}
            content={
              <>
                <MembersRoleDropdown
                  defaultValue={appRole}
                  buttonVariant={'secondary'}
                  label={translations.ROLE}
                />
                <RoleDescriptions
                  roleType={'app'}
                  items={[
                    {
                      role: translations.VIEWER,
                      description: translations.VIEWER_DESC,
                    },
                    {
                      role: translations.DEVELOPER,
                      description: translations.DEVELOPER_DESC,
                    },
                    {
                      role: translations.OWNER,
                      description: translations.OWNER_DESC,
                    },
                  ]}
                />
              </>
            }
            actions={{
              cancel: {},
              main: {
                text: 'Save',
                props: { type: 'submit', loading: isUpdating || isCreating },
              },
            }}
          />
        )}
      </FormProvider>
    </>
  );
};

export default ChangeApplicationRoleModal;
