import { rem } from 'polished';
import { KeyboardEvent, ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NonStyledNavLink } from '@src/components/shared/NonStyledLink';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import WalModal from '../Modal/Modal';
import Tooltip from '../Tooltip/Tooltip';

export type TabGroupSize = 'small' | 'medium' | 'large';

export interface TabGroupProps<T = string> {
  /** The tab group options */
  options: TabGroupOptionProps<T>[];
  /** The tab group button size */
  size?: TabGroupSize;
  /** If the toggle is disabled */
  disabled?: boolean;
  optionClicked?: (clickedValue: T, selectedByKeyboard: boolean) => void;
  onLeaveTabConfirm?: (clickedValue: T) => void;
  stretchWidth?: boolean;
  className?: string;
  confirmMessage?: string;
}

export interface TabGroupOptionProps<T = string> {
  /** The label to display */
  label: string;
  /** The value of the option */
  value: T;
  hide?: boolean;
  disabled?: boolean;
  /* an info message that appears on hover for disabled elements */
  disabledInfo?: string;
  link?: Pick<LinkProps, 'to' | 'state'>;
  element?: ReactNode;
  confirmOnLeave?: boolean;
  hoverText?: string;
}

const TabGroupWrapper = styled.div<{ stretchWidth?: boolean; numberOfItems: number }>`
  display: flex;
  flex-wrap: wrap;
  ${({ stretchWidth, numberOfItems }) =>
    stretchWidth &&
    css`
      display: grid;
      grid-template-columns: ${`repeat(${numberOfItems}, 1fr)`};
    `}
  column-gap: ${units.padding.xl};
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${units.margin.lg};
`;

const SeparatorLine = styled.div<{ size: TabGroupSize }>`
  position: relative;
  width: 100%;
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.baseOutline};
  top: ${rem(-2)};
`;

const SwitcherOptionStyle = (size?: TabGroupSize, disabled?: boolean) => css`
  cursor: pointer;
  height: 100%;
  color: ${({ theme }) => theme.color.text};
  border-width: 0;
  display: grid;
  align-items: center;
  background-color: transparent;
  z-index: 1;
  ${() =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: default;
    `}

  ${() => {
    switch (size) {
      case 'large':
        return css`
          padding-right: ${rem(15)};
        `;
      case 'small':
        return css`
          padding-right: ${rem(6)};
        `;
      case 'medium':
      default:
        return css`
          padding-right: ${rem(10)};
        `;
    }
  }}
  padding-left: 0;

  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.baseOutline};
  ${() => {
    switch (size) {
      case 'large':
        return css`
          font-size: ${units.fontSize.base};
          line-height: ${rem(18)};
          padding: ${rem(10)} ${rem(10)};
        `;
      case 'small':
        return css`
          font-size: ${units.fontSize.sm};
          line-height: ${rem(16)};
          padding: ${rem(4)} ${rem(2)};
        `;
      case 'medium':
      default:
        return css`
          font-size: ${units.fontSize.base};
          line-height: ${rem(16)};
          padding: ${rem(8)} ${rem(6)};
        `;
    }
  }}
  &:not(:disabled):hover {
    border-color: ${({ theme }) => theme.color.mainBrighter};
  }

  color: ${({ theme }) => theme.color.textTranslucent};

  &.active {
    color: unset;
    border-color: ${({ theme }) => theme.color.main};
  }
`;

interface SwitcherOptionProps {
  size?: TabGroupSize;
}

const SwitcherOptionLink = styled(NonStyledNavLink)<SwitcherOptionProps>`
  ${({ size }) => SwitcherOptionStyle(size)};

  &:not(.active) {
    color: ${({ theme }) => theme.color.textTranslucent};
  }
`;
const SwitcherOptionButton = styled.button<SwitcherOptionProps>`
  ${({ size, disabled }) => SwitcherOptionStyle(size, disabled)};
`;

export const TabGroup = ({
  options = [],
  size = 'medium',
  disabled = false,
  optionClicked,
  className,
  stretchWidth,
  confirmMessage,
  onLeaveTabConfirm,
}: TabGroupProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const navigate = useNavigate();

  const [active, setActive] = useState(options?.[0]?.value);
  const [showPrompt, setShowPrompt] = useState(false);
  const [nextOption, setNextOption] = useState<null | TabGroupOptionProps>(null);

  /**
   * Updates the active state.
   *
   * @param value the option to set as active.
   */
  const onOptionClick = (value: any, selectedByKeyboard: boolean) => {
    if (!disabled && optionClicked) {
      optionClicked(value, selectedByKeyboard);
    } else if (!disabled) {
      return setActive(value);
    }
  };

  const getShowPrompt = (value: string) => {
    return Boolean(options.find((option) => option.value === value)?.confirmOnLeave);
  };

  const buttons = options
    .filter((option) => !option.hide)
    .map((option: TabGroupOptionProps) => {
      const optionIsActive = Boolean(option.value && option.value === active);

      const commonProps = {
        id: option.value,
        key: option.value,
        size,
        role: 'tab',
        disabled: option.disabled,
        'aria-selected': optionIsActive,
        onClick: (event: SyntheticEvent) => {
          if (active && getShowPrompt(active)) {
            event.preventDefault();
            setShowPrompt(true);
            setNextOption(option);
          } else {
            onOptionClick(option.value, false);
          }
        },
        onKeyDown: (e: KeyboardEvent) => {
          if (e.keyCode === KeyBindings.ENTER) {
            if (active && getShowPrompt(active)) {
              e.preventDefault();
              setShowPrompt(true);
            } else {
              onOptionClick(option.value, true);
            }
          }
        },
      };

      const { key } = commonProps;

      const SwitchOptionButtonComponent = (
        <SwitcherOptionButton
          {...commonProps}
          key={key}
          className={optionIsActive ? 'active' : undefined}
          title={option.disabled && option.disabledInfo ? option.disabledInfo : undefined}>
          {option.label}
        </SwitcherOptionButton>
      );

      return option.link && !option.disabled ? (
        <SwitcherOptionLink {...commonProps} key={key} {...option.link}>
          {option.label}
        </SwitcherOptionLink>
      ) : option.hoverText && option.disabled ? (
        <Tooltip
          hoverBuffer={0}
          key={option.value}
          focusOrder={['content']}
          triggerComponent={SwitchOptionButtonComponent}
          text={option.hoverText}
        />
      ) : (
        SwitchOptionButtonComponent
      );
    });

  return (
    <>
      <WalModal
        disableClickOutside
        openState={[showPrompt, setShowPrompt]}
        content={confirmMessage || uiTranslations.CONFIRM_LEAVE_TAB}
        actions={{
          cancel: {
            text: uiTranslations.CANCEL,
            props: {
              onClick: () => {
                setShowPrompt(false);
                setNextOption(null);
              },
            },
          },
          main: {
            text: uiTranslations.LEAVE,
            props: {
              onClick: () => {
                if (nextOption) {
                  if (nextOption.link) {
                    navigate(nextOption.link.to);
                  }
                  setActive(nextOption.value);
                  setShowPrompt(false);
                  if (active) {
                    onLeaveTabConfirm?.(active);
                  }
                }
              },
            },
          },
        }}
      />
      <Wrapper>
        <TabGroupWrapper
          role={'tablist'}
          numberOfItems={options.filter((o) => !o.hide).length}
          stretchWidth={stretchWidth}
          className={className}>
          {buttons}
        </TabGroupWrapper>
        <SeparatorLine size={size} />
        {options.map(
          (option) => active === option.value && <div key={option.value}>{option.element}</div>
        )}
      </Wrapper>
    </>
  );
};

export default TabGroup;
