import { Button, ErrorWarning, Spinner } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import PauseEnvironmentModal from '@src/components/shared/modals/PauseEnvironmentModal';
import useEnvironmentPauseMutation from '@src/hooks/react-query/environments/mutations/useEnvironmentPauseMutation';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import useCurrentPausingEnvironments from '@src/hooks/zustand/useCurrentPausingEnvironments';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

import ResumeEnvText from '../components/ResumeEnvText';

const PauseItemsWrapper = styled.div<{ disablePadding?: boolean }>`
  display: flex;
  ${({ disablePadding }) =>
    !disablePadding &&
    css`
      margin-bottom: ${units.margin.md};
    `}
`;
const PauseEnvModalText = styled.div`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.md};
`;

const SpinnerText = styled.div`
  margin-left: ${units.margin.md};
`;

const SpinnerTextWrapper = styled.div`
  display: flex;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface PauseButtonSectionProps {
  /** Pass true if you only want to display the button (hide info text) */
  disableInfoText?: boolean;
  disableWrapperPadding?: boolean;
  hidePauseButton?: boolean;
}

const PauseButtonSection = ({
  disableInfoText,
  disableWrapperPadding,
  hidePauseButton = false,
}: PauseButtonSectionProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [openPauseEnvironmentModal, setOpenPauseEnvironmentModal] = useState<boolean>(false);

  const {
    mutate: pauseEnvironment,
    isPending: isPauseEnvironmentPending,
    isSuccess: isPauseEnvironmentSuccessful,
    error: pauseEnvironmentError,
  } = useEnvironmentPauseMutation();
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  useEffect(() => {
    if (isPauseEnvironmentSuccessful) {
      setOpenPauseEnvironmentModal(false);
    }
  }, [isPauseEnvironmentSuccessful]);

  const pauseEnv = () => {
    // dispatch an action to pause the environment
    pauseEnvironment({ paused: true });
  };

  const resumeEnv = () => {
    // dispatch an action to resume a paused environment
    pauseEnvironment({ paused: false });
  };

  const { data: envPausedStatusMap } = useEnvironmentPauseStatusQuery({ appId, orgId });
  const envPauseStatus = envPausedStatusMap?.[envId];
  const { isEnvPausing } = useCurrentPausingEnvironments();
  const isUpdatingCurrentEnvironment = isEnvPausing(envId);

  // RBAC
  const canPauseEnvironment = useRBAC('pauseEnvironment');

  const ResumeButtonElement = () => {
    return (
      <Button
        size={'medium'}
        onClick={() => setOpenPauseEnvironmentModal(true)}
        iconLeft={{ name: 'play', size: 12, disableInvert: true }}
        loading={isUpdatingCurrentEnvironment}
        disabled={isUpdatingCurrentEnvironment}>
        {uiTranslations.RESUME_ENVIRONMENT}
      </Button>
    );
  };
  if (canPauseEnvironment) {
    return (
      <PauseItemsWrapper disablePadding={disableWrapperPadding}>
        {envPauseStatus === undefined && (
          <SpinnerTextWrapper>
            <Spinner size={'small'} />
            <SpinnerText>{uiTranslations.CHECKING_RUNTIME_STATUS}</SpinnerText>
          </SpinnerTextWrapper>
        )}
        {!hidePauseButton &&
          (envPauseStatus === false ? (
            <>
              <Button
                variant={'secondary'}
                size={'medium'}
                onClick={() => setOpenPauseEnvironmentModal(true)}
                iconLeft={{ name: 'pause', size: 12 }}>
                {uiTranslations.PAUSE_ENVIRONMENT}
              </Button>
            </>
          ) : disableInfoText ? (
            <ResumeButtonElement />
          ) : (
            <ResumeEnvText>
              <div>
                <ResumeButtonElement />
              </div>
            </ResumeEnvText>
          ))}
        <PauseEnvironmentModal
          openState={[openPauseEnvironmentModal, setOpenPauseEnvironmentModal]}
          content={
            <>
              {envPauseStatus ? (
                <Trans defaults={uiTranslations.RESUME_ENV_CONFIRMATION_TEXT}>
                  <PauseEnvModalText />
                </Trans>
              ) : (
                <Trans defaults={uiTranslations.PAUSE_ENV_CONFIRMATION_TEXT_1}>
                  <PauseEnvModalText />
                </Trans>
              )}
              {pauseEnvironmentError?.response && (
                <ErrorWarning
                  mode={'alert'}
                  code={pauseEnvironmentError.response.data.error}
                  message={pauseEnvironmentError.response.data.message}
                  details={JSON.stringify(pauseEnvironmentError.response.data.details)}
                />
              )}
            </>
          }
          onPause={pauseEnv}
          onResume={resumeEnv}
          isEnvironmentPaused={envPauseStatus}
          isPauseEnvironmentPending={isPauseEnvironmentPending}
          onCancel={() => setOpenPauseEnvironmentModal(false)}
        />
      </PauseItemsWrapper>
    );
  }

  return <span />;
};

export default PauseButtonSection;
