import { get } from 'lodash';
import { rem } from 'polished';
import { ErrorOption, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { InputElementProps } from '../Input/Input';
import {
  InputWrapper,
  renderDeleteIcon,
  renderSecretIcon,
  SharedInputProps,
} from '../Input/InputWrapper';
import { inputCss } from '../Input/styles/mixins';

type TextareaInternalProps = InputElementProps & {
  isExpandableTextareasEnabled: boolean;
  minHeight: number;
};
const TextareaInternal = styled.textarea<TextareaInternalProps>`
  ${(props) => inputCss(props)}
  /** Prevent user from resizing horizontally since padlock icon cannot move with textarea when it is a secret field*/
  min-width: 100%;
  ${({ isExpandableTextareasEnabled, minHeight }) =>
    isExpandableTextareasEnabled
      ? css`
          resize: vertical;
          min-height: ${rem(minHeight)};
        `
      : css`
          resize: none;
        `};
`;

export interface TextareaProps extends SharedInputProps {
  /** Number of default rows */
  rows?: number;
}

/**
 * Returns a textarea component.
 **
 * Default errors messages can be overwritten by passing an InputValidator object
 * and defining the message property, e.g. instead of `required={true}`
 * use `required={{value: true, message: 'Custom error message'}}`.
 */
export const Textarea = (props: TextareaProps) => {
  const {
    name,
    id,
    placeholder = '',
    size = 'medium',
    readonly = false,
    allowArrowScrolling,
    rows = 4,
    defaultValue,
    inputRef,
    onClick,
    onChange,
    onBlur,
    onFocus,
    noBorderRadius,
    transparent,
    ariaControls,
    fontFamily,
    secret,
    autoComplete = 'off',
    hideOutline,
    deleteAction,
  } = props;

  const minHeight = rows ? rows * 30 : 30;

  // Form
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, name) as ErrorOption;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <InputWrapper {...props}>
      {(formRegister) => (
        <>
          <TextareaInternal
            {...formRegister}
            hideOutline={hideOutline}
            minHeight={minHeight}
            isExpandableTextareasEnabled
            autoComplete={autoComplete}
            data-testid={`${name}-input-element`}
            invalid={Boolean(name && fieldError)}
            ref={(e) => {
              // Sharing ref usage: https://react-hook-form.com/faqs#Howtosharerefusage
              if (inputRef) {
                inputRef.current = e;
              }
              formRegister.ref(e);
            }}
            className={'input-element'}
            inputSize={size}
            name={name}
            id={id || name}
            rows={rows}
            readOnly={readonly}
            allowArrowScrolling={allowArrowScrolling}
            disabled={!allowArrowScrolling && readonly}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={(event) => {
              formRegister.onChange(event);
              if (onChange) {
                onChange(event.target.value);
              }
            }}
            onBlur={(event) => {
              formRegister.onBlur(event);

              if (onBlur) {
                onBlur(event.target.value);
              }
            }}
            onClick={(event) => (onClick ? onClick(event) : event.stopPropagation())}
            onFocus={(event) => (onFocus ? onFocus(event.target.value) : null)}
            noBorderRadius={noBorderRadius}
            transparent={transparent}
            aria-controls={ariaControls}
            fontFamily={fontFamily}
            iconRight={secret}
          />
          {secret && renderSecretIcon(uiTranslations.SECRETS_INFO_TEXT)}
          {deleteAction && renderDeleteIcon(deleteAction)}
        </>
      )}
    </InputWrapper>
  );
};
