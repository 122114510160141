import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';
import {
  memoryUnitOptions,
  UnitOption,
  UnitTypes,
  UnitValuePayload,
} from '@src/utilities/memory-units';

import { Input, INPUT_LABEL_MARGIN } from '../Input/Input';

interface UnitsInputProps {
  id: string;
  name: string;
  onChange: (payload: UnitValuePayload) => void;
  label?: string;
  defaultValue?: UnitValuePayload;
  viewMode?: boolean;
  className?: string;
  noBorderRadius?: 'all' | 'right' | 'left';
  hideLabel?: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;

  .input-wrapper {
    position: relative;
    display: flex;
    box-sizing: border-box;
    -webkit-box-align: stretch;
    align-items: stretch;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  input {
    cursor: auto;
    display: flex;
    box-sizing: border-box;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding-right: ${rem(24)};
  }
`;

const UnitsSelector = styled.div<{ readonly?: boolean; hideLabel?: boolean }>`
  font-size: ${units.fontSize.sm};
  cursor: default;
  color: ${({ theme }) => theme.color.textTranslucent};
  place-self: stretch;
  grid-area: 1 / 2 / 2 / 3;
  z-index: 1;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: unset;
  flex-grow: unset;
  align-items: center;
  background: transparent;
  margin: ${rem(1)};
  border: ${rem(1)} solid transparent;
  padding: 0 ${rem(2)};
  width: ${rem(24)};

  ${({ readonly }) =>
    readonly &&
    css`
      pointer-events: none;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.color.mainTransparent};
  }

  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${`calc(${INPUT_LABEL_MARGIN} + 1px)`};
    `}
`;

const Switcher = styled.div<{ hidden: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9;
  right: 0;
  background-color: ${({ theme }) => theme.color.baseLayer};
  border-radius: ${rem(2)};
  transform: ${`translateY(${rem(31)})`};
  padding: 5px 0;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

const SwitcherItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.text};
  padding: ${rem(5)} ${rem(5)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.mainTransparent};
  }
`;

/**
 * An input conmponent which gives the option of selecting a unit.
 * On change of unit or input value, a value will be sent to the parent component through `onChange`.
 */
export const UnitsInput = ({
  id,
  name,
  label,
  onChange,
  defaultValue,
  viewMode,
  className,
  noBorderRadius,
  hideLabel = true,
}: UnitsInputProps) => {
  const [hidden, setHidden] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState<UnitTypes>('mb');
  const node: any = useRef();
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setSelectedUnit(defaultValue.unitType);
      setValue(name, defaultValue?.value?.toString());
    } else {
      setSelectedUnit('mb');
      setValue(name, '');
    }
  }, [defaultValue, setValue, name]);

  // TODO: See if we can refactor the panel component to accept absolute positioned toggle item.
  useEffect(() => {
    /**
     * Handles the user's click depending on whether it is inside or outside of the panel.
     *
     * @param event the click event
     */
    const handleClickOutside = (event: Event) => {
      if (node.current.contains(event.target)) {
        // user clicked inside the panel component
        return;
      }
      // user clicked out the panel component
      setHidden(true);
    };
    if (!hidden) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hidden]);

  /**
   * Toggles the hidden state of the panel.
   */
  const togglePanel = () => {
    setHidden(!hidden);
  };

  /**
   * On click of unit in list.
   *
   * @param unit The selected unit.
   */
  const selectUnit = (unit: UnitOption) => {
    setSelectedUnit(unit.value);
    onChange?.({ value: getValues()[name], unitType: unit.value });
    setHidden(true);
  };

  const onBlur = () => {
    onChange?.({ value: getValues()[name], unitType: selectedUnit });
  };

  return (
    <Wrapper className={className}>
      <Input
        noBorderRadius={noBorderRadius}
        min={0}
        viewMode={viewMode}
        defaultValue={`${defaultValue?.value.toString()} ${defaultValue?.unitType}`}
        id={id}
        label={label}
        hideLabel={hideLabel}
        onBlur={onBlur}
        name={name}
        type={'number'}
      />
      {!viewMode && (
        <UnitsSelector
          data-testid={`${name}-units-input-selector`}
          hideLabel={hideLabel}
          onClick={togglePanel}>
          {memoryUnitOptions.find((unit) => unit.value === selectedUnit)?.label}
        </UnitsSelector>
      )}
      <Switcher ref={node} hidden={hidden}>
        {memoryUnitOptions.map((unit) => (
          <SwitcherItem
            key={unit.value}
            data-testid={`${name}-units-item-${unit.value}`}
            onClick={() => selectUnit(unit)}>
            <span>{unit.label}</span>
          </SwitcherItem>
        ))}
      </Switcher>
    </Wrapper>
  );
};
