// maps resource values and secrets to schema default values
import yaml from 'js-yaml';
import { cloneDeep, get, has, isEmpty, merge, set } from 'lodash';
import { FieldNamesMarkedBoolean } from 'react-hook-form';

import {
  DOT_NOTATION_DELIMITER,
  mapDynamicFormValuesToSchemaDefaults,
} from '@src/components/shared/DynamicForm/utils/dynamic-form-utils';
import { KeyValue } from '@src/models/general';
import {
  CoProvisionResource,
  CoProvisionResourceFields,
  ResourceDefinition,
  ResourceDefinitionVersion,
  ResourceDriver,
  ResourceForm,
  ResourceType,
} from '@src/models/resources';
import { DynamicFormSchema } from '@src/types/dynamic-form';

/**
 * This function traverses the dirtyProps which can be deeply nested, and generates new formData with only the props set to true.
 * The 'newFormData' variable is modified throigh object reference, the function returns void.
 *
 * @param dirtyProps The formState.dirtyProps from react-hook-form.
 * @param originalFormData The formData from react-hook-form.
 * @param newFormData The new Object which will be generated.
 * @param path The path to the formState.dirtyProps property.
 */
export const traverseDirtyPropsAndGenerateNewFormData = (
  dirtyProps: Record<string, unknown> | [],
  originalFormData: Record<string, unknown>,
  newFormData: Record<string, unknown>,
  patternPropertyPaths?: Set<string>,
  path: string = '',
  nestedFormData?: Record<string, unknown>
): void => {
  /**
   * If the current path is contained in patternPropertyPaths, the formData will be in array format.
   * This means that the dirtyProps can have an unhelpful format e.g.
   * ```
   * [
   *   {
   *     key: true,
   *     value: true
   *   }
   * ]
   * ```
   *
   * Since the order from the BE is not guaranteed, it's difficult to know what changed based on `dirtyProps`.
   * So we just add this to the payload.
   */
  if (patternPropertyPaths?.has(`driver_data.${path}`)) {
    set(newFormData, path, get(originalFormData, path));
  } else if (dirtyProps !== null && typeof dirtyProps === 'object') {
    Object.entries(nestedFormData || originalFormData).forEach(([key, value]) => {
      // replace the dots in the key, so they won't get interpreted as part of the path when using the set method to set the value in the object
      const newKey = key.replace(/\./g, DOT_NOTATION_DELIMITER);
      const newPath = path ? `${path}.${newKey}` : newKey;

      if (typeof value === 'object' && value !== null) {
        traverseDirtyPropsAndGenerateNewFormData(
          dirtyProps,
          originalFormData,
          newFormData,
          patternPropertyPaths,
          newPath,
          value as Record<string, unknown>
        );

        // set objects to empty if there are no nested values, to match the input schema
        if (!has(newFormData, newPath)) {
          set(newFormData, newPath, {});
        }
      } else {
        if (value || value === null || typeof value === 'boolean') {
          set(newFormData, newPath, value);
        }
      }
    });
  }
};

/**
 * build  payload by removing not dirty (touched) properties from the form value
 */
export const removeUntouchedPropsFromPayload = (
  formValue: Record<string, any>,
  dirtyProps: Record<string, any>,
  patternPropertyPaths?: Set<string>
): Record<string, any> => {
  const newFormData = {};
  traverseDirtyPropsAndGenerateNewFormData(
    dirtyProps,
    formValue,
    newFormData,
    patternPropertyPaths
  );
  return newFormData;
};

/*
 * according to the API removed properties should be set to null in order to be deleted
 * this function looks for the removed properties recursively and set them to null
 */
interface Properties {
  [key: string]: string | null | Properties;
}
export const setRemovedPropertiesToNull = (
  oldProperties: Properties,
  newProperties: Properties
): Properties => {
  const result = { ...newProperties };
  if (typeof oldProperties === 'object' && typeof newProperties === 'object') {
    Object.entries(oldProperties).forEach(([key, value]) => {
      if (!Object.keys(newProperties).includes(key) || newProperties[key] === '') {
        result[key] = null;
      }
      if (
        value &&
        typeof value === 'object' &&
        newProperties[key] &&
        typeof newProperties[key] === 'object' &&
        !Array.isArray(newProperties[key])
      ) {
        result[key] = setRemovedPropertiesToNull(value, newProperties[key] as Properties);
      }
    });
  }
  return result;
};

/**
 * builds the patch payload for the driver params from the changed data in the form
 *
 * @param formValues
 */
export const buildDriverParamsPayload = (
  formValues: Record<string, any>,
  dirtyFields: FieldNamesMarkedBoolean<ResourceForm>,
  resource?: ResourceDefinition,
  patternPropertyPaths?: Set<string>
) => {
  let dataPayload: Record<string, any> | undefined;

  dataPayload = resource?.driver_inputs
    ? setRemovedPropertiesToNull(resource?.driver_inputs, formValues.driver_data)
    : formValues.driver_data;

  if (dataPayload) {
    // set removed properties in the driver inputs to null
    dataPayload = removeUntouchedPropsFromPayload(
      dataPayload,
      // if the driver_data has no nested dirtyFields return the root dirtyFields, otherwise return the dirty fields that are nested under driver_data
      dirtyFields.driver_data === true ? dirtyFields : dirtyFields.driver_data,
      patternPropertyPaths
    );

    if (isEmpty(dataPayload.values)) {
      delete dataPayload.values;
    }
  }

  return dataPayload;
};

/**
 * This function modifies the form response into a format that is required by backend for resources service.
 *
 * It does the following modifications:
 * - Parses plain text into JSON format (for properties of type='object')
 * - traverseDirtyPropsAndGenerateNewFormData
 * - setRemovedPropertiesToNull
 * - Converts field array format into object (this occours with patternProperties)
 *
 * @param object.formValues - The resource form values in the format react-hook-form uses.
 * @param object.dirtyFields - Dirty fields object from react-hook-form.
 * @param object.propertiesToParse - Properties which should be parsed from string to JSON. Paths are in dot notation.
 * @param object.patternPropertyPaths - Properties which should be converted from array(useFieldArray format) to object. Paths are in dot notation.
 * @param object.resource - The existing resource definition.
 * @param object.isPatternPropertiesEnabled - Flag to stop patternproperties frm being parsed (feature flag).
 */
export const makeResourceSpecificModificationstoDynamicFormValues = (object: {
  formValues: ResourceForm;
  dirtyFields: FieldNamesMarkedBoolean<ResourceForm>;
  propertiesToParse?: Set<string>;
  patternPropertyPaths?: Set<string>;
  resource?: ResourceDefinition;
}) => {
  const { formValues, dirtyFields, propertiesToParse, patternPropertyPaths, resource } = object;
  let newResourceData = cloneDeep(formValues);

  /**
   * If a property was type 'object', and value is type string, it should be parsed as JSON.
   */
  Array.from(propertiesToParse || []).forEach((path) => {
    try {
      const value = get(newResourceData, path);

      if (typeof value === 'string') {
        set(newResourceData, path, yaml.load(value));
      }
    } catch {
      // Catches the case that the field is empty
      // The dynamic form will already have validated that there's valid JSON/YAML.
      // This try/catch if just a fallback to make sure a parsing error doesn't crach the app.
    }
  });

  Array.from(patternPropertyPaths || []).forEach((path) => {
    const newObj = {};
    const ppArray: KeyValue[] = get(newResourceData, path) || [];

    ppArray.forEach((pp) => {
      set(newObj, pp.key, pp.value);
    });

    set(newResourceData, path, newObj);
  });

  newResourceData = {
    ...newResourceData,
    driver_data: buildDriverParamsPayload(
      newResourceData,
      dirtyFields,
      resource,
      patternPropertyPaths
    ),
  };

  return newResourceData;
};

interface FindDriverAndSchemaProps {
  resource?: ResourceDefinition;
  driver?: ResourceDriver;
  resourceType?: ResourceType;
}

export const getDriverSchema = ({ resource, driver, resourceType }: FindDriverAndSchemaProps) => {
  let schema: DynamicFormSchema | undefined;

  const isStaticAsync = driver?.id === 'static-async';

  /**
   * If the driver is 'static' or 'echo', it means we should use the schema as defined in the resourceType.outputs_schema.
   * Otherwise, we use the schema from the driver (driver.inputs_schema).
   */
  if (driver?.id === 'static' || driver?.id === 'echo' || isStaticAsync) {
    const staticAsyncSchema = merge({}, resourceType?.outputs_schema, driver?.inputs_schema);
    // If this is an existing resource, add the values to the defaults to prefull this input fields.
    if (resource && resourceType?.outputs_schema) {
      schema = mapDynamicFormValuesToSchemaDefaults(
        resource.driver_inputs,
        isStaticAsync ? staticAsyncSchema : resourceType?.outputs_schema
      );
    }
    // If the driver is 'static-async', it means we should use the "regular" static schema (resourceType.outputs_schema), merged with the additional driver inputs defined in the driver, see line 294.
    else if (isStaticAsync) {
      schema = staticAsyncSchema;
    }
    // Otherwise, just take the plain outputs_schema.
    else {
      schema = resourceType?.outputs_schema;
    }
  } else if (driver?.inputs_schema) {
    schema = resource
      ? mapDynamicFormValuesToSchemaDefaults(resource.driver_inputs, driver?.inputs_schema)
      : driver?.inputs_schema;
  }

  return {
    driver,
    schema,
  };
};

export const buildCoProvisionResourcesPayload = (
  coprovisionResourcesFormFieldsArray: CoProvisionResourceFields[]
) => {
  return coprovisionResourcesFormFieldsArray?.reduce(
    (
      coprovisionResourcesObject: Record<string, CoProvisionResource>,
      resourceFormFields: CoProvisionResourceFields
    ) => {
      const { match_dependents, is_dependent } = resourceFormFields;
      if (resourceFormFields.resource_descriptor) {
        coprovisionResourcesObject[resourceFormFields.resource_descriptor] = {
          is_dependent,
          match_dependents,
        };
      }
      return coprovisionResourcesObject;
    },
    {}
  );
};

/**
 * Gets the resource definition version with the changes from the form
 *
 * @param resourceDefinitionVersion
 * @param changes
 */
export const getDraftResourceDefinitionVersion = (
  resourceDefinitionVersion: ResourceDefinitionVersion,
  changes: Partial<ResourceDefinition>
) => ({
  ...resourceDefinitionVersion,
  active: false,
  provision: { ...changes.provision },
  driver_inputs: merge(resourceDefinitionVersion.driver_inputs, changes.driver_inputs),
});
