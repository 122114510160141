import { CopyText, Icon, Spinner } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { DeploymentStatus } from '@src/models/deployment-object';
import { units } from '@src/styles/variables';
import { getDeploymentStatus } from '@src/utilities/deployment-status';

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: ${units.fontSize.sm};
`;

const Text = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

export const DeploymentSpinner = styled(Spinner)`
  margin-right: ${units.margin.sm};
`;

const HashContainer = styled.div<{ marginRight?: boolean }>`
  display: flex;
  align-items: center;
  max-width: ${rem(120)};
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${units.margin.sm};
    `}
`;

const SeparatorDisc = styled.div`
  background-color: ${({ theme }) => theme.color.textTranslucent};
  height: ${rem(4)};
  width: ${rem(4)};
  margin: 0 ${units.margin.sm};
  border-radius: 50%;
`;

interface DeploymentTopRowProps {
  date: string;
  hash?: string;
  status?: DeploymentStatus;
  hasRunningPods?: boolean;
  activeDeployment?: boolean;
  isClone?: boolean;
  deploymentEnvId?: string;
}

interface DisplayDeploymentStatusProps
  extends Required<Pick<DeploymentTopRowProps, 'status' | 'activeDeployment' | 'hasRunningPods'>> {
  greyIcon?: boolean;
}

export const DisplayDeploymentStatus = ({
  activeDeployment,
  status,
  greyIcon,
}: DisplayDeploymentStatusProps) => (
  <Title>
    {activeDeployment ? (
      <>
        {status === 'in progress' && <DeploymentSpinner diameter={16} />}
        {status === 'succeeded' && (
          <Icon
            name={'checkmark'}
            overrideColor={'green'}
            marginRight={'sm'}
            disableInvert
            size={12}
          />
        )}
        {status === 'failed' && (
          <Icon
            name={'cross'}
            marginRight={'sm'}
            overrideColor={greyIcon ? 'text-translucent' : 'alert-brighter'}
            size={12}
          />
        )}
      </>
    ) : (
      <>
        {status === 'succeeded' && (
          <Icon
            name={'checkmark'}
            marginRight={'sm'}
            size={12}
            overrideColor={greyIcon ? 'text-translucent' : undefined}
          />
        )}
        {status === 'failed' && (
          <Icon
            name={'cross'}
            marginRight={'sm'}
            size={12}
            overrideColor={greyIcon ? 'text-translucent' : undefined}
          />
        )}
      </>
    )}
    {status === 'timeout' && <Icon name={'clock'} marginRight={'sm'} size={12} />}
    {getDeploymentStatus(status)}
  </Title>
);

const DeploymentEntryMetadata = ({
  status,
  hash,
  date,
  hasRunningPods,
  activeDeployment,
  isClone,
  deploymentEnvId,
}: DeploymentTopRowProps) => {
  const { t } = useTranslation();
  const viewAppTranslations = t('VIEW_APPLICATION');

  return (
    <Wrapper>
      {status && (
        <DisplayDeploymentStatus
          status={status}
          hasRunningPods={Boolean(hasRunningPods)}
          activeDeployment={Boolean(activeDeployment)}
        />
      )}
      {isClone && (
        <Title>
          <Icon name={'copy'} marginRight={'sm'} size={12} disableInvert />
          {`${viewAppTranslations.CLONE_FROM} ${deploymentEnvId}`}
        </Title>
      )}
      {(status || isClone) && (
        <HashContainer marginRight={!isClone}>
          <SeparatorDisc data-testid={'separator-disc'} />
          {hash && (
            <CopyText
              text={hash}
              showText
              textClassName={'txt-sm txt-translucent'}
              overrideCopyButtonColor={'text-translucent'}
            />
          )}
        </HashContainer>
      )}
      {hash && <SeparatorDisc data-testid={'separator-disc'} />}
      <Text>{date}</Text>
    </Wrapper>
  );
};

export default DeploymentEntryMetadata;
