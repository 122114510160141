import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { User } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { groupsQueryKeys } from '../groupsQueryKeys';

export const useGroupsQuery = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: groupsQueryKeys.list(orgId),
    queryFn: () => makeRequest<User[]>('GET', `/orgs/${orgId}/users?type=group`),
    select: (data) => data.data.filter((user) => user.type === 'group'),
    enabled: Boolean(orgId),
  });
};
