export const stripDelimiters = (str: string, delimiterString: { start: string; end: string }) => {
  if (delimiterString) {
    const delimiterStart = new RegExp(`[\\${delimiterString.start}]`, 'ig');
    const delimiterEnd = new RegExp(`[\\${delimiterString.end}]`, 'ig');
    return str.replace(delimiterStart, '').replace(delimiterEnd, '');
  }
};

/**
 * check if a placeholder cannot be resolved based on a lookup object
 */
export const placeholderHasError = (
  value: string,
  lookupObject: Record<string, any>,
  delimiterString: { start: string; end: string }
): boolean => {
  const placeholderSegments = value
    .split('.')
    .map((segement) => stripDelimiters(segement, delimiterString));
  let newLookUpObject: any = { ...lookupObject };
  if (placeholderSegments.length > 1) {
    for (const segment of placeholderSegments) {
      if (segment && newLookUpObject.hasOwnProperty(segment)) {
        newLookUpObject = { ...newLookUpObject[segment] };
      } else {
        return true;
      }
    }
  } else if (placeholderSegments.length === 1 && placeholderSegments[0] !== '') {
    return true;
  }
  return false;
};
