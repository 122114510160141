import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

import Icon from '../Icon/Icon';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: ${units.padding.sm};
  cursor: pointer;
`;

const ExpandableSectionElement = styled.div`
  padding: 0;
`;

export interface ExpandableSectionProps {
  title: string;
  children: ReactNode;
  className?: string;
}

/**
 * An expandable section.
 *
 * Clickable area is an arrow with text. Expanded content can be any element.
 */
const ExpandableSection = ({ className, title, children }: ExpandableSectionProps) => {
  const [expanded, setExpanded] = useState(false);

  const expandItem = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <ExpandableSectionElement className={className}>
      <HeaderWrapper className={'txt-translucent txt-sm'} onClick={expandItem}>
        <Icon
          size={12}
          overrideColor={'text-translucent'}
          name={expanded ? 'arrow-down' : 'arrow-right'}
        />
        <span>{title}</span>
      </HeaderWrapper>
      {expanded && children}
    </ExpandableSectionElement>
  );
};

export default ExpandableSection;
