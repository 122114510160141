import { get } from 'lodash';
import { rem } from 'polished';
import { KeyboardEvent } from 'react';
import { ErrorOption, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

import { InputWrapper, renderDeleteIcon, renderSecretIcon, SharedInputProps } from './InputWrapper';
import { inputCss } from './styles/mixins';

export const inputHeight = {
  large: rem(40),
  medium: rem(32),
  small: rem(24),
};

export const INPUT_LABEL_MARGIN = units.margin.lg;

export type InputSize = 'small' | 'medium' | 'large';
/**
 * Interface for the HTML Input Element.
 */
export interface InputElementProps {
  /** If the field is invalid */
  invalid?: boolean;
  /** The size of the element */
  inputSize?: InputSize;
  /** readonly state */
  readOnly?: boolean;
  allowArrowScrolling?: boolean;
  fakeInput?: boolean;
  noHover?: boolean;
  noBorderRadius?: 'all' | 'right' | 'left';
  /** Makes the input transparent */
  transparent?: boolean;
  rows?: number;
  fontFamily?: 'default' | 'source-code';
  /** Add padding at the right end to give space for an icon*/
  iconRight?: boolean;
  /** Should only be used when having an input inside an input e.g. ComboSelect */
  hideOutline?: boolean;
  /** Minimum height of the fake input*/
  fakeInputMinHeight?: number;
  placeholder?: string;
}

const InputInternal = styled.input<InputElementProps>`
  ${(props) => inputCss(props)}
`;

const FakeInput = styled.span<InputElementProps>`
  ${(props) => inputCss(props)}
  display: inline;
  line-height: ${({ inputSize }) => (inputSize === 'large' ? 2.1 : 1.8)};
  height: auto;
  overflow: hidden;
  outline-offset: ${rem(-2)};
  z-index: 1;
  cursor: text;
  white-space: pre-wrap;
  &:empty:before {
    content: '${({ placeholder }) => placeholder}';
    color: ${({ theme }) => theme.color.textTranslucent};
  }
  ${({ fakeInputMinHeight }) =>
    fakeInputMinHeight &&
    css`
      min-height: ${rem(fakeInputMinHeight)};
    `}

  ${({ readOnly, theme, placeholder }) =>
    readOnly &&
    css`
      &:empty:before {
        content: '${placeholder}';
        color: ${theme.color.textTranslucent};
        opacity: 0;
      }
    `}

  ${({ fakeInput }) =>
    fakeInput &&
    css`
      display: flex;
      position: relative;
      overflow: auto;
      cursor: text;
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
    `}
`;
/**
 * Returns an input component.
 **
 * Default errors messages can be overwritten by passing an InputValidator object
 * and defining the message property, e.g. instead of `required={true}`
 * use `required={{value: true, message: 'Custom error message'}}`.
 */
export const Input = (props: SharedInputProps) => {
  const {
    name,
    id,
    type = 'text',
    placeholder = '',
    size = 'medium',
    readonly = false,
    allowArrowScrolling,
    maxLength,
    max,
    min,
    defaultValue,
    inputRef,
    onClick,
    onChange,
    onInput,
    onKeyDown,
    onMouseDown,
    onBlur,
    onFocus,
    onPaste,
    fakeInput,
    fakeInputMinHeight,
    children,
    propagateClickEvent,
    noHover,
    noBorderRadius,
    transparent,
    ariaControls,
    fontFamily,
    secret,
    autoComplete = 'off',
    tabIndex,
    hideOutline,
    deleteAction,
  } = props;

  // Form
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, name) as ErrorOption;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const handleFakeInputKeyDown = (event: KeyboardEvent) => {
    if (onKeyDown) {
      if (
        maxLength &&
        event.currentTarget.textContent &&
        event.currentTarget.textContent?.length > maxLength
      ) {
        event.preventDefault();
      }
      return onKeyDown(event);
    } else {
      return null;
    }
  };

  return (
    <InputWrapper {...props}>
      {(formRegister) =>
        fakeInput ? (
          <FakeInput
            fakeInputMinHeight={fakeInputMinHeight}
            hideOutline={hideOutline}
            tabIndex={tabIndex}
            data-testid={`${name}-input-element`}
            className={'input-element'}
            fakeInput={fakeInput}
            ref={inputRef}
            invalid={Boolean(errors && name && fieldError)}
            inputSize={size}
            readOnly={readonly}
            placeholder={placeholder}
            onPaste={(event) => onPaste?.(event)}
            onInput={(event) => (onInput ? onInput(event.currentTarget) : null)}
            onBlur={(event) => {
              if (onBlur && event.target.textContent) {
                onBlur(event.target.textContent);
              }
            }}
            onClick={(event) => (onClick ? onClick(event) : event.stopPropagation())}
            onFocus={(event) => (onFocus ? onFocus(event.target.textContent) : null)}
            onMouseDown={(event) => (onMouseDown ? onMouseDown(event) : null)}
            noBorderRadius={noBorderRadius}
            transparent={transparent}
            aria-controls={ariaControls}
            fontFamily={fontFamily}
            onKeyDown={handleFakeInputKeyDown}>
            {children}
          </FakeInput>
        ) : (
          <>
            <InputInternal
              hideOutline={hideOutline}
              {...formRegister}
              size={1}
              tabIndex={tabIndex || 0}
              autoComplete={autoComplete}
              data-testid={`${name}-input-element`}
              className={'input-element'}
              invalid={Boolean(errors && name && fieldError)}
              ref={(e) => {
                // Sharing ref usage: https://react-hook-form.com/faqs#Howtosharerefusage
                if (inputRef) {
                  inputRef.current = e;
                }
                formRegister.ref(e);
              }}
              inputSize={size}
              name={name}
              id={id || name}
              key={id}
              type={type}
              min={min}
              max={max}
              noHover={noHover}
              placeholder={placeholder}
              readOnly={readonly}
              allowArrowScrolling={allowArrowScrolling}
              disabled={!allowArrowScrolling && readonly}
              defaultValue={defaultValue}
              onChange={(event) => {
                formRegister.onChange(event);
                if (onChange) {
                  onChange(event.target.value);
                }
              }}
              onBlur={(event) => {
                formRegister.onBlur(event);
                if (onBlur) {
                  onBlur(event.target.value);
                }
              }}
              onPaste={(event) => onPaste?.(event)}
              onClick={(event) =>
                onClick ? onClick(event) : propagateClickEvent ? null : event.stopPropagation()
              }
              onFocus={(event) => (onFocus ? onFocus(event.target.value) : null)}
              onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : null)}
              onMouseDown={(event) => (onMouseDown ? onMouseDown(event) : null)}
              noBorderRadius={noBorderRadius}
              transparent={transparent}
              aria-controls={ariaControls}
              fontFamily={fontFamily}
              iconRight={secret}
            />
            {secret && renderSecretIcon(uiTranslations.SECRETS_INFO_TEXT)}
            {deleteAction && renderDeleteIcon(deleteAction)}
          </>
        )
      }
    </InputWrapper>
  );
};
