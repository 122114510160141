import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/queries/useGetCurrentUserQuery';
import { Org } from '@src/models/user';
import { getSelectedOrganization } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';

/**
 * Generic component for a public route.
 */
const PublicRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentUserQuery();
  const { data: orgs = [] } = useGetOrgsQuery();

  /**
   * Set the org to redirect to.
   */
  useEffect(() => {
    const savedInLs = getSelectedOrganization();
    const firstOrgId = orgs[0]?.id;
    if (user) {
      if (savedInLs && orgs.find((org: Org) => org.id === savedInLs)) {
        navigate(generateAppURL(savedInLs));
      } else if (firstOrgId) {
        navigate(generateAppURL(firstOrgId));
      }
    }
  }, [navigate, orgs, user]);

  return <>{children}</>;
};

export default PublicRoute;
