import { rem } from 'polished';
import {
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';
import { setCaretPositionToElement } from '@src/utilities/dom-utility';

import Icon from '../Icon/Icon';
import { Input } from '../Input/Input';
import { ValidationTypes } from '../Input/InputWrapper';

export const ArgumentBubble = styled.div<{
  size: 'small' | 'medium' | 'large';
  editMode: 'in-bubble' | 'outside-bubble';
}>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.mainTransparent};
  border-radius: ${({ size }) => (size === 'large' ? rem(9) : rem(6))};
  /* line-height: 1.8; */
  padding: 0 ${rem(6)};
  flex: 0 1 ${rem(18)};
  margin-right: ${rem(3)};
  white-space: nowrap;
  &:focus {
    outline: none;
  }
  font-size: ${({ size }) =>
    size === 'large'
      ? units.fontSize.sm
      : size === 'medium'
        ? units.fontSize.sm
        : units.fontSize.sm};
  margin: ${({ size, editMode }) =>
    editMode === 'outside-bubble' &&
    (size === 'large'
      ? `${rem(5)} ${rem(6)}`
      : size === 'medium'
        ? `${rem(4)} ${rem(6)}`
        : `${rem(2)} ${rem(2)}`)};
  margin-right: ${({ editMode }) => editMode === 'outside-bubble' && 0};

  ${({ editMode }) =>
    editMode === 'outside-bubble' &&
    css`
      pointer-events: none;
    `}
`;

const FakeInput = styled(Input)<{ editMode: 'in-bubble' | 'outside-bubble' }>`
  ${({ editMode }) =>
    editMode === 'outside-bubble' &&
    css`
      > .input-element {
        padding: 0;
      }
    `};
`;

const OutsideBubbleInput = styled(Input)`
  min-width: ${rem(100)};
  width: 100%;
  .input-element {
    min-height: unset;
    height: 100%;
  }
`;

const RemoveIcon = styled(Icon)`
  pointer-events: all;
`;

export interface ArgumentsInputProps {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
  hideLabel?: boolean;
  defaultArguments?: string[];
  onChange?: (args: string[]) => any;
  readonly?: boolean;
  noBorderRadius?: 'all' | 'right' | 'left';
  editMode?: 'in-bubble' | 'outside-bubble';
  size?: 'small' | 'medium' | 'large';
  /** Separator for splitting input strings */
  separators?: string[];
  /** In case of `outside-bubble`, validate agains a regex. If regex passes, the string will be split */
  matchPattern?: RegExp;
  resetArgsState?: [boolean, Dispatch<SetStateAction<boolean>>];
  hideError?: boolean;
  /** A set of standard validation for inputs */
  standardValidation?: ValidationTypes[];
}

const ArgumentsInput = ({
  className,
  name,
  label,
  placeholder,
  hideLabel = false,
  defaultArguments,
  onChange,
  readonly,
  noBorderRadius,
  size = 'medium',
  editMode = 'in-bubble',
  separators,
  matchPattern,
  resetArgsState,
  hideError,
  standardValidation,
}: ArgumentsInputProps) => {
  // Component state
  const [args, setArgs] = useState<string[]>([]);
  const [resetArgs, setResetArgs] = resetArgsState || [];
  const [argsChanged, setArgsChanged] = useState(false);
  // If `shouldFocus` is boolean, focus on last one. If there is a number specified, focus on that index.
  const [shouldFocus, setShouldFocus] = useState<boolean | number>(false);
  const fakeInputRef = useRef<any>(null);
  const [focusedOnChild, setFocusedOnChild] = useState(false);

  const { setValue, getValues } = useFormContext();

  // For reference in setTimeout.
  const focusedOnChildRef = useRef<boolean>(focusedOnChild);
  focusedOnChildRef.current = focusedOnChild;

  /**
   * set the value in the form whenever the args change
   */
  useEffect(() => {
    setValue(
      `${name}-wrapper`,
      args.filter((a) => a !== ''),
      { shouldDirty: argsChanged }
    );
  }, [args, name, setValue, argsChanged]);

  /**
   * If the form is reset, reset args to empty array.
   */
  useEffect(() => {
    if (resetArgs && setResetArgs) {
      setArgs([]);
      setResetArgs(false);
    }
  }, [args, resetArgs, setResetArgs]);

  useEffect(() => {
    if (editMode === 'outside-bubble' && onChange) {
      onChange(args);
    } else if (onChange && argsChanged) {
      // Wait 500 ms to check if child inputs are focused. If not, the user has exited the arguments input.
      setTimeout(() => {
        if (!focusedOnChildRef.current) {
          onChange(args.filter((arg) => arg));
          setArgsChanged(false);
          setArgs((prevState) => prevState.filter((arg) => arg.length));
        }
      }, 500);
    }
  }, [args, onChange, argsChanged, editMode]);

  useEffect(() => {
    if (defaultArguments?.length) {
      setArgs(defaultArguments);
    }
  }, [defaultArguments]);

  /**
   * Focuses on the selected or last bubble.
   */
  const focusOnArgumentBubble = useCallback(() => {
    // Focus on specific
    if (typeof shouldFocus === 'number') {
      const element = fakeInputRef.current.querySelector(
        `.argument-bubble[data-key="${shouldFocus}"]`
      );
      setCaretPositionToElement(element);
      element?.focus();
      setShouldFocus(false);
      // Focus on last
    } else if (shouldFocus) {
      const all = fakeInputRef.current.querySelectorAll(`.argument-bubble`);
      const last = all[all.length - 1];
      setCaretPositionToElement(last);
      last?.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  /**
   * Listens to `shouldFocus`.
   */
  useEffect(() => {
    if (typeof shouldFocus === 'number' || shouldFocus === true) {
      focusOnArgumentBubble();
    }
  }, [shouldFocus, focusOnArgumentBubble]);

  /**
   * When the fake input is clicked, add a bubble if there is none, or focus on last one.
   */
  const clickOnInput = () => {
    // event.currentTarget.focus
    if (!args.length && editMode === 'in-bubble') {
      // Add first one if it's not there
      addArgumentBubble();
    }
    if (editMode === 'in-bubble') {
      setShouldFocus(true);
      focusOnArgumentBubble();
    }
  };

  const addArgumentBubble = (focus?: boolean) => {
    setArgs((prevState) => {
      if (focus) {
        setShouldFocus(true);
      }
      return [...prevState, ''];
    });
  };

  /**
   * Handles adding the extra empty bubble.
   */
  const onInput = (event: SyntheticEvent) => {
    const content = event.currentTarget.textContent;
    const key = event.currentTarget.getAttribute('data-key');
    setArgsChanged(true);

    if (content && key && parseInt(key, 10) === args.length - 1) {
      addArgumentBubble();
      setShouldFocus(false);
    }
  };

  /**
   * Handles setting the args to state.
   */
  const onBlur = (event: FocusEvent<HTMLDivElement>) => {
    setFocusedOnChild(false);
    const content = event.target.textContent;
    const key = event.target.getAttribute('data-key');
    const newArgs = [...args];
    if (key) {
      if (content) newArgs.splice(parseInt(key, 10), 1, content);
      else newArgs.splice(parseInt(key, 10), 1);
    }

    setArgs(newArgs);
    // Stops duplication of entered value, and rendered arg.
    event.target.textContent = content;
  };

  /**
   * Handles whether the current bubble should be deleted.
   */
  const shouldDelete = (event: KeyboardEvent) => {
    const content = event.currentTarget.textContent;
    const dataKeyAttr = event.currentTarget.getAttribute('data-key');
    const key = dataKeyAttr && parseInt(dataKeyAttr, 10);
    const deletePressed = event.keyCode === KeyBindings.BACKSPACE;

    if (deletePressed && !content && typeof key === 'number') {
      const focusOn = key === 0 ? 0 : key - 1;
      setShouldFocus(focusOn);
      setArgs((prevState) => {
        const newArgs = [...prevState];
        newArgs.splice(key, 1);
        // If the last argument is deleted, there is no element to focus on
        if (newArgs.length === 0) setFocusedOnChild(false);
        return newArgs;
      });
    }
  };

  const preventDefault = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const checkPattern = (value: string) =>
    (!matchPattern && value.length) || (matchPattern && matchPattern.test(value));

  const onInputChange = (value: string, isOnBlur?: boolean) => {
    let inputText = value;
    const entries: string[] = [];

    if (isOnBlur && checkPattern(value)) {
      entries.push(value);
    }

    if (!separators?.length) return;

    const firstSeparator = separators[0]!;
    for (const separator of separators) {
      inputText = inputText.replace(new RegExp(separator, 'g'), firstSeparator);
      inputText = inputText.replace(new RegExp(`${firstSeparator}+`, 'g'), firstSeparator);
    }

    const splitEntries = inputText.split(firstSeparator);

    if (splitEntries.length > 1) {
      for (const entry of splitEntries) {
        if (checkPattern(entry)) {
          entries.push(entry);
        }
      }
    }

    if (entries.length) {
      setArgs((prevState) => [...prevState, ...entries]);
      setValue(name, '');
    } else if (separators?.includes(value)) {
      setValue(name, '');
    }
  };

  const removeArg = (index: number) => {
    setArgs((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.BACKSPACE && !getValues()[name]) {
      setArgs((prevState) => {
        prevState.pop();
        return [...prevState];
      });
    }
  };

  return (
    <FakeInput
      noBorderRadius={noBorderRadius}
      fakeInput
      labelAbove={Boolean(args.length)}
      label={label}
      hideLabel={hideLabel}
      placeholder={placeholder}
      name={`${name}-wrapper`}
      className={className}
      // Make tabbable for when user wants to tab into empty args array field
      tabIndex={!args.length ? 0 : undefined}
      readonly={readonly}
      size={size}
      editMode={editMode}
      onClick={clickOnInput}
      onFocus={() => {
        // In the case user wants to tab intop empty args array field
        if (!args.length && editMode === 'in-bubble') {
          addArgumentBubble(true);
        }
      }}
      inputRef={fakeInputRef}>
      {args.map((arg, index) => (
        <ArgumentBubble
          className={'argument-bubble'}
          data-testid={`argument-bubble-${index}`}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          size={size}
          editMode={editMode}
          contentEditable={!readonly}
          suppressContentEditableWarning={!readonly}
          data-key={index}
          onClick={preventDefault}
          onInput={onInput}
          onBlur={onBlur}
          onFocus={() => setFocusedOnChild(true)}
          onKeyDown={shouldDelete}>
          {arg}
          {editMode === 'outside-bubble' && (
            <RemoveIcon
              name={'remove'}
              size={12}
              pointer
              marginLeft={'sm'}
              onClick={() => removeArg(index)}
            />
          )}
        </ArgumentBubble>
      ))}
      {editMode === 'outside-bubble' && (
        <OutsideBubbleInput
          placeholder={!args.length ? placeholder : undefined}
          size={size}
          transparent
          hideLabel
          onKeyDown={onKeyDown}
          onChange={onInputChange}
          onBlur={(value: string) => onInputChange(value, true)}
          name={name}
          hideError={hideError}
          standardValidation={standardValidation}
        />
      )}
      {/* <ArgumentBubble size="large" editMode="in-bubble" /> */}
    </FakeInput>
  );
};

export default ArgumentsInput;
