/// <reference types="vite-plugin-svgr/client" />
import { WalModal } from '@humanitec/ui-components';
import { em } from 'polished';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AdBlockerSVG from '@src/assets/svg/ad-blocker.svg?react';
import { CodePlaceholder } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';

const AdBlockerHeading = styled.h3`
  color: ${({ theme }) => theme.color.text};
`;

const AdBlockerImage = styled(AdBlockerSVG)`
  width: ${em(176)};
`;

const AdBlockerImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${units.margin.lg} 0;
`;

export const AdBlockerLink = styled.a`
  font-size: ${units.fontSize.base};
`;

const AdBlockerText = styled.p`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.lg};
  color: ${({ theme }) => theme.color.text};
`;

interface AdBlockerWarningModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const AdBlockerWarningModal = ({ openState }: AdBlockerWarningModalProps) => {
  // i18n
  const { t } = useTranslation();
  const appRootTranslations = t('APP_ROOT');
  return (
    <WalModal openState={openState} size={'large'} disableClickOutside>
      <AdBlockerHeading>{appRootTranslations.AD_BLOCKER_MODAL_TITLE}</AdBlockerHeading>
      <AdBlockerImageWrapper>
        <AdBlockerImage />
      </AdBlockerImageWrapper>
      <AdBlockerText>
        <Trans i18nKey={'AD_BLOCKER_MODAL_TEXT1'}>
          <AdBlockerLink
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={'https://www.optimizely.com/'}
          />
        </Trans>
      </AdBlockerText>
      <AdBlockerText>
        <Trans i18nKey={'AD_BLOCKER_MODAL_TEXT2'}>
          <CodePlaceholder />
          <CodePlaceholder />
          <AdBlockerLink
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={
              'https://developer.humanitec.com/platform-orchestrator/troubleshooting/#unavailability-of-features-in-the-ui'
            }
          />
        </Trans>
      </AdBlockerText>
    </WalModal>
  );
};

export default AdBlockerWarningModal;
