import { Icon } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrgRoles, Role } from '@src/models/role';
import { units } from '@src/styles/variables';

const UsernameWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr auto;
  grid-gap: ${units.margin.lg};
`;

const Field = styled.span`
  word-break: break-all;
  white-space: normal;
`;

const Status = styled.span`
  font-size: ${units.fontSize.sm};
`;

const Email = styled(Field)`
  word-break: break-all;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const UserDropdownItem = ({ user }: { user: Role<OrgRoles> }) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('COMPONENTS').FILTER_ORG_MEMBERS;

  return (
    <>
      <UsernameWrapper>
        <Field>{user.name}</Field>
        <span>
          <Icon
            name={
              user.type === 'service' ? 'robot' : user.type === 'group' ? 'org-members' : 'person'
            }
          />
        </span>
        <Status>
          {user.type === 'invitation'
            ? translations.PENDING
            : user.type === 'user'
              ? translations.ACTIVE
              : user.type === 'group'
                ? translations.GROUP
                : translations.SERVICE}
        </Status>
      </UsernameWrapper>
      <UsernameWrapper>
        <Email>{user.email}</Email>
      </UsernameWrapper>
    </>
  );
};

export default UserDropdownItem;
