import { CloseIcon, EmptyStateCard, FlowDiagram, Spinner, WalCard } from '@humanitec/ui-components';
import { Edge, MarkerType, Node, ReactFlowProvider } from '@xyflow/react';
import { useMemo, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import { useResourceDeploymentTimes } from '@src/hooks/useResourceDeploymentTimes';
import i18n from '@src/i18n/i18n';
import { DeploymentObject } from '@src/models/deployment-object';
import {
  ActiveResource,
  ResourceCategory,
  ResourceDefinition,
  ResourceDependencyGraph as ResourceDependencyGraphModel,
  ResourceDependencyGraphNode,
  ResourceType,
  ResourceTypes,
} from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL } from '@src/utilities/navigation';

import ResourceNode from './components/ResourceNode';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  row-gap: ${units.margin.md};
  column-gap: ${units.margin.md};
  margin-bottom: ${units.margin.md};
  overflow: auto;
`;

const SideBar = styled(WalCard)`
  flex: 1 1 180px;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  border-radius: 4px;
`;

const ReactFlowContainer = styled.div<{ hideContainerBorder?: boolean }>`
  flex-grow: 1;
  min-width: 80%;
  min-height: 70%;
  ${({ hideContainerBorder, theme }) =>
    !hideContainerBorder &&
    css`
      border: 1px solid ${theme.color.baseOutline};
    `}
  border-radius: 4px;
`;

const FullWidthEmptyCard = styled(EmptyStateCard)`
  width: 100%;
`;
const nodeTypes = {
  resourceNode: ResourceNode,
};

export interface ResourceNodeData extends Record<string, unknown> {
  res_id?: string;
  guresid: string;
  class: string;
  def_id: string;
  type: ResourceTypes;
  provisionTime?: string;
  category?: ResourceCategory;
}

export interface ResourceDependencyGraphProps {
  resourceDependencyGraph: Partial<ResourceDependencyGraphModel> | undefined;
  resourceDependencyGraphFetching?: boolean;
  resourceTypes: ResourceType[] | undefined;
  activeResources?: ActiveResource[];
  resourceDefinitions: ResourceDefinition[] | undefined;
  deployment?: DeploymentObject;
  hideContainerBorder?: boolean;
  showDriverAccount?: boolean;
  showResDefLink?: boolean;
}

export const ResourceDependencyGraph = ({
  deployment,
  resourceDependencyGraph,
  resourceDependencyGraphFetching,
  resourceTypes,
  activeResources,
  hideContainerBorder,
  resourceDefinitions,
  showDriverAccount = false,
  showResDefLink = false,
}: ResourceDependencyGraphProps) => {
  // state
  const [selectedResource, setSelectedResource] = useState<ActiveResource>();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  // i18n
  const { t } = useTranslation();
  const graphTranslations = t('UI');

  // Router
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const provisionTimes = useResourceDeploymentTimes(
    deployment,
    activeResources,
    resourceDependencyGraph?.nodes
  );

  const nodes: Node<ResourceNodeData>[] = useMemo(() => {
    return resourceTypes
      ? resourceDependencyGraph?.nodes?.map((node) => ({
          id: node.guresid,
          position: { x: 0, y: 0 },
          type: 'resourceNode',
          data: {
            res_id: node.id,
            guresid: node.guresid,
            class: node.class,
            def_id: node.def_id,
            type: node.type,
            provisionTime: provisionTimes[node.guresid]?.provision_time,
            category: resourceTypes?.find((rt) => rt.type === node.type)?.category,
          },
        })) || []
      : [];
  }, [resourceDependencyGraph, provisionTimes, resourceTypes]);

  const edges = useMemo(
    () =>
      resourceDependencyGraph?.nodes?.reduce((acc: Edge[], node: ResourceDependencyGraphNode) => {
        return node.depends_on.length > 0
          ? [
              ...acc,
              ...node.depends_on.map((dependencyGuresId) => ({
                id: `${node.guresid}-${dependencyGuresId}`,
                source: node.guresid,
                target: dependencyGuresId,
                markerEnd: { type: MarkerType.ArrowClosed, strokeWidth: 5 },
              })),
            ]
          : acc;
      }, []) || [],
    [resourceDependencyGraph]
  );

  const handleNodeClick = (node: Node<ResourceNodeData>) => {
    setSelectedResource(
      activeResources?.find((activeResource) => activeResource.gu_res_id === node.data.guresid)
    );
    setSidebarOpen(true);
  };

  const handlePaneClick = () => {
    if (selectedResource) {
      setSelectedResource(undefined);
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Container>
        <Wrapper className={'flex-column'}>
          {resourceDependencyGraph ? (
            <>
              <ReactFlowContainer
                data-testid={'react-flow-wrapper'}
                hideContainerBorder={hideContainerBorder}>
                <ReactFlowProvider>
                  <FlowDiagram
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    showControls
                    onNodeClick={handleNodeClick}
                    onPaneClick={handlePaneClick}
                    resourceDependencyGraphLink={`${generateAppURL(orgId, appId, envId)}/resource-graph/${resourceDependencyGraph.id}`}
                  />
                </ReactFlowProvider>
              </ReactFlowContainer>
              {sidebarOpen && selectedResource && (
                <SideBar>
                  <CloseIcon onClick={() => setSidebarOpen(false)} />
                  <div className={'flex-row flex-centered mb-lg'}>
                    <ResourceIcon type={selectedResource.type} hasMargin />
                    <span className={'mr-md'}>{selectedResource.res_id}</span>
                  </div>
                  {resourceDefinitions && (
                    <ResourceCardDetails
                      activeResource={selectedResource}
                      resourceDefinitions={resourceDefinitions}
                      provisionTime={provisionTimes[selectedResource.gu_res_id]?.provision_time}
                      hideResourceId
                      showDriverType
                      showDriverAccount={showDriverAccount}
                      showResDefLink={showResDefLink}
                    />
                  )}
                </SideBar>
              )}
            </>
          ) : resourceDependencyGraphFetching ? (
            <Spinner />
          ) : (
            <FullWidthEmptyCard>{graphTranslations.NO_GRAPH_AVAILABLE}</FullWidthEmptyCard>
          )}
        </Wrapper>
      </Container>
    </I18nextProvider>
  );
};
