import { isEqual, uniqBy } from 'lodash';
import { rem } from 'polished';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import { Button } from '../../base/Button/Button';
import Icon from '../../base/Icon/Icon';
import { WalLabel } from '../../base/Label';
import { Text } from '../../base/Text/Text';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import { GroupByItem } from '../Filters/Filters';
import WalDropdownMenu from '../Menu/DropdownMenu/DropdownMenu';
import MultiFilter, { FilterByOptions, FilterConditions } from '../MultiFilter/MultiFilter';
import { WalTable, WalTableProps, WalTableRow } from '../Table/Table';

const SkeletonWrapper = styled.span`
  margin-top: ${rem(50)};
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const GroupBySection = styled.div`
  margin-bottom: ${units.margin.lg};
  width: 100%;
`;

const GroupedTable = styled(WalTable)<{ alignGroupedTableCellByWidthOf?: string }>`
  ${({ alignGroupedTableCellByWidthOf }) =>
    alignGroupedTableCellByWidthOf &&
    css`
      td {
        width: ${alignGroupedTableCellByWidthOf};
        word-break: break-all;
      }
    `}

  ${({ alignGroupedTableCellByWidthOf, tableStyle }) =>
    alignGroupedTableCellByWidthOf &&
    tableStyle === 'expandable' &&
    css`
      td:first-child {
        width: 40px;
      }
    `}
`;

const GroupByTitle = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.sm};
  font-weight: normal;
`;

interface MultiFilterTableProps extends WalTableProps {
  filterByOptions: FilterByOptions;
  onFiltersChange: (conditions?: FilterConditions) => void;
  filteredRows?: WalTableRow[];
  /* text that will be shown when no items corresponds to the applied filters */
  noItemsTextFiltersApplied?: string;
  /* text that will be shown when no items and no filters are applied filters */
  noItemsText?: string;
  /* the initial number of rows before filtering */
  initialRowsCount: number;
  /* custom message to be displayed when filter is applied */
  hideFilterMessage?: boolean;
  defaultConditions?: FilterConditions;
  /* the loading state */
  isLoading?: boolean;
  infiniteScroll?: {
    isLoadingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    hasError: boolean;
  };
  groupByOptions?: GroupByItem<string>[];
  /* This is used to give columns of grouped tables a fixed size so they would be vertically aligned. You can pass any value of css width property https://developer.mozilla.org/en-US/docs/Web/CSS/width*/
  alignGroupedTableCellByWidthOf?: string;
  hideFilters?: boolean;
}

const MultiFilterTable = ({
  filterByOptions,
  onFiltersChange,
  initialRowsCount,
  noItemsText,
  noItemsTextFiltersApplied,
  hideFilterMessage = false,
  defaultConditions = {},
  isLoading,
  hideFilters,
  ...tableProps
}: MultiFilterTableProps) => {
  const { rows, groupByOptions } = tableProps;
  const [filterConditions, setFilterConditions] = useState<FilterConditions | undefined>(
    defaultConditions
  );
  const [groupByItem, setGroupByItem] = useState<GroupByItem<string>>();

  const methods = useWalhallForm({
    defaultValues: {
      groupByOption: groupByOptions?.[0]?.id,
    },
  });

  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [_, setSearchParams] = useSearchParams();

  const handleFilterChange = (conditions: FilterConditions) => {
    setFilterConditions(conditions);
    onFiltersChange(conditions);
  };

  const handleGroupByChange = (id: string, item: GroupByItem<string>) => {
    setGroupByItem(item);
  };

  const handleClearFilter = () => {
    setFilterConditions(defaultConditions);
    onFiltersChange(defaultConditions);
    setSearchParams('');
  };

  const generateFilterMessage = (
    currentRows: any[] | undefined,
    initialRowsLength: number,
    filtersHiddenText: string
  ) => {
    if (currentRows && currentRows.length !== initialRowsLength) {
      const hiddenCount = initialRowsLength - (rows.length || 0);
      return `${hiddenCount} ${filtersHiddenText}`;
    }
    return '';
  };

  const renderGroupedTables = () => {
    if (groupByItem) {
      const groups = uniqBy(
        rows.map((row) => ({
          groupedByValue: row.data[groupByItem.value],
          groupedByParentValue: groupByItem.parent && row.data[groupByItem.parent],
        })),
        (row) => `${row.groupedByParentValue}${row.groupedByValue}`
      );
      return groups?.map((group) => (
        <GroupBySection key={`${group.groupedByParentValue}${group.groupedByValue}`}>
          <GroupByTitle>
            {group.groupedByParentValue && (
              <>
                {group.groupedByParentValue}{' '}
                <Icon name={'arrow-right'} marginRight={'md'} marginLeft={'md'} size={12} />
              </>
            )}
            {group.groupedByValue}
          </GroupByTitle>
          <GroupedTable
            {...tableProps}
            className={'grouped-table'}
            rows={rows
              .filter((row) =>
                group && groupByItem && groupByItem.parent
                  ? row.data[groupByItem.parent] === group.groupedByParentValue
                  : true
              )
              .filter((row) =>
                group && groupByItem ? row.data[groupByItem.value] === group.groupedByValue : true
              )}
          />
        </GroupBySection>
      ));
    }
  };

  const areFiltersApplied = !isEqual(filterConditions, defaultConditions);
  const isNoItems = rows?.length === 0;

  return (
    <>
      <div className={'flex-centered mb-md'}>
        {!hideFilters && (
          <>
            <div className={'flex-centered mb-md'}>
              {groupByOptions && groupByOptions.length > 0 && (
                <FormProvider {...methods}>
                  <div className={'flex-centered mr-md'}>
                    <WalLabel>{uiTranslations.GROUP_BY}</WalLabel>
                    <WalDropdownMenu
                      className={'ml-md'}
                      name={'groupByOption'}
                      items={groupByOptions}
                      onItemClick={handleGroupByChange}
                      buttonVariant={'secondary'}
                      buttonSize={'medium'}
                    />
                  </div>
                </FormProvider>
              )}
              <MultiFilter
                disabled={isLoading}
                filterByOptions={filterByOptions}
                onFiltersChange={handleFilterChange}
                defaultConditions={filterConditions}
              />
            </div>

            {areFiltersApplied && (
              <span>
                {!hideFilterMessage && (
                  <Text size={'sm'} className={'mr-sm'}>
                    {generateFilterMessage(rows, initialRowsCount, uiTranslations.FILTERS_HIDDEN)}
                  </Text>
                )}
                <Button variant={'borderless-blue'} onClick={handleClearFilter}>
                  {uiTranslations.CLEAR_FILTERS}
                </Button>
              </span>
            )}
          </>
        )}

        {isLoading && (
          <SkeletonWrapper>
            <SkeletonGroup count={10} itemProps={{ height: 47 }} />
          </SkeletonWrapper>
        )}
        {!isLoading && groupByItem ? (
          renderGroupedTables()
        ) : (
          <WalTable {...tableProps} rows={rows} />
        )}
      </div>
      {isNoItems && !isLoading && (
        <EmptyStateCard className={'flex-centered mb-md'}>
          {areFiltersApplied
            ? noItemsTextFiltersApplied || uiTranslations.NO_ITEMS_FILTERS
            : noItemsText || uiTranslations.NO_ITEMS}
        </EmptyStateCard>
      )}
    </>
  );
};

export default MultiFilterTable;
