import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { makeResourceSpecificModificationstoDynamicFormValues } from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/resource-form.utils';
import { ResourceForm } from '@src/models/resources';
import { units } from '@src/styles/variables';
import {
  patternPropertiesJSONSchema,
  uiHintsJSONSchema,
} from '@src/testing-utils/dynamic-form-mock';
import { DynamicFormSchema } from '@src/types/dynamic-form';
import { useWalhallForm } from '@src/utilities/form';

import WYSIWYG from '../../WYSIWYG/WYSIWYG';
import DynamicForm, { DynamicFormProps } from '../DynamicForm';
import { FieldProps } from '../utils/dynamic-form-types';
import { isPatternProperties } from '../utils/dynamic-form-utils';
import { FormPlaygroundResponseModal } from './FormPlaygroundResponseModal';

const postgres: DynamicFormSchema = {
  type: 'object',
  properties: {
    secrets: {
      properties: {
        dbcredentials: {
          properties: {
            password: {
              title: 'Password',
              type: 'string',
              uiHints: {
                group: 'secrets',
                inputType: 'password',
              },
            },
            username: {
              title: 'User / Role',
              type: 'string',
              uiHints: {
                group: 'secrets',
              },
            },
          },
          title: 'Superuser credentials for the instance',
          type: 'object',
        },
      },
      required: ['dbcredentials'],
      type: 'object',
    },
    values: {
      type: 'object',
      properties: {
        append_host_to_user: {
          description:
            'Azure Databases for Postgres and MySQL require usernames to have `@servername` appended to them. Set this to `true` for the driver to append this automatically.',
          title: 'Append host to user',
          type: 'boolean',
          uiHints: {
            group: 'values',
            order: 3,
          },
        },
        host: {
          description: 'The IP Address or hostname that the instance is available on.',
          title: 'Hostname or IP: ',
          type: 'string',
          uiHints: {
            group: 'hostport',
            width: 2,
          },
        },
        name: {
          description: 'The name of the maintenance database to connect to. (Optional)',
          title: 'Database name',
          type: 'string',
          uiHints: {
            group: 'values',
            order: 1,
          },
        },
        port: {
          description: 'The port on the instance that the database is available on.',
          maximum: 65535,
          minimum: 0,
          title: 'Port',
          type: 'integer',
          uiHints: {
            group: 'hostport',
          },
        },
      },
    },
  },
  uiGroups: {
    secrets: {
      type: 'section',
      title: 'Credentials',
    },
    values: {
      type: 'section',
      title: 'Values',
    },
    hostport: {
      type: 'row',
      order: 2,
      section: 'values',
    },
  },
};

const mariadboverssh: DynamicFormSchema = {
  type: 'object',
  properties: {
    values: {
      properties: {
        append_host_to_user: {
          description:
            'Azure Databases for Postgres and MySQL require usernames to have `@servername` appended to them. Set this to `true` for the driver to append this automatically.',
          title: 'Append host to user',
          type: 'boolean',
          uiHints: {
            group: 'values',
          },
        },
        host: {
          description: 'The IP Address or hostname that the instance is available on.',
          type: 'string',
          uiHints: {
            group: 'hostport',
            width: 2,
          },
        },
        port: {
          description: 'The port on the instance that the database is available on.',
          maximum: 65535,
          minimum: 0,
          type: 'integer',
          uiHints: {
            group: 'hostport',
          },
        },
        ssh_host: {
          description: 'The IP Address or hostname of teh SSH Server.',
          title: 'SSH Host',
          type: 'string',
          uiHints: {
            group: 'sshhostport',
            width: 2,
          },
        },
        ssh_port: {
          description: 'The port on the SSH server is listening on. Defaults to 22.',
          maximum: 65535,
          minimum: 0,
          title: 'SSH Port',
          type: 'integer',
          uiHints: {
            group: 'sshhostport',
          },
        },
        ssh_user: {
          description: 'The user to connect to the SSH server on.',
          title: 'SSH Username',
          type: 'string',
          uiHints: {
            group: 'values',
            order: 3,
          },
        },
      },
      type: 'object',
    },
    secrets: {
      properties: {
        dbcredentials: {
          properties: {
            password: {
              title: 'Password',
              type: 'string',
              uiHints: {
                group: 'login',
                inputType: 'password',
                order: 2,
              },
            },
            username: {
              title: 'User / Role',
              type: 'string',
              uiHints: {
                group: 'login',
                order: 1,
              },
            },
          },
          title: 'Superuser credentials for the instance',
          type: 'object',
        },
      },
      required: ['dbcredentials'],
      type: 'object',
    },
  },
  uiGroups: {
    login: {
      type: 'section',
      order: 1,
      title: 'Login',
    },
    values: {
      type: 'section',
      order: 2,
      title: 'Values',
    },
    sshhostport: {
      type: 'row',
      order: 1,
      section: 'values',
      description:
        'Custom description to show on combined fields. Not ideal as we need to define the description twice.',
    },
    hostport: {
      type: 'row',
      order: 2,
      section: 'values',
    },
  },
};

const yamlValidation: DynamicFormSchema = {
  properties: {
    values: {
      type: 'object',
      properties: {
        json: {
          description: 'An object with one required parameter.',
          properties: {
            level1: {
              type: 'object',
              properties: {
                level2: {
                  properties: {
                    nestedinstance: {
                      pattern: '^[0-9a-z][0-9a-z-]+[0-9a-z]$',
                      title: 'Nested Parameter',
                      type: 'string',
                    },
                  },
                  required: ['nestedinstance'],
                },
              },
            },
            required_param: {
              pattern: '^[0-9a-z][0-9a-z-]+[0-9a-z]$',
              title: 'Required Parameter',
              type: 'string',
            },
            second_param: {
              pattern: '^[0-9a-z][0-9a-z-]+[0-9a-z]$',
              title: 'Second Parameter',
              type: 'string',
            },
          },
          required: ['required_param', 'level1'],
          title: 'JSON Object',
          type: 'object',
          uiHints: {
            inputType: 'textarea',
          },
        },
        another: {
          type: 'string',
        },
        minimum: {
          type: 'number',
          minimum: 3,
        },
        maximum: {
          type: 'number',
          maximum: 3,
        },
        minLength: {
          type: 'string',
          minLength: 3,
        },
        maxLength: {
          type: 'string',
          maxLength: 3,
        },
        pattern: {
          type: 'string',
          pattern: '[A-Z]',
        },
      },
      required: ['another', 'json'],
    },
  },
  type: 'object',
};

const StyledDynamicForm = styled(DynamicForm)`
  margin-bottom: ${units.margin.lg};
`;

const SubmitButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const RenderForm = (props: DynamicFormProps & { onSubmit: (formValues: any) => void }) => {
  const { handleSubmit, reset } = useFormContext();

  const onSubmit = async (formValues: any) => {
    props.onSubmit(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledDynamicForm {...props} />
      <div>
        <SubmitButton type={'submit'}>Submit</SubmitButton>
        <Button variant={'secondary'} onClick={() => reset()}>
          Reset
        </Button>
      </div>
    </form>
  );
};

const FormPlayground = () => {
  // Form
  const formMethods = useWalhallForm<ResourceForm>({ shouldUnregister: true });

  const {
    formState: { dirtyFields },
  } = formMethods;

  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [formValues, setFormValues] = useState();
  const [modifiedFormValues, setModifiedFormValues] = useState();

  const [propertiesToParse, setPropertiesToParse] = useState<Set<string>>(new Set<string>());
  /**
   * Any dynamic form with patternProperties will result in an array with entries containing 'key', 'value'. The backend does not expect array format, so we need to convert to an object. This state specifies the paths to such patternProperties.
   */
  const [patternPropertyPaths, setPatternPropertyPaths] = useState<Set<string>>(new Set<string>());

  const onFormSubmit = (data: any) => {
    setModifiedFormValues(
      makeResourceSpecificModificationstoDynamicFormValues({
        formValues: data,
        propertiesToParse,
        patternPropertyPaths,
        dirtyFields,
      }).driver_data
    );
    setFormValues(data);
    setResponseModalOpen(true);
  };

  const onFieldCallback = ({ schema: callbackSchema, path }: FieldProps) => {
    if (isPatternProperties(callbackSchema)) {
      setPatternPropertyPaths((prevState) => {
        if (!prevState.has(path)) {
          prevState.add(path);
        }
        return prevState;
      });
    }
    if (callbackSchema?.type === 'object') {
      setPropertiesToParse((prevState) => {
        if (!prevState.has(path)) {
          prevState.add(path);
        }
        return prevState;
      });
    }
  };

  const [schema, setSchema] = useState(yamlValidation);

  return (
    <>
      <FormPlaygroundResponseModal
        openState={[responseModalOpen, setResponseModalOpen]}
        formValues={formValues}
        modifiedFormValues={modifiedFormValues}
      />
      <WYSIWYG
        renderedContent={
          <FormProvider {...formMethods}>
            <RenderForm
              formSchema={schema}
              prefix={'driver_data'}
              onFieldCallback={onFieldCallback}
              onSubmit={onFormSubmit}
            />
          </FormProvider>
        }
        defaultEditorContent={yamlValidation}
        onEditorContentChange={setSchema}
        examples={[
          {
            id: 'yaml',
            label: 'YAML Validation',
            value: yamlValidation,
          },
          {
            id: 'keyvaluepairs',
            label: 'Key/Value Pairs',
            value: patternPropertiesJSONSchema,
          },
          {
            id: 'postgres',
            label: 'Postgres rdbms',
            value: postgres,
          },
          {
            id: 'default',
            label: 'Default',
            value: uiHintsJSONSchema,
          },
          {
            id: 'MariaDB',
            label: 'MariaDB over ssh',
            value: mariadboverssh,
          },
        ]}
      />
    </>
  );
};

export default FormPlayground;
