import {
  ArgumentsInput,
  Button,
  DropdownItem,
  Input,
  KeyValueEntriesModal,
  WalDropdownMenu,
} from '@humanitec/ui-components';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import {
  CommandProbe,
  GrpcProbe,
  HttpProbe,
  PROBE_METHODS,
  ProbeMethods,
  ProbeTypes,
  TcpProbe,
} from '@src/models/container';
import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const InputWrapper = styled.div`
  overflow: hidden;
`;

const ProbesGrid = styled.div`
  display: grid;
  grid-template-columns: ${rem(320)} 1fr;
  align-items: center;
  column-gap: ${units.margin.xxs};
  row-gap: ${units.margin.sm};
`;

const HttpWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${rem(150)} ${rem(80)} max-content;
  column-gap: ${rem(1)};
`;

const ManageHeadersButton = styled(Button)`
  margin-left: ${units.margin.sm};
`;

const LabelTextWrapper = styled.span`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.sm};
`;

interface ProbeProps {
  type?: ProbeTypes;
  deltaPath: string;
}

const Probe = ({ deltaPath }: ProbeProps) => {
  // Component state
  const [probeMethodChanged, setProbeMethodChanged] = useState(false);
  const [headersModalOpen, setHeadersModalOpen] = useState(false);

  // i18n
  const { t: tWorkloadProfileNS } = useTranslation('workloadProfile');
  const { t: tCommon } = useTranslation();
  const sectionsTranslations = tCommon('VIEW_MODULE').SECTIONS;
  const probesTranslations = tWorkloadProfileNS('FEATURES').PROBE;

  // Form
  const methods = useWalhallForm();
  const { getValues, setValue } = methods;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { data: probe, updateWorkload } = useDeltaUtils<ProbeMethods>(deltaPath);

  const [probeMethod, setProbeMethod] = useState<PROBE_METHODS>(probe?.type ?? 'none');

  // Router hooks
  const { containerId } = useParams<keyof MatchParams>() as MatchParams;

  useEffect(() => {
    setProbeMethod(probe?.type ?? 'none');
  }, [probe]);

  useEffect(() => {
    if (probeMethod !== probe?.type) {
      setProbeMethodChanged(true);
    } else {
      setProbeMethodChanged(false);
    }
  }, [probeMethod, probe]);

  useEffect(() => {
    if (!probeMethodChanged && probe?.type === 'http') {
      setValue('value', probe?.path);
      setValue('httpPort', probe?.port);
    } else if (!probeMethodChanged && probe?.type === 'tcp') {
      setValue('value', probe?.port);
    }
  }, [probe, containerId, setValue, probeMethodChanged]);

  const updateProbe = useCallback(
    (newProbe: ProbeMethods) => {
      // Path and port are required for http probe
      if (newProbe.type === 'http' && (!newProbe.port || !newProbe.path)) return;

      updateWorkload([
        {
          op: newProbe.type === 'none' ? 'remove' : 'add',
          value: newProbe,
        },
      ]);
    },
    [updateWorkload]
  );

  /**
   * Submit values from form elements (Not command(ArgumantsInput))
   */
  const submitValues = useCallback(() => {
    const formValues = getValues();
    if (probeMethod !== 'none' && !formValues.value) return;

    switch (probeMethod) {
      case 'http':
        // Maintain headers if the type is http
        const headers = probe?.type === 'http' ? probe.headers : undefined;
        updateProbe({
          type: probeMethod,
          path: formValues.value,
          port: parseInt(formValues.httpPort, 10),
          headers,
        });
        break;
      case 'tcp':
        updateProbe({ type: probeMethod, port: formValues.value });
        break;
      case 'grpc':
        updateProbe({ type: probeMethod, port: formValues.value });
        break;
      case 'none':
        updateProbe({ type: 'none' });
        break;
      // no default
    }
  }, [getValues, updateProbe, probeMethod, probe]);

  const onArgChange = useCallback(
    (args: string[]) => {
      if (updateProbe) {
        updateProbe({ type: 'command', command: args });
      }
    },
    [updateProbe]
  );

  const changeSelect = (id: string, item: DropdownItem<PROBE_METHODS>) => {
    setProbeMethod(item.value);
    if (item.value === 'none') {
      updateProbe({ type: 'none' });
    }
    setValue('value', '');
  };

  const changeInput = () => {
    submitValues();
  };

  const onHeadersChange = useCallback(
    ({ entries }: { entries: KeyValue[] }) => {
      const formValues = getValues();
      updateProbe({
        type: 'http',
        path: formValues.value,
        port: parseInt(formValues.httpPort, 10),
        headers: entries.reduce(
          (acc, val) => ({
            ...acc,
            [val.key]: val.value,
          }),
          {}
        ),
      });
    },
    [getValues, updateProbe]
  );

  const probeMethodLabel = (): string => {
    switch (probeMethod) {
      case 'http':
        return probesTranslations.URL;
      case 'command':
        return probesTranslations.ARGUMENTS_ARRAY;
      case 'tcp':
      case 'grpc':
        return probesTranslations.PORT;
      default:
        return probesTranslations.NONE;
    }
  };

  const inputSwitcher = (method: PROBE_METHODS, withDefaultValues = false) => {
    switch (method) {
      case 'command':
        return (
          <ArgumentsInput
            noBorderRadius={'left'}
            hideLabel
            name={'value'}
            label={' '}
            readonly={!draftModeActive}
            placeholder={probesTranslations.ARGUMENTS_ARRAY}
            defaultArguments={withDefaultValues ? (probe as CommandProbe)?.command : []}
            onChange={onArgChange}
          />
        );
      case 'http':
        return (
          <HttpWrapper>
            {!draftModeActive ? (
              <span className={'txt-sm'}>{(probe as HttpProbe)?.path}</span>
            ) : (
              <Input
                noBorderRadius={'all'}
                hideLabel
                type={'text'}
                name={'value'}
                id={`${deltaPath}Value`}
                label={probeMethodLabel()}
                onBlur={changeInput}
              />
            )}
            {!draftModeActive ? (
              <span className={'txt-sm'}>{(probe as HttpProbe)?.port}</span>
            ) : (
              <Input
                noBorderRadius={'left'}
                name={'httpPort'}
                placeholder={'Port'}
                hideLabel
                label={probesTranslations.PORT}
                type={'number'}
                min={1}
                max={65535}
                onBlur={changeInput}
              />
            )}
            {probe?.type === 'http' && (
              <ManageHeadersButton
                variant={'secondary'}
                disabled={!draftModeActive && !probe.headers}
                onClick={() => {
                  setHeadersModalOpen(true);
                }}>
                {draftModeActive
                  ? probe.headers
                    ? sectionsTranslations.EDIT_HEADERS
                    : sectionsTranslations.ADD_HEADERS
                  : probe.headers
                    ? sectionsTranslations.VIEW_HEADERS
                    : sectionsTranslations.NO_HEADERS}
              </ManageHeadersButton>
            )}
          </HttpWrapper>
        );
      case 'tcp':
        return !draftModeActive ? (
          <span className={'txt-sm'}>{(probe as TcpProbe)?.port}</span>
        ) : (
          <Input
            noBorderRadius={'left'}
            hideLabel
            type={'number'}
            name={'value'}
            id={`${deltaPath}Value`}
            min={1}
            max={65535}
            label={probeMethodLabel()}
            onBlur={changeInput}
          />
        );
      case 'grpc':
        return !draftModeActive ? (
          <span className={'txt-sm'}>{(probe as GrpcProbe)?.port}</span>
        ) : (
          <Input
            noBorderRadius={'left'}
            hideLabel
            type={'number'}
            name={'value'}
            id={`${deltaPath}Value`}
            min={1}
            max={65535}
            label={probeMethodLabel()}
            onBlur={changeInput}
          />
        );
      case 'none':
        break;
      // no default
    }
  };

  const probeMethods: DropdownItem<PROBE_METHODS>[] = [
    {
      label: probesTranslations.NONE,
      value: 'none',
      id: 'none',
    },
    {
      label: probesTranslations.HTTP,
      value: 'http',
      id: 'http',
    },
    {
      label: probesTranslations.COMMAND,
      value: 'command',
      id: 'command',
    },
    {
      label: probesTranslations.TCP_PORT,
      value: 'tcp',
      id: 'tcp',
    },
    {
      label: probesTranslations.GRPC,
      value: 'grpc',
      id: 'grpc',
    },
  ];

  return (
    <ProbesGrid>
      <FormProvider {...methods}>
        {!draftModeActive ? (
          <LabelTextWrapper className={'txt-sm'}>
            {probe?.type === undefined
              ? probesTranslations.NONE
              : probeMethods.find((method) => method.value === probe?.type)?.label}
          </LabelTextWrapper>
        ) : (
          <WalDropdownMenu
            buttonVariant={'input'}
            fullWidth
            items={probeMethods}
            defaultValue={probeMethod || 'none'}
            onItemClick={changeSelect}
          />
        )}
        <InputWrapper>
          {probeMethodChanged
            ? inputSwitcher(probeMethod, false)
            : probe?.type && inputSwitcher(probe?.type, true)}
        </InputWrapper>
        {headersModalOpen && probe?.type === 'http' && (
          <KeyValueEntriesModal
            title={'Manage headers'}
            state={[headersModalOpen, setHeadersModalOpen]}
            addButtonText={'Add header'}
            entryNameSingular={'Header'}
            entries={Object.entries(probe.headers || {}).map(([key, value]) => ({
              key,
              value,
            }))}
            editingEnabled={draftModeActive}
            handleFormSubmit={onHeadersChange}
          />
        )}
      </FormProvider>
    </ProbesGrid>
  );
};

export default Probe;
