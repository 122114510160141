import { rem } from 'polished';
import { Link, To } from 'react-router-dom';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

const InfoWrapper = styled.div`
  display: inline;
`;

const InfoText = styled.span`
  font-size: ${units.fontSize.base};
  max-width: ${rem(450)};
`;

const InfoLink = styled(Link)`
  font-size: ${units.fontSize.base};
  color: ${({ theme }) => theme.color.mainBrighter};
  cursor: pointer;
`;

interface InfoTextBoxProps {
  text: string;
  link?: {
    text: string;
    to: To;
  };
}

const InfoTextBox = ({ text, link }: InfoTextBoxProps) => {
  return (
    <InfoWrapper>
      <InfoText>{text}</InfoText>
      {link && <InfoLink to={link.to}>{link.text}</InfoLink>}
    </InfoWrapper>
  );
};

export default InfoTextBox;
