import { Input } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface GCPFieldsProps {
  isNew?: boolean;
}

const GCPWorkloadIdentityFields = ({ isNew }: GCPFieldsProps) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.GCP_IDENTITY;

  return (
    <>
      <Input
        name={'gcp_audience'}
        className={'mb-md'}
        labelAbove={!isNew}
        label={formTranslations.GCP_AUDIENCE}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
      <Input
        name={'gcp_service_account'}
        className={'mb-md'}
        labelAbove={!isNew}
        label={formTranslations.GCP_SERVICE_ACCOUNT}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
    </>
  );
};

export default GCPWorkloadIdentityFields;
