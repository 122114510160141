import styled from 'styled-components';

import { units } from '@src/styles/variables';

export const CodeLine = styled.pre`
  margin-bottom: ${units.margin.sm};
  word-break: break-all;
  margin: ${units.margin.sm} 0;
`;

export const DescriptionLink = styled.a`
  font-size: ${units.fontSize.base};
`;
