import {
  ActionButtons,
  AddButtonForm,
  Input,
  Textarea,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { KeyValue } from '@src/models/general';
import { useWalhallForm } from '@src/utilities/form';

import AnnotationForm from './AnnotationForm';

const Annotations = ({ deltaPath }: { deltaPath: string }) => {
  const { data, updateWorkload } = useDeltaUtils<Record<string, string>>(deltaPath);
  const [showAddForm, setShowAddForm] = useState<boolean | undefined>(false);
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [overrideColumnRow, setOverrideColumRow] = useState<number | undefined>();

  // i18n
  const { t } = useTranslation('workloadProfile');
  const annotationsTranslations = t('FEATURES').ANNOTATIONS;

  const labels = Object.entries(data || {}).map(([key, value]) => ({
    key,
    value,
  }));
  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const formMethods = useWalhallForm<KeyValue>({ mode: 'all' });

  const columns: WalTableColumn<KeyValue>[] = [
    {
      label: annotationsTranslations.KEY,
      prop: 'key',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 25,
        text: (row) => row.data.key.replace(/([_.-])/g, '$1\u200b'),
      },
    },
    {
      label: annotationsTranslations.VALUE,
      prop: 'value',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 10,
        text: (row) => row.data.value,
      },
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row, index) => {
        return (
          draftModeActive && (
            <ActionButtons
              buttons={['edit', 'delete']}
              onEdit={() => setOverrideColumRow(index)}
              onDelete={() => updateWorkload([{ op: 'remove', key: row.data.key }])}
            />
          )
        );
      },
    },
  ];

  return (
    <>
      {draftModeActive && (
        <AddButtonForm
          showFormContentState={[showAddForm, setShowAddForm]}
          buttonText={annotationsTranslations.ADD}
          formContent={
            <AnnotationForm
              onSubmit={(formData) => {
                updateWorkload([{ op: 'add', key: formData.key, value: formData.value }]);
                setShowAddForm(false);
                formMethods.reset();
              }}
              onCancel={() => setShowAddForm(false)}
              existingKeys={labels.map((label) => label.key.toLowerCase())}
            />
          }
        />
      )}
      {labels.length > 0 ? (
        <WalTable
          caption={annotationsTranslations.ANNOTATIONS}
          columns={columns}
          disableScrolling
          tableStyle={'expandable'}
          expandedRowState={[expandedRow, setExpandedRow]}
          rows={
            labels
              ? labels?.map((labelValue, rowIndex) => ({
                  data: labelValue,
                  expandableContent: (
                    <FormProvider {...formMethods}>
                      <Input
                        className={'mt-md'}
                        label={annotationsTranslations.KEY}
                        labelAbove
                        name={'key'}
                        defaultValue={labelValue.key}
                        readonly
                      />
                      <Textarea
                        className={'mt-md'}
                        label={annotationsTranslations.VALUE}
                        labelAbove
                        name={'value'}
                        rows={4}
                        defaultValue={labelValue.value}
                        readonly
                      />
                    </FormProvider>
                  ),
                  overrideColumns: overrideColumnRow === rowIndex,
                  columnOverrideTemplate: (
                    <AnnotationForm
                      defaultValues={labelValue}
                      onSubmit={(formData) => {
                        updateWorkload([
                          { op: 'replace', key: formData.key, value: formData.value },
                        ]);
                        setOverrideColumRow(undefined);
                        formMethods.reset();
                      }}
                      onCancel={() => setOverrideColumRow(undefined)}
                      existingKeys={labels
                        .filter((label) => label.key !== labelValue.key) // skip the key that is being edited
                        .map((label) => label.key.toLowerCase())}
                    />
                  ),
                }))
              : []
          }
        />
      ) : (
        <p className={'mt-md'}>{annotationsTranslations.NO_ANNOTATIONS_DEFINED}</p>
      )}
    </>
  );
};

export default Annotations;
