import { TableCount, WalCard } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { RenderWorkloadProps, useGetWorkloadData } from '@src/hooks/useGetWorkloadData';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

import PauseButtonSection from '../../../../../../../../../../../components/shared/PauseEnvironment/PauseButtonSection/PauseButtonSection';
import TopBar from './components/TopBar/TopBar';
import WorkloadsList from './components/WorkloadList/WorkloadList';

const FullWidthWrapper = styled.div`
  width: 100%;
`;

const NoWorkloadsText = styled(WalCard)`
  font-size: ${units.fontSize.sm};
`;

interface EnvironmentWorkloadsProps {
  // show the workloads of a specific deployment
  customDeployment?: DeploymentObject;
  hidePauseWarning?: boolean;
}

const EnvironmentWorkloads = ({
  customDeployment,
  hidePauseWarning,
}: EnvironmentWorkloadsProps) => {
  // i18n
  const { t } = useTranslation();
  const workloadTranslations = t('VIEW_WORKLOADS');

  // Component state
  const [sortedWorkloadDefinitions, setSortedWorkloadDefinitions] = useState<RenderWorkloadProps[]>(
    []
  );
  const [filterInputValue, setFilterInputValue] = useState<string>();

  // Context
  const { draftModeActive, onLatestDeployment } = useDeploymentOrDeltaContext();

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  // React query
  const { data: customDeploymentSet } = useDeploymentSetQuery({ setId: customDeployment?.set_id });
  const { workloadDefinitions, isFetching: areWorkloadDefinitionsLoading } = useGetWorkloadData(
    customDeployment ? customDeploymentSet : undefined
  );

  // Router hooks
  const { deployId, orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  useEffect(() => {
    setSortedWorkloadDefinitions(
      workloadDefinitions.sort(() => {
        return 1;
      })
    );
  }, [workloadDefinitions, orgId, appId, envId]);

  const filteredWorkloadDefinitions = sortedWorkloadDefinitions.filter((m) =>
    filterInputValue ? m.workloadId.includes(filterInputValue) : true
  );

  return (
    <FullWidthWrapper>
      <TopBar setFilterInputValue={setFilterInputValue} readonly={areWorkloadDefinitionsLoading} />
      {onLatestDeployment && !hidePauseWarning && <PauseButtonSection hidePauseButton />}
      {areWorkloadDefinitionsLoading && (
        <span className={`flex-column gap-md`}>
          <SkeletonGroup
            count={2}
            itemProps={{
              height: 41.5,
              className: 'mt-md',
            }}
          />
        </span>
      )}
      {!workloadDefinitions.length &&
        currentDeltaAction !== 'add-workload' &&
        draftModeActive &&
        !areWorkloadDefinitionsLoading && (
          <NoWorkloadsText cardStyle={'transparent'}>
            <span>
              <Trans
                defaults={workloadTranslations.NO_WORKLOADS_AVAILABLE}
                components={{ italic: <i /> }}
              />
            </span>
          </NoWorkloadsText>
        )}
      {!areWorkloadDefinitionsLoading && (
        <WorkloadsList
          workloadDefinitions={filteredWorkloadDefinitions}
          deploymentId={customDeployment ? customDeployment.id : deployId}
        />
      )}
      <TableCount totalCount={filteredWorkloadDefinitions.length} />
    </FullWidthWrapper>
  );
};

export default EnvironmentWorkloads;
