import { Button, WalCard } from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import DiscardDraftModal from '@src/components/shared/DiscardDraftModal';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { getChangeCountMessage } from '@src/utilities/deployment-delta-utils';

const Card = styled(WalCard)`
  margin-bottom: ${units.margin.md};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BottomRow = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  padding-top: ${units.padding.xs};
  font-size: ${units.fontSize.sm};
`;

const Buttons = styled.div`
  display: flex;
  column-gap: ${units.margin.sm};
  align-items: center;
`;

interface DraftCardProps {
  delta: DeploymentDelta;
}

const DraftCard = ({ delta }: DraftCardProps) => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const translations = t('DRAFTS');

  // Context
  const canDeployEnv = useRBAC('deployEnvironment');

  // React Query
  const { data: environment } = useEnvironmentQuery();

  // Component state
  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);

  const isDeploying = environment?.last_deploy?.status === 'in progress';

  const changeCountMessage = useMemo(() => getChangeCountMessage(delta), [delta]);

  return (
    <>
      <DiscardDraftModal
        openState={[discardChangesModalOpen, setDiscardChangesModalOpen]}
        delta={delta}
      />
      <Card dataTestId={'draft-card'}>
        <div>
          <Link to={delta.id} className={'title-link'}>
            {delta.metadata?.name || translations.UNTITLED_DRAFT}
          </Link>
          {delta.metadata?.last_modified_at && (
            <BottomRow>
              {`${translations.LAST_EDITED} ${formatDate(
                delta.metadata?.last_modified_at,
                DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
              )}`}
            </BottomRow>
          )}
        </div>
        <Buttons>
          <span className={'mr-md'}>{changeCountMessage}</span>
          <Button
            disabled={isDeploying || !canDeployEnv}
            variant={'secondary'}
            onClick={() => setDeploymentOverviewModalOpen(true)}>
            {isDeploying ? translations.CURRENTLY_DEPLOYING : translations.REVIEW_DEPLOY}
          </Button>
          <Button
            variant={'secondary'}
            iconLeft={'delete'}
            ariaLabel={translations.DISCARD_DRAFT}
            onClick={() => setDiscardChangesModalOpen(true)}
          />
        </Buttons>
      </Card>
      {deploymentOverviewModalOpen && environment && (
        <DeploymentOverviewModal
          environment={environment}
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          deploymentType={{ type: 'deploy', data: delta }}
        />
      )}
    </>
  );
};

export default DraftCard;
