import {
  DropdownItem,
  EmptyStateCard,
  Icon,
  Text,
  WalLabel,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ListItem,
  ListItemText,
} from '@src/components/shared/MatchingCriteria/AddNewCriteria/components/NewCriteriaRowEntry';
import MatchingCriteria from '@src/components/shared/MatchingCriteria/MatchingCriteria';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useMatchingCriteriaCreateMutation from '@src/hooks/react-query/resources/mutations/useMatchingCriteriaCreateMutation';
import useMatchingCriteriaDeleteMutation from '@src/hooks/react-query/resources/mutations/useMatchingCriteriaDeleteMutation';
import useResourceClassesQuery from '@src/hooks/react-query/resources/queries/useResourceClassesQuery';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { EnvironmentType } from '@src/models/environment-type';
import { ResourceClass, ResourceConflictChange, ResourceCriteria } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL } from '@src/utilities/navigation';

const StyledLink = styled.a`
  font-size: ${units.fontSize.base};
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const AppWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ResourceDefinitionMatchingCriteria = () => {
  // i18n
  const { t } = useTranslation();
  const tableTranslations = t('ACCOUNT_SETTINGS').RESOURCES;
  const resourceMatchingTranslations = t('RESOURCE_MATCHING');
  const conflictModalTranslations =
    t('ACCOUNT_SETTINGS').RESOURCES.CONFIRM_DELETE_RES_DEF_MATCHING_CRITERIA;

  // State
  const [deleteConflictsModalOpen, setDeleteConflictsModalOpen] = useState<boolean>(false);
  const [deleteConflicts, setDeleteConflicts] = useState<ResourceConflictChange[]>([]);
  const [criteriaToDelete, setCriteriaToDelete] = useState<ResourceCriteria | null>(null);

  // Route Params
  const { defId, orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React query
  const { data: resource } = useResourceDefinitionByIdQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();
  const { data: resourceClasses = [] } = useResourceClassesQuery();
  const { data: resourcetypes = [] } = useResourceTypesQuery();
  const { data: applications = [] } = useApplicationsQuery();
  const {
    mutate: addMatchingCriteria,
    isPending: isCriteriaCreating,
    error,
    reset: resetMutation,
    isSuccess: isCriteriaCreated,
  } = useMatchingCriteriaCreateMutation();

  const {
    mutate: deleteMatchingCriteria,
    error: deleteCriteriaError,
    isPending: isDeletingCriteria,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useMatchingCriteriaDeleteMutation((err) => {
    if (err?.response?.status === 409) {
      setDeleteConflictsModalOpen(true);
      setDeleteConflicts(err.response.data as ResourceConflictChange[]);
    }
  });

  useEffect(() => {
    if (isCriteriaCreated) {
      resetMutation();
    }
  }, [isCriteriaCreated, resetMutation]);

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setDeleteConflictsModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const objectName = 'matching criteria';

  const tableCols: WalTableColumn<ResourceConflictChange>[] = [
    {
      label: conflictModalTranslations.ID,
      prop: 'id',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.res_id,
      },
      template: (row) => <span>{row.data.res_id || '-'}</span>,
    },
    {
      label: conflictModalTranslations.REPLACEMENT_DEFINITION,
      justifyContent: 'flex-start',
      prop: 'replacement_definition',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.to_def,
      },
      template: (row) => <span>{row.data.to_def || '-'}</span>,
    },
  ];

  const resourceType = resourcetypes.find((type) => type.type === resource?.type);

  const envTypeItems = envTypes.map((e: EnvironmentType) => ({
    label: e.id,
    value: e.id,
    id: e.id,
    searchString: e.id,
    overflowHidden: true,
    component: (
      <ListItem>
        <ListItemText>{e.id}</ListItemText>
      </ListItem>
    ),
  }));

  const selectedTypeResourceClasses = resourceClasses.filter(
    (c) => c.resource_type === resource?.type
  );

  const envClassItems =
    resourceType?.use === 'direct'
      ? selectedTypeResourceClasses.map((c: ResourceClass) => ({
          label: c.id,
          value: c.id,
          id: c.id,
          searchString: c.id,
          overflowHidden: true,
          component: (
            <ListItem>
              <ListItemText>{c.id}</ListItemText>
            </ListItem>
          ),
        }))
      : [];

  const applicationsItems = applications.map((a) => ({
    label: a.id,
    value: a.id,
    id: a.id,
    searchString: a.id,
    overflowHidden: true,
    component: (
      <ListItem>
        <ListItemText>{a.id}</ListItemText>
      </ListItem>
    ),
  }));

  const environmentItems = uniqBy(
    applications.flatMap((a) => a.envs),
    'id'
  ).map((e) => ({
    label: e.id,
    value: e.id,
    id: e.id,
    searchString: e.id,
    overflowHidden: true,
    component: (
      <ListItem>
        <ListItemText>{e.id}</ListItemText>
      </ListItem>
    ),
  }));

  const itemsMap = {
    env_type: [
      {
        label: resourceMatchingTranslations.ALL_VALUES,
        id: '*',
        value: '*',
        searchString: `${resourceMatchingTranslations.ALL_VALUES} *`,
        component: (
          <ListItem>
            <ListItemText>{resourceMatchingTranslations.ALL_VALUES}</ListItemText>
          </ListItem>
        ),
      },
      ...envTypeItems,
    ],
    class: [
      {
        id: 'default',
        label: 'default',
        value: 'default',
        searchString: 'default',
        hideFromList: Boolean(
          selectedTypeResourceClasses?.find((c) => c.id.toLowerCase() === 'default')
        ),
      },
      ...envClassItems,
    ],
    app_id: [
      {
        label: resourceMatchingTranslations.ALL_VALUES,
        id: '*',
        value: '*',
        searchString: `${resourceMatchingTranslations.ALL_VALUES} *`,
        component: (
          <ListItem>
            <ListItemText>{resourceMatchingTranslations.ALL_VALUES}</ListItemText>
          </ListItem>
        ),
      },
      ...applicationsItems,
    ],
    env_id: [
      {
        label: resourceMatchingTranslations.ALL_VALUES,
        id: '*',
        value: '*',
        searchString: `${resourceMatchingTranslations.ALL_VALUES} *`,
        component: (
          <ListItem>
            <ListItemText>{resourceMatchingTranslations.ALL_VALUES}</ListItemText>
          </ListItem>
        ),
      },
      ...environmentItems,
    ],
  };

  const heading = (
    <Trans defaults={tableTranslations.MATCHING_CRITERIA_HEADING}>
      <StyledLink
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={
          'https://developer.humanitec.com/platform-orchestrator/resources/overview/#resource-provisioning'
        }
      />
      <StyledLink
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={'https://developer.humanitec.com/platform-orchestrator/resources/overview/#context'}
      />
      <StyledLink
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={
          'https://developer.humanitec.com/platform-orchestrator/resources/definitions/#matching-criteria'
        }
      />
    </Trans>
  );

  const defaultDropdownOptions: DropdownItem<keyof ResourceCriteria>[] = [
    // Options available in the dropdown for a user to add to the criteriaList
    { label: 'Application ID', id: 'app_id', value: 'app_id' },
    { label: 'Environment ID', id: 'env_id', value: 'env_id' },
    { label: 'Environment type', id: 'env_type', value: 'env_type' },
    { label: 'Resource class', id: 'class', value: 'class' },
    // Only include 'Resource ID' as part as the dropdown options if the resource type isn't 'k8s-cluster'
    {
      label: 'Resource ID',
      id: 'res_id',
      value: 'res_id',
      hideFromList: resource?.type === 'k8s-cluster',
    },
  ];

  const confirmDeleteModalContent = (
    <>
      <Text className={'mb-md'}>
        <Trans
          defaults={conflictModalTranslations.DELETE_RESOURCE_DEFINITION_OR_MATCHING_CRITERIA_TEXT}
          values={{ objectName }}
        />
      </Text>

      {deleteConflicts?.length > 0 ? (
        <>
          <Text color={'textTranslucent'} className={'mb-md'}>
            {conflictModalTranslations.RESOURCES_AFFECTED}
          </Text>
          <WalTable
            caption={conflictModalTranslations.RESOURCES_AFFECTED}
            columns={tableCols}
            tableStyle={'expandable'}
            tableRowStyle={'base'}
            rows={(deleteConflicts || [])?.map(
              (deleteChange): WalTableRow => ({
                data: deleteChange,
                expandableContent: (
                  <>
                    <Field>
                      <WalLabel>{conflictModalTranslations.RESOURCE_ID}</WalLabel>
                      <span>{deleteChange.res_id || '_'}</span>
                    </Field>
                    <Field>
                      <WalLabel>{conflictModalTranslations.APPLICATION_ID}</WalLabel>
                      <AppWrapper>
                        {deleteChange.app_id || '_'}
                        {deleteChange.app_id && (
                          <Link
                            to={generateAppURL(orgId, deleteChange.app_id)}
                            target={'_blank'}
                            rel={'noopener noreferrer'}>
                            <Icon
                              name={'link'}
                              overrideColor={'main-brighter'}
                              marginLeft={'sm'}
                              pointer
                            />{' '}
                          </Link>
                        )}
                      </AppWrapper>
                    </Field>
                    <Field>
                      <WalLabel>{conflictModalTranslations.ENVIRONMENT_ID}</WalLabel>
                      <span>{deleteChange.env_id || '_'}</span>
                    </Field>
                    <Field>
                      <WalLabel>{conflictModalTranslations.REPLACEMENT_DEFINITION}</WalLabel>
                      <span>{deleteChange.to_def || '_'}</span>
                    </Field>
                  </>
                ),
              })
            )}
            disableScrolling
          />
        </>
      ) : (
        <EmptyStateCard>{conflictModalTranslations.NO_RESOURCES_AFFECTED}</EmptyStateCard>
      )}
    </>
  );

  const submitCriteria = (criteria: Partial<ResourceCriteria>) => {
    const { env_type, app_id, res_id, env_id } = criteria;
    addMatchingCriteria({
      defId,
      criteria: {
        env_type: env_type === '*' ? '' : env_type,
        app_id: app_id === '*' ? '' : app_id,
        res_id: res_id === '*' ? '' : res_id,
        env_id: env_id === '' ? '' : env_id,
        class: criteria.class === '' ? 'default' : criteria.class,
      },
    });
  };

  const handleDeleteButtonClicked = (criteria: ResourceCriteria) => {
    setCriteriaToDelete(criteria);
    if (criteria) {
      deleteMatchingCriteria({ defId, criteria });
    }
  };

  const handleDeleteCriteria = (force?: boolean) => {
    if (criteriaToDelete) {
      deleteMatchingCriteria({ defId, criteria: criteriaToDelete, force });
    }
  };

  return (
    <MatchingCriteria<ResourceCriteria>
      description={!resource?.is_default ? heading : undefined}
      initialCriteria={[{ label: 'Environment type', key: 'env_type', value: 'env_type' }]}
      onSubmitCriteria={submitCriteria}
      isCriteriaCreating={isCriteriaCreating}
      isCriteriaCreated={isCriteriaCreated}
      addCriteriaDescription={resourceMatchingTranslations.RESOURCE_AVAILABILITY_TEXT}
      submitCriteriaError={error}
      comboSelectItemsMap={itemsMap}
      defaultDropdownOptions={defaultDropdownOptions}
      criteriaList={resource?.criteria}
      onDeleteConfirmed={() => handleDeleteCriteria(true)}
      onDeleteButtonClicked={handleDeleteButtonClicked}
      deleteCriteriaError={deleteCriteriaError}
      isDeletingCriteria={isDeletingCriteria}
      confirmDeleteModalState={[deleteConflictsModalOpen, setDeleteConflictsModalOpen]}
      confirmDeleteModalContent={confirmDeleteModalContent}
      resourceDefinition={resource}
      criteriaToDelete={criteriaToDelete}
      section={'resources'}
    />
  );
};

export default ResourceDefinitionMatchingCriteria;
