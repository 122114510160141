import { Input } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

const AWSFields = ({ isNew, namePrefix }: { isNew?: boolean; namePrefix?: string }) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AWS;

  return (
    <>
      <Input
        name={namePrefix ? `${namePrefix}-accessKeyId` : 'accessKeyId'}
        className={'mb-md'}
        labelAbove={!isNew}
        label={formTranslations.ACCESS_KEY_ID_LABEL}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
      <Input
        name={namePrefix ? `${namePrefix}-secretAccessKey` : 'secretAccessKey'}
        labelAbove={!isNew}
        label={formTranslations.SECRET_ACCESS_KEY_LABEL}
        required={isNew}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
    </>
  );
};

export default AWSFields;
