import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import useGetCurrentUserQuery from '../../user/queries/useGetCurrentUserQuery';
import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';

const useDeploymentDeltaListQuery = (
  // Allow passing of custom URL params. Can be removed if WAL-5572 is completed.
  customParams?: {
    orgId?: string;
    appId?: string;
    envId?: string;
  }
): QueryResponse<DeploymentDelta[]> => {
  // Router hooks
  const {
    orgId: routerOrgId,
    appId: routerAppId,
    envId: routerEnvId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;

  const { data: currentUser } = useGetCurrentUserQuery();

  const { data, ...queryResult } = useQuery({
    queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
    queryFn: () =>
      makeRequest<DeploymentDelta[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/deltas?env=${envId}&archived=false`
      ),
    // Don't make request of any of the params don't exist
    enabled: Boolean(orgId && appId && envId && currentUser),
  });

  return {
    ...queryResult,
    data:
      data?.data?.filter(
        (deploymentDelta) =>
          deploymentDelta?.metadata?.created_by === currentUser?.id &&
          !deploymentDelta?.metadata?.archived
      ) || [],
  };
};

export default useDeploymentDeltaListQuery;
