import { Button, Input, WalDropdownMenu, WalModal } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateGroupMutation } from '@src/hooks/react-query/groups/mutations/useCreateGroupMutation';
import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { useWalhallForm } from '@src/utilities/form';

import { OrgRoleDescriptions } from '../../../components/RoleDescriptions/OrgRoleDescriptions';

export const AddGroupModal = () => {
  // Component state
  const [open, setOpen] = useState(false);
  const regularUsersRoleOptions = useRoleDropdownOptions();

  // i18n
  const { t } = useTranslation('orgMembers');
  const groupsTranslations = t('GROUPS');
  const { t: tCommon } = useTranslation();
  const uiTranslations = tCommon('UI');

  // Form
  const formMethods = useWalhallForm({
    defaultValues: {
      group_id: '',
      idp_id: '',
      role: regularUsersRoleOptions[0]?.id,
    },
  });

  // React Query
  const {
    mutate: createGroup,
    isSuccess: groupCreated,
    reset: resetCreateGroupMutation,
  } = useCreateGroupMutation();

  useEffect(() => {
    if (groupCreated) {
      resetCreateGroupMutation();
      setOpen(false);
    }
  }, [groupCreated, resetCreateGroupMutation]);

  return (
    <>
      <Button iconLeft={'plus'} onClick={() => setOpen(true)} className={'mb-lg'}>
        {groupsTranslations.ADD_GROUP}
      </Button>
      <FormProvider {...formMethods}>
        <WalModal
          openState={[open, setOpen]}
          handleFormSubmit={(formValues) => {
            createGroup({
              group_id: formValues.group_id,
              idp_id: formValues.idp_id,
              role: formValues.role,
            });
          }}
          title={groupsTranslations.ADD_GROUP}
          content={
            <>
              <Input label={groupsTranslations.GROUP_ID} name={'group_id'} required />
              <Input label={groupsTranslations.IDP_ID} name={'idp_id'} required />
              <WalDropdownMenu
                items={regularUsersRoleOptions}
                name={'role'}
                label={groupsTranslations.ROLE}
                allowUpdatesToDefaultValue
              />
              <OrgRoleDescriptions />
            </>
          }
          actions={{ main: { text: uiTranslations.CREATE, props: { type: 'submit' } }, cancel: {} }}
        />
      </FormProvider>
    </>
  );
};
