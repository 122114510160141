import { Button, MenuItem } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import ServicePortCard from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/ServicePorts/components/ServicePortCard';
import ServicePortModal from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/ServicePorts/components/ServicePortModal/ServicePortModal';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { ServiceFeature, ServicePort } from '@src/models/deployment-set';

const ServicePortsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddServicePortButton = styled(Button)`
  align-self: flex-start;
`;

export interface ServicePortEntry extends ServicePort {
  name: string;
}

interface ServicePortsProps {
  deltaPath: string;
}

const ServicePorts = ({ deltaPath }: ServicePortsProps) => {
  // state
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [servicePortToEdit, setServicePortToEdit] = useState<ServicePortEntry>();
  const [servicePortToDelete, setServicePortToDelete] = useState<ServicePortEntry>();
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // contexts
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { data: ports, updateWorkload: updateServicePorts } = useDeltaUtils<ServiceFeature>(
    `${deltaPath}/ports`,
    () => {
      setOpenDeleteConfirmationModal(false);
    }
  );

  const servicePorts = Object.entries(ports || {}).map(
    ([portKey, { container_port, service_port, protocol }]: [string, ServicePort]) => ({
      name: portKey,
      container_port,
      service_port,
      protocol,
    })
  );

  const handleAddButtonClick = () => {
    setOpenModal(true);
    setServicePortToEdit(undefined);
  };

  const handleCardMenuItemClick = (item: MenuItem<any>, servicePort: ServicePortEntry) => {
    if (item.value === 'edit') {
      setOpenModal(true);
      setServicePortToEdit(servicePort);
    } else if (item.value === 'delete') {
      setOpenDeleteConfirmationModal(true);
      setServicePortToDelete(servicePort);
    }
  };

  const deleteServicePort = () => {
    updateServicePorts(
      [
        {
          key: servicePortToDelete?.name,
          op: 'remove',
        },
      ],
      { deleteParentPaths: true }
    );
  };

  return (
    <>
      <ServicePortsWrapper>
        {draftModeActive ? (
          <AddServicePortButton
            variant={'secondary'}
            iconLeft={'plus'}
            onClick={handleAddButtonClick}>
            {sectionsTranslations.ADD_SERVICE_PORT}
          </AddServicePortButton>
        ) : (
          servicePorts.length === 0 && (
            <span className={'txt-md'}>{sectionsTranslations.NO_SERVICE_PORTS}</span>
          )
        )}

        {servicePorts.map((servicePort, index) => (
          <ServicePortCard
            first={index === 0}
            last={servicePorts.length === index + 1}
            key={servicePort.name}
            servicePort={servicePort}
            menuItemClicked={handleCardMenuItemClick}
          />
        ))}
      </ServicePortsWrapper>
      {openModal && (
        <ServicePortModal
          state={[openModal, setOpenModal]}
          servicePort={servicePortToEdit}
          servicePorts={servicePorts}
          deltaPath={deltaPath}
        />
      )}
      {openDeleteConfirmationModal && (
        <ConfirmDeleteModal
          state={[openDeleteConfirmationModal, setOpenDeleteConfirmationModal]}
          deleteConfirmedCallback={deleteServicePort}
          confirmMessage={sectionsTranslations.SERVICE_PORT_DELETE_CONFIRMATION}
          title={`${sectionsTranslations.DELETE_SERVICE_PORT_TITLE} ${servicePortToDelete?.name}`}
        />
      )}
    </>
  );
};

export default ServicePorts;
