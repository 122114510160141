import { FilterTable, Icon, Spinner, Text, WarningSection } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Dot } from '@src/components/shared/Dot';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import { useDebouncedValue } from '@src/hooks/useDebouncedValue';
import { ContainerErrorState, ReplicaPodRuntime } from '@src/models/environment';
import { units } from '@src/styles/variables';
import { getContainerErrorsInReplicaSet } from '@src/utilities/runtime-utils';

const ReplicaStatusesWrapper = styled.div<{ newVersion: boolean }>`
  display: flex;
  align-items: center;

  ${({ newVersion }) =>
    newVersion &&
    css`
      & > div:not(:last-child) {
        margin-right: ${units.margin.sm};
      }
      margin-top: ${units.margin.md};
    `}
`;

const ReplicaStatus = styled.div<{
  first?: boolean;
  last?: boolean;
  active?: boolean;
  newVersion?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
  padding: ${units.padding.sm} ${units.padding.md};

  ${({ newVersion, first, last, active }) =>
    newVersion
      ? css`
          border-radius: ${rem(4)};
          background: ${({ theme }) => theme.color.baseBrightest};
        `
      : css`
          border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
          cursor: pointer;
          height: ${rem(38)};
          &:hover {
            border-color: ${({ theme }) => theme.color.main};
            background-color: ${({ theme }) => theme.color.mainTransparent};
          }
          ${first &&
          css`
            border-top-left-radius: ${rem(5)};
            border-bottom-left-radius: ${rem(5)};
          `}
          ${last &&
          css`
            border-top-right-radius: ${rem(5)};
            border-bottom-right-radius: ${rem(5)};
          `}
          ${active &&
          css`
            border-color: ${({ theme }) => theme.color.main};
            background-color: ${({ theme }) => theme.color.mainTransparent};
          `}
        `};
`;

const DotWrapper = styled.div<{ newVersion?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${rem(6)};

  ${({ newVersion }) =>
    newVersion ? `margin-right: ${units.margin.xs}` : `margin-top: ${units.margin.xs}`};
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${units.fontSize.sm};
  font-family: 'Source Code Pro', sans-serif;
  .hidden {
    visibility: hidden;
  }
`;

const PodBoxes = ({
  phase,
  count,
  oldRevision,
}: {
  phase: ReplicaPodRuntime['phase'];
  count: number;
  oldRevision?: boolean;
}) => {
  // i18n
  const { t } = useTranslation();
  const viewModuleTranslations = t('VIEW_MODULE');
  return (
    <ReplicaStatus aria-label={phase} tabIndex={0} key={phase} newVersion>
      <DotWrapper newVersion>
        {phase === 'Pending' && <Spinner diameter={10} />}
        {phase === 'Failed' && <Dot color={'red'} />}
        {phase === 'Unknown' && <Dot color={'yellow'} />}
        {phase === 'Running' &&
          (count > 0 ? (
            <Dot color={'green'} />
          ) : (
            <Icon overrideColor={'yellow'} name={'warning'} size={12} />
          ))}
      </DotWrapper>
      <Text className={'ml-sm'}>
        {count} {phase.toLowerCase()}
      </Text>
      {oldRevision && <Text className={'ml-sm'}>{viewModuleTranslations.PREVIOUS_REVISION}</Text>}
    </ReplicaStatus>
  );
};

const ReplicaStatuses = () => {
  // i18n
  const { t } = useTranslation();
  const viewModuleTranslations = t('VIEW_MODULE');

  // Component state
  const [selectedReplicaStatus] = useState<number>(0);
  const [errors, setErrors] = useState<ContainerErrorState[]>([]);
  const [, setRemovingReplicas] = useState(0);
  const [replicaRuntimes, setReplicaRuntimes] = useState<ReplicaPodRuntime[]>([]);
  const [replicasCount, setReplicaCount] = useState(0);

  const oldRevisionPods = useRef<ReplicaPodRuntime[]>([]);

  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();

  const { workloadController } = environmentRuntimeData || {};

  const debouncedWorkloadController = useDebouncedValue(workloadController, 300);

  useEffect(() => {
    setReplicaRuntimes(debouncedWorkloadController?.pods ?? []);
    setReplicaCount(debouncedWorkloadController?.replicas ?? 0);
  }, [debouncedWorkloadController]);

  useEffect(() => {
    const errorsArr: ContainerErrorState[] = [];
    if (replicaRuntimes[selectedReplicaStatus]) {
      getContainerErrorsInReplicaSet(replicaRuntimes[selectedReplicaStatus], errorsArr);
      setErrors(errorsArr);
    }
  }, [selectedReplicaStatus, replicaRuntimes]);

  useEffect(() => {
    setRemovingReplicas(replicaRuntimes.length - replicasCount);
  }, [replicasCount, replicaRuntimes.length]);

  const containerStatusError = !!debouncedWorkloadController?.pods.find(
    (pod) => pod.containerStatuses.length === 0
  );

  const runningPods = replicaRuntimes.reduce((filteredPods: ReplicaPodRuntime[], pod, index) => {
    // reset revision ref
    if (index === 0) oldRevisionPods.current = [];

    const isRunning = pod.phase === 'Running' && pod.status === 'Success';

    if (isRunning) {
      if (debouncedWorkloadController && pod.revision < debouncedWorkloadController?.revision) {
        oldRevisionPods.current.push(pod);
      } else {
        filteredPods.push(pod);
      }
    }

    return filteredPods;
  }, []);

  const unknownPods = replicaRuntimes.filter((pod) => pod.phase === 'Unknown');
  const failedPods = replicaRuntimes.filter(
    (pod) =>
      pod.phase === 'Failed' ||
      (pod.phase === 'Running' && ['Failure', 'Warning'].includes(pod.status))
  );

  const pendingPods = replicaRuntimes.filter((pod) => pod.phase === 'Pending');

  return (
    <>
      {Boolean(debouncedWorkloadController?.pods.length) && (
        <ReplicaStatusesWrapper newVersion>
          <>
            {Boolean(runningPods.length) && (
              <PodBoxes count={runningPods.length} phase={'Running'} />
            )}
            {Boolean(oldRevisionPods.current.length) && (
              <PodBoxes count={oldRevisionPods.current.length} phase={'Running'} oldRevision />
            )}
            {Boolean(unknownPods.length) && (
              <PodBoxes count={unknownPods.length} phase={'Unknown'} />
            )}
            {Boolean(failedPods.length) && <PodBoxes count={failedPods.length} phase={'Failed'} />}
            {Boolean(pendingPods.length) && (
              <PodBoxes count={pendingPods.length} phase={'Pending'} />
            )}
          </>
        </ReplicaStatusesWrapper>
      )}
      <BottomWrapper>
        {Boolean(errors.length) && (
          <WarningSection mode={'alert'}>
            <Trans
              defaults={viewModuleTranslations.ERROR_IN_X_PODS}
              values={{ count: errors.length }}
            />
            <FilterTable
              tableRowStyle={'base'}
              className={'mt-md'}
              tableStyle={'expandable'}
              rows={errors?.map((error) => ({
                data: error,
                expandableContent: (
                  <div className={'flex-column'}>
                    <Text size={'sm'} color={'textTranslucent'}>
                      {viewModuleTranslations.ERROR_CONTENT.CONTAINER}
                    </Text>
                    {error.container}
                    <Text size={'sm'} color={'textTranslucent'} className={'mt-lg'}>
                      {viewModuleTranslations.ERROR_CONTENT.ERROR}
                    </Text>
                    {error.message}
                    <Text size={'sm'} color={'textTranslucent'} className={'mt-lg'}>
                      {viewModuleTranslations.ERROR_CONTENT.DEBUG_INFORMATION}
                    </Text>
                    {viewModuleTranslations.ERROR_CONTENT.REASON}: {error.reason}
                  </div>
                ),
              }))}
              columns={[
                {
                  label: viewModuleTranslations.POD_NAME,
                  prop: 'pod_name',
                  template: (row) => <p>{row.data.pod || '-'}</p>,
                },
                {
                  label: viewModuleTranslations.CONTAINER_AFFECTED,
                  prop: 'container',
                  justifyContent: 'flex-start',
                  template: (row) => <p>{row.data.container || '-'}</p>,
                },
              ]}
              caption={viewModuleTranslations.ERRORS}
              alignGroupedTableCellByWidthOf={'200px'}
            />
          </WarningSection>
        )}
        {containerStatusError ? (
          <div>{viewModuleTranslations.NO_CONTAINER_STATUS_AVALAIBLE}</div>
        ) : (
          <div aria-hidden={'true'} className={'hidden'}>
            -
          </div>
        )}
        {(debouncedWorkloadController?.status === 'Succeeded' ||
          debouncedWorkloadController?.status === 'Running') &&
          (!errors || errors?.length === 0) && (
            <div>{viewModuleTranslations.ALL_CONTAINERS_RUN_SUCCESSFULLY}</div>
          )}

        {Boolean(oldRevisionPods.current.length) && (
          <WarningSection>
            <div className={'flex align-center'}>{viewModuleTranslations.OLD_REVISION_TEXT}</div>
          </WarningSection>
        )}

        {debouncedWorkloadController?.status !== 'Succeeded' &&
          debouncedWorkloadController?.message && (
            <WarningSection
              mode={debouncedWorkloadController?.status === 'Failed' ? 'alert' : 'warning'}>
              {debouncedWorkloadController.message}
            </WarningSection>
          )}
      </BottomWrapper>
    </>
  );
};

export default ReplicaStatuses;
