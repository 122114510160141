import { WarningSection } from '@humanitec/ui-components';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

const ResumeEnvTextTitle = styled.div`
  margin-bottom: ${units.margin.sm};
`;

const ResumeEnvTextBody = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.sm};
`;

interface ResumeEnvTextProps {
  children?: ReactNode;
}

const ResumeEnvText = ({ children }: ResumeEnvTextProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  return (
    <WarningSection>
      <Trans defaults={uiTranslations.RESUME_ENV_CONFIRMATION_TEXT_1}>
        <ResumeEnvTextTitle />
        <ResumeEnvTextBody />
      </Trans>
      {children}
    </WarningSection>
  );
};

export default ResumeEnvText;
