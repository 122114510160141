import {
  Button,
  Checkbox,
  EmptyStateCard,
  Input,
  TableCount,
  WalDropdownMenu,
} from '@humanitec/ui-components';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import useGetSpecificOrgQuery from '@src/hooks/react-query/organisations/queries/useGetSpecificOrgQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useFilterApplications from '@src/hooks/useFilterApplications';
import { useRBAC } from '@src/hooks/useRBAC';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import ApplicationCard from '../components/ApplicationCard/ApplicationCard';
import CreateApplicationModal from '../components/CreateApplicationModal';
import { GetStarted } from '../components/GetStarted';

const Wrapper = styled(PageContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FilterAppsInput = styled(Input)`
  width: ${rem(300)};
  align-self: flex-end;
`;

const AppsWrapper = styled.div`
  display: grid;
  overflow: auto;
  margin-bottom: ${units.margin.md};
`;

const AppsGrid = styled.div`
  display: grid;
  grid-row-gap: ${units.margin.lg};
  margin-bottom: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: ${units.margin.lg};
  align-items: center;
  justify-content: space-between;
`;

const SortingOptions = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: ${units.margin.md};
  grid-gap: ${units.margin.md};
`;

const AppsList = () => {
  // i18n
  const { t } = useTranslation('viewApplicationList');

  // Zustand
  const { showEnvsInAppList, sortAppsBy, setSortAppsBy, setShowEnvsInAppList } =
    useUserPreferencesStore();

  // Form
  const formMethods = useWalhallForm();
  const sortingFormMethods = useWalhallForm<{
    showEnvironments: boolean;
    sorting: 'create-date' | 'name';
  }>({
    defaultValues: {
      showEnvironments: showEnvsInAppList,
      sorting: sortAppsBy,
    },
  });

  const { watch } = sortingFormMethods;

  const showEnvironments = watch('showEnvironments');
  const sorting = watch('sorting');

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // Component state
  const [filterValue, setFilterValue] = useState<string>();
  const [orgIdFromState, setOrgIdFromState] = useState<string>(orgId);

  // RBAC
  const canCreateApplication = useRBAC('createApplication');
  const canViewResources = useRBAC('viewResources');

  // React Query
  const { data: org } = useGetSpecificOrgQuery({ orgId });
  const { filteredApplications, applicationsLoaded, applicationsLoading, appCount } =
    useFilterApplications(filterValue, sorting);
  const { data: resourceDefinitions, isSuccess: resourceDefinitionsLoaded } =
    useResourceDefinitionsQuery();

  const showGetStarted =
    canViewResources &&
    resourceDefinitionsLoaded &&
    (!resourceDefinitions?.some((def) => def.type === 'k8s-cluster' && !def.is_default) ||
      !resourceDefinitions?.length);

  // sets the user preference in localstorage
  useEffect(() => {
    setShowEnvsInAppList(showEnvironments);
    setSortAppsBy(sorting);
  }, [sorting, showEnvironments, setSortAppsBy, setShowEnvsInAppList]);

  /*
   *  We use the orgIdFromState and applicationsFromState state variables for performance reasons.
   *  It makes sure the useEffect that dispatches the pausing calls only tracks neccessary updates for orgId and applications variables.
   *  Thereby, reducing the amount of times the dispatch calls are made.
   */
  useEffect(() => {
    if (orgId !== orgIdFromState) {
      setOrgIdFromState(orgId);
    }
  }, [orgId, orgIdFromState]);

  const renderFilterApplications = () => (
    <FormProvider {...formMethods}>
      <FilterAppsInput
        readonly={applicationsLoading}
        name={'deployments-filter'}
        placeholder={t('FILTER_APPLICATIONS')}
        hideLabel
        onChange={setFilterValue}
      />
    </FormProvider>
  );

  return (
    <Wrapper>
      {showGetStarted && <GetStarted />}
      <PageHeader
        customHeading={t('APPLICATIONS_TITLE')}
        rightContent={renderFilterApplications()}
      />

      <ButtonsWrapper>
        {canCreateApplication && (
          <>
            <CreateApplicationModal buttonVariant={showGetStarted ? 'secondary' : 'primary'} />
            {org?.scaffolding_url && (
              <Button
                className={'ml-md'}
                iconLeft={'copy'}
                target={'_blank'}
                variant={'secondary'}
                link={{ href: org.scaffolding_url }}>
                {t('CREATE_FROM_TEMPLATE')}
              </Button>
            )}
          </>
        )}
        <FormProvider {...sortingFormMethods}>
          <SortingOptions>
            <span className={'txt-base'}>{t('SORT_BY')}</span>
            <WalDropdownMenu
              name={'sorting'}
              disabled={!Boolean(appCount)}
              items={[
                { id: 'name', value: 'name', label: t('NAME') },
                { id: 'create-date', value: 'create-date', label: t('CREATE_DATE') },
              ]}
            />
            <Checkbox
              readonly={!Boolean(appCount)}
              name={'showEnvironments'}
              label={t('SHOW_ENVIRONMENTS')}
            />
          </SortingOptions>
        </FormProvider>
      </ButtonsWrapper>

      <TableCount totalCount={filteredApplications.length} />
      <AppsWrapper>
        <AppsGrid>
          {applicationsLoaded && appCount === 0 && (
            <EmptyStateCard>{t('NO_APPLICATIONS_ADDED')}</EmptyStateCard>
          )}
          {applicationsLoaded && filterValue && !filteredApplications.length && (
            <EmptyStateCard>{t('NO_APPLICATIONS_FOUND')}</EmptyStateCard>
          )}
          {applicationsLoading && <SkeletonGroup count={3} itemProps={{ height: 132 }} />}
          {!applicationsLoading &&
            filteredApplications.map((app) => (
              <ApplicationCard hideEnvs={!showEnvironments} application={app} key={app.id} />
            ))}
        </AppsGrid>
      </AppsWrapper>
    </Wrapper>
  );
};

export default AppsList;
