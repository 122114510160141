import { rem } from 'polished';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import SectionHeader from '@src/components/shared/SectionHeader';
import { units } from '@src/styles/variables';

import { Button, ButtonVariant } from '../../base/Button/Button';
import { Icon, IconNames } from '../../base/Icon/Icon';

const Wrapper = styled.div<{ marginBottom?: boolean }>`
  width: 100%;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${units.margin.xl};
    `}
`;

const ButtonWrapper = styled.div<{ itemMinWidth?: number }>`
  ${({ itemMinWidth }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(${itemMinWidth ? itemMinWidth + 'px' : '100px'}, max-content)
      );
      column-gap: ${units.margin.md};
      row-gap: ${units.margin.md};
    `;
  }}
`;

export const OptionGridButton = styled(Button)<{ canClick?: boolean; minWidth?: number }>`
  width: 100%;
  svg {
    margin-bottom: ${units.margin.xs};
  }

  .inner-button-text {
    display: flex;
    flex-direction: column;
    white-space: normal;
  }

  ${({ canClick }) =>
    !canClick &&
    css`
      pointer-events: none;
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${rem(minWidth)};
    `}
`;

const LargeTitle = styled.h4`
  margin-bottom: ${units.margin.sm};
`;

export interface OptionGridOption<TValue = any, TId = string> {
  name: string;
  id: TId;
  icon: IconNames;
  value?: TValue;
  testingId?: string;
  customWidth?: number;
  hide?: boolean;
}

export interface OptionGridProps<TValue = any, TId = string> {
  options: OptionGridOption<TValue, TId>[];
  staticComponent?: ReactNode;
  onOptionClick: (id: TId, value?: TValue) => void;
  title?:
    | string
    | {
        style: 'large';
        title: string;
      };
  className?: string;
  /** item min width in pixels */
  itemMinWidth?: number;
  buttonVariant?: ButtonVariant;
  marginBottom?: boolean;
}

/**
 * An array of large buttons with icons & text.
 */
export const OptionGrid = <TValue extends unknown = any, TId extends unknown = string>({
  title,
  staticComponent,
  options,
  onOptionClick,
  className,
  itemMinWidth,
  buttonVariant = 'secondary',
  marginBottom,
}: OptionGridProps<TValue, TId>) => (
  <Wrapper className={className} marginBottom={marginBottom}>
    {typeof title === 'string' ? (
      <SectionHeader backgroundColor={'transparent'}>{title}</SectionHeader>
    ) : (
      title?.style === 'large' && <LargeTitle>{title?.title}</LargeTitle>
    )}
    <ButtonWrapper itemMinWidth={itemMinWidth}>
      {options
        .filter((o) => !o.hide)
        .map((option, index) => (
          <OptionGridButton
            canClick={typeof onOptionClick === 'function'}
            className={option.testingId}
            dataTestid={option.testingId}
            variant={buttonVariant}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            minWidth={option.customWidth}
            onClick={() => onOptionClick(option.id, option.value)}>
            <Icon size={32} name={option.icon} />
            <span>{option.name}</span>
          </OptionGridButton>
        ))}
      {staticComponent}
    </ButtonWrapper>
  </Wrapper>
);
