import { Button, ButtonVariant, Input, WalModal } from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useApplicationCreateMutation } from '@src/hooks/react-query/applications/mutations/useApplicationCreateMutation';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import { userQueryKeys } from '@src/hooks/react-query/user/userQueryKeys';
import { useWalhallForm } from '@src/utilities/form';
import { convertToNameAndId } from '@src/utilities/string-utility';

const CreateNewApplicationButton = styled(Button)`
  align-self: flex-start;
`;

interface CreateApplicationModalProps {
  buttonVariant: ButtonVariant;
}

const CreateApplicationModal = ({ buttonVariant = 'primary' }: CreateApplicationModalProps) => {
  // Conponent state
  const [open, setOpen] = useState(false);

  // i18n
  const { t: tViewApplicationList } = useTranslation('viewApplicationList');
  const { t } = useTranslation();
  const appNameFormTranslations = t('COMPONENTS').APP_NAME_FORM;
  const createNewApplicationText = tViewApplicationList('CREATE_NEW_APPLICATION');

  // Form
  const formMethods = useWalhallForm({ defaultValues: { appName: '' } });
  const { watch, setError } = formMethods;

  // React Query
  const queryClient = useQueryClient();
  const { isLoading: applicationsLoading, data: applications = [] } = useApplicationsQuery();
  const {
    mutate: createApplication,
    isSuccess: appCreateSuccess,
    error: createApplicationError,
  } = useApplicationCreateMutation();
  // Form
  const formAppId = convertToNameAndId(watch('appName') || '').id;

  useEffect(() => {
    if (createApplicationError?.response?.status === 409) {
      setError('appName', { message: appNameFormTranslations.ALREADY_EXISTS });
    }
  }, [
    formAppId,
    appNameFormTranslations.ALREADY_EXISTS,
    setError,
    createApplicationError?.response?.status,
  ]);

  useEffect(() => {
    if (appCreateSuccess) {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.currentUser() });
    }
  }, [appCreateSuccess, queryClient]);

  return (
    <>
      <CreateNewApplicationButton
        iconLeft={'plus'}
        variant={buttonVariant}
        onClick={() => setOpen(true)}
        disabled={applicationsLoading}>
        {createNewApplicationText}
      </CreateNewApplicationButton>

      {open && (
        <FormProvider {...formMethods}>
          <WalModal
            handleFormSubmit={({ appName }) => {
              createApplication({ newApplicationPayload: convertToNameAndId(appName) });
            }}
            openState={[open, setOpen]}
            title={createNewApplicationText}
            content={
              <Input
                name={'appName'}
                required
                maxLength={50}
                label={appNameFormTranslations.PLACEHOLDER}
                noLabelOverflow
                standardValidation={[
                  {
                    type: 'existingId',
                    ids: applications.map((a) => a.id),
                  },
                  { type: 'name' },
                ]}
                className={'mb-sm'}
              />
            }
            actions={{
              main: {
                text: createNewApplicationText,
                props: {
                  type: 'submit',
                },
              },
              cancel: {
                props: {
                  onClick: () => {
                    setOpen(false);
                    formMethods.reset();
                  },
                },
              },
            }}
          />
        </FormProvider>
      )}
    </>
  );
};

export default CreateApplicationModal;
