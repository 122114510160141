import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoles, EnvTypeRoles, OrgRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';

import useApplicationEnvironmentsQuery from './react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import useGetCurrentUserQuery from './react-query/user/queries/useGetCurrentUserQuery';
import { useUserOrgPermissionsQuery } from './react-query/user/queries/useGetUserPermissionsQuery';
import { useDecision } from './useDecision';
import { useGetUserRoles } from './useGetUserRoles';

const administratorPermissons = [
  'testUpdateDeleteCloudAccount',
  'createCloudAccounts',
  'createDeleteDeployer',
  'assignAdministrator',
  'purgeVersionValue',
  'editResources',
  'createUpdateDeleteRegistries',
] as const;
export type AdministratorPermissons = (typeof administratorPermissons)[number];

export type RBACPermissionTypes =
  | keyof NonNullable<ReturnType<typeof usePermissions>['permissions']>
  | AdministratorPermissons;

interface CustomParams {
  orgId?: string;
  appId?: string;
  envId?: string;
}

/**
 * Internal hook. This is separated from the `useRBAC` hook so we can automatically infer the return type in the useRBAC hook.
 * Guidelines:
 * ```
 * - Be explicit about permission names. Even if the permitted roles are the same for two different features, define it as a separate key/name.
 *   This will improve readability & make it easier to understand what part of the UI it's referring to.
 * - Be explicit about what roles have permssions i.e. positive checks vs negative checks. Negative checks could lead to a new role type being granted permission once it's added.
 *   e.g. (appRole === 'owner' || appRole === 'developer') vs appRole !== 'viewer'
 * - orgType administator has permission to do everything by default. There's no need to include it in the permissions defined here.
 * ```
 */
const usePermissions = (orgRole: OrgRoles, customParams?: CustomParams) => {
  // Router hooks
  const { orgId: routerOrgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;
  const orgId = customParams?.orgId || routerOrgId;

  // React Query
  const { data: user, isSuccess: isCurrentUserLoaded } = useGetCurrentUserQuery();
  const { data: applicationEnvironments } = useApplicationEnvironmentsQuery({ orgId, appId });
  const { data: environment } = useEnvironmentQuery({ orgId, appId, envId: customParams?.envId });
  const { isUserPermissionsLoaded, userOrgPermissions } = useUserOrgPermissionsQuery(orgId);

  const deployerRoleForAllEnvTypes = useMemo(() => {
    if (!applicationEnvironments?.length || !user) return false;

    const appEnvTypes = applicationEnvironments?.map((env) => env.type);

    const envTypeRolesForApplication: Record<string, EnvTypeRoles> = appEnvTypes.reduce(
      (prevState, envType) => {
        const role = user.roles[`/orgs/${orgId}/env-types/${envType}`] as EnvTypeRoles;

        return role
          ? {
              ...prevState,
              [envType]: role,
            }
          : prevState;
      },
      {}
    );

    if (!Object.keys(envTypeRolesForApplication).length) {
      return false;
    }

    /**
     * An Owner will not be able to delete an App unless they have the Deployer Role for all the Environment Types used in the App.
     */
    return appEnvTypes?.every((envTypeId) => envTypeRolesForApplication[envTypeId] === 'deployer');
  }, [applicationEnvironments, user, orgId]);

  // Selectors
  const appRole = user?.roles?.[`/orgs/${orgId}/apps/${appId}`];

  const envTypeRole = environment
    ? user?.roles?.[`/orgs/${orgId}/env-types/${environment.type}`]
    : undefined;

  // Permissions
  const isAppOwnerOrDeveloperRole = ['owner', 'developer'].includes(appRole as AppRoles);
  const isAppOwner = appRole === 'owner';
  const isDeployer = envTypeRole === 'deployer';

  // RBAC
  const [userPermissionsDecision] = useDecision('user-permissions');

  const viewPermissions = useViewPermissions(orgRole, orgId);

  return {
    permissionsLoaded:
      // Only require userPermissions if the feature flag is enabled
      (userPermissionsDecision.enabled ? isUserPermissionsLoaded : true) && isCurrentUserLoaded,
    permissions: {
      ...administratorPermissons.reduce(
        (acc, permission) => ({ ...acc, [permission]: orgRole === 'administrator' }),
        {} as Record<AdministratorPermissons, boolean>
      ),
      createUpdateDeleteEnvironmentType: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageEnvironmentTypes')
        : orgRole === 'administrator',
      createEnvironment: isAppOwnerOrDeveloperRole,
      deleteEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
      accessDraftURL: isAppOwnerOrDeveloperRole,
      pauseEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
      revertValueVersion: isAppOwnerOrDeveloperRole,
      deleteApplication: isAppOwner && deployerRoleForAllEnvTypes,
      createApplication: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('createApplication')
        : orgRole === 'manager',
      updateWebhook: isAppOwner,
      editApplication: isAppOwnerOrDeveloperRole,
      deployEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
      archiveArtefactVersions: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('contributeImages')
        : orgRole === 'manager',
      deleteArtefact: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('deleteImages')
        : orgRole === 'administrator',
      editSharedValues: appRole === 'developer' || appRole === 'owner',
      connectCIPipeline: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('contributeImages')
        : orgRole === 'manager',
      // Merge 'createUpdateOrgMembers' & 'createUpdateOrgMembers' & 'deleteOrgMember' once FF is deleted
      createUpdateDeleteServiceUsers: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageUsers')
        : orgRole === 'manager',
      createUpdateOrgMembers: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageUsers')
        : orgRole === 'manager',
      deleteOrgMember: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageUsers')
        : orgRole === 'administrator',
      // Merge 'manageResources' & 'createCloudAccounts' once FF is deleted
      manageResources: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageResources')
        : orgRole === 'administrator',
      createCloudAccounts: userPermissionsDecision.enabled
        ? userOrgPermissions?.includes('manageResources')
        : orgRole === 'manager',
      ...viewPermissions,
    },
  };
};

/**
 * Hook which contains all view permissions.
 */
const useViewPermissions = (orgRole: OrgRoles, orgId?: string) => {
  const { userOrgPermissions } = useUserOrgPermissionsQuery(orgId);

  // RBAC
  const [userPermissionsDecision] = useDecision('user-permissions');

  return {
    viewEnvironmentTypesPage: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('read')
      : orgRole === 'manager' || orgRole === 'orgViewer',
    viewAccountsPage: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('read')
      : orgRole === 'manager' || orgRole === 'orgViewer',
    viewArtefactsPage: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('readArtefactVersions')
      : orgRole === 'member' || orgRole === 'manager' || orgRole === 'orgViewer',
    viewServiceUsersPage: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('read')
      : orgRole === 'manager' || orgRole === 'orgViewer',
    viewOrgMembersPage: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('read')
      : orgRole === 'manager' || orgRole === 'orgViewer',
    viewApplications: orgRole === 'manager' || orgRole === 'member' || orgRole === 'orgViewer',
    viewResources: userPermissionsDecision.enabled
      ? userOrgPermissions?.includes('read')
      : orgRole === 'orgViewer',
    viewRegistries: orgRole === 'orgViewer',
  };
};

/**
 * @param action Name of the action you want to get the permissions for.
 * @example
 * When naming your return variable when using the hook, prefix with `can`.
 * Permission `pauseEnvironment` will be named `canPauseEnvironment`.
 * ```
 *  const canPauseEnvironment = useRBAC('pauseEnvironment');
 * ```
 * @returns boolean
 */
export const useRBAC = (action: RBACPermissionTypes, customParams?: CustomParams) => {
  // Selectors
  const { orgRole } = useGetUserRoles({ orgId: customParams?.orgId });

  const { permissions, permissionsLoaded } = usePermissions(orgRole, customParams);

  // Administrator can access everything
  return permissionsLoaded
    ? (action && permissions?.[action]) || orgRole === 'administrator'
    : undefined;
};
