import { Button, FormGenerator, Input, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ServicePortEntry } from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/ServicePorts/ServicePorts';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { UpdateWorkloadChanges, useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { ServiceFeature, ServicePort } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const ModalHeading = styled.h3`
  margin-bottom: ${units.margin.sm};
`;

const SaveButton = styled(Button)`
  margin-right: ${units.margin.md};
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${units.margin.md};
`;

interface ServicePortsModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  servicePorts: ServicePortEntry[];
  deltaPath: string;
  servicePort?: ServicePortEntry;
}

interface ServicePortFormData {
  name: string;
  container_port: number;
  service_port: number;
  protocol: string;
}

export const ServicePortModal = ({
  state,
  servicePort,
  servicePorts,
  deltaPath,
}: ServicePortsModalProps) => {
  const [openModal, setOpenModal] = state;

  const { updateWorkload: updateServicePorts } = useDeltaUtils<ServiceFeature>(
    `${deltaPath}/ports`
  );

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  const uiTranslations = t('UI');

  // form
  const methods = useWalhallForm<ServicePortFormData>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  // context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const cancelChanges = () => {
    setOpenModal(false);
  };

  const saveChanges = (formValues: ServicePortFormData) => {
    const { name, service_port, container_port, protocol } = formValues;
    const payload: ServicePort = {
      service_port,
      container_port,
      protocol,
    };

    const deltaToPatch: UpdateWorkloadChanges = [];
    const changedServicePortName = servicePort && servicePort.name !== name;

    if (changedServicePortName) {
      deltaToPatch.push({
        key: servicePort.name,
        op: 'remove',
      });
      deltaToPatch.push({
        key: name,
        op: 'add',
        value: payload,
      });
    } else {
      deltaToPatch.push({
        key: name,
        op: servicePort ? 'replace' : 'add',
        value: payload,
      });
    }

    updateServicePorts(deltaToPatch, { deleteParentPaths: true });
    setOpenModal(false);
  };

  return (
    <WalModal openState={[openModal, setOpenModal]} disableOverflow>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveChanges)}>
          <ModalHeading>
            {servicePort
              ? !draftModeActive
                ? sectionsTranslations.VIEW_SERVICE_PORT
                : sectionsTranslations.EDIT_SERVICE_PORT
              : sectionsTranslations.ADD_SERVICE_PORT}
          </ModalHeading>
          <Input
            name={'name'}
            defaultValue={servicePort?.name}
            label={sectionsTranslations.PORT_NAME}
            required
            readonly={!draftModeActive}
            standardValidation={[
              { type: 'id' },
              {
                type: 'existingId',
                ids: servicePorts?.filter((s) => s.name !== servicePort?.name).map((s) => s.name),
              },
            ]}
          />
          <FormGenerator
            fields={[
              {
                type: 'input',
                props: {
                  name: 'service_port',
                  type: 'number',
                  valueAsNumber: true,
                  label: sectionsTranslations.SERVICE_PORT,
                  readonly: !draftModeActive,
                  required: true,
                  max: 65535,
                  min: 1,
                  defaultValue: servicePort?.service_port?.toString(),
                },
              },
              {
                type: 'input',
                props: {
                  name: `container_port`,
                  type: 'number',
                  valueAsNumber: true,
                  label: sectionsTranslations.CONTAINER_PORT,
                  readonly: !draftModeActive,
                  defaultValue: servicePort?.container_port?.toString(),
                  max: 65535,
                  min: 1,
                },
              },
              {
                type: 'dropdown',
                props: {
                  name: 'protocol',
                  label: sectionsTranslations.PROTOCOL,
                  items: [
                    { id: 'TCP', label: 'TCP', value: 'TCP' },
                    { id: 'UDP', label: 'UDP', value: 'UDP' },
                    { id: 'SCTP', label: 'SCTP', value: 'SCTP' },
                  ],
                  disabled: !draftModeActive,
                  defaultValue: servicePort ? servicePort?.protocol : 'TCP',
                },
              },
            ]}
          />
          <ActionsWrapper>
            {draftModeActive && (
              <SaveButton variant={'primary'} type={'submit'} disabled={servicePort && !isDirty}>
                {uiTranslations.SAVE_CHANGES}
              </SaveButton>
            )}
            <Button variant={'secondary'} onClick={cancelChanges}>
              {draftModeActive ? uiTranslations.CANCEL : uiTranslations.CLOSE}
            </Button>
          </ActionsWrapper>
        </form>
      </FormProvider>
    </WalModal>
  );
};

export default ServicePortModal;
