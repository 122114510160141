import { Input } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

interface AddResourceClassFormProps {
  idDisabled?: boolean;
}

export const AddResourceClassForm = ({ idDisabled = false }: AddResourceClassFormProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <>
      <Input
        name={'id'}
        required
        label={uiTranslations.ID}
        standardValidation={[{ type: 'id' }]}
        readonly={idDisabled}
      />
      <Input name={'description'} label={uiTranslations.DESCRIPTION} />
    </>
  );
};
