import React, { useCallback, useState } from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import { KeyValue } from '@src/models/general';
import { useWalhallForm } from '@src/utilities/form';

import { AutoCompleteOptions } from '../../base/MonacoInput/MonacoInput';
import EntryEditor, { EntryEditorState } from '../EntryEditor/EntryEditor';
import KeyValueEditorViewComponent from '../KeyValueEditor/KeyValueEditorViewComponent';
import KeyValueForm from '../KeyValueForm/KeyValueForm';

export interface FieldEditorProps {
  id?: string;
  /** key value object */
  keyValue: KeyValue;
  existingKeys?: string[];
  /** wether the field is readonly */
  readonly?: boolean;
  /** callback function when the user deletes */
  onDeleteField: (key: KeyValue) => void;
  /** callback function when the user saves a field */
  onSaveField: (formValue: KeyValue) => void;
  /** if the field is a secret - add special hide and show features for secret fields */
  isSecret?: boolean;
  /** options for the autocomplete component */
  autoCompleteOptions?: AutoCompleteOptions;
  /** label for key input */
  keyLabel?: string;
  keyExtension?: string;
  /** key placeholder */
  keyPlaceholder?: string;
  /** label for value input */
  valueLabel?: string;
  /** placeholder for value input */
  valuePlaceholder?: string;
  /** if the key should be readonly */
  keyReadOnly?: boolean;
  hideLabel?: boolean;
  showDescription?: boolean;
  showSecret?: boolean;
}

const KeyValueEditor = (props: FieldEditorProps) => {
  const {
    id,
    keyValue,
    onDeleteField,
    onSaveField,
    isSecret,
    autoCompleteOptions,
    showDescription,
    showSecret,
    readonly,
    keyExtension,
  } = props;

  const methods = useWalhallForm();
  const { trigger, watch, setValue } = methods;
  const formValues = watch();
  const [state, setState] = useState<EntryEditorState>('view');

  /**
   * save field handler
   *
   * @param formValue - data entered by the user in the form
   */
  const handleSaveField = useCallback(
    (formData: FieldValues) => {
      setValue(id ? `${id}-value` : 'value', ''); // empty value field when saving
      formData[id ? `${id}-value` : 'value'] = formData[id ? `${id}-value` : 'value'].replace(
        /\\n/g,
        '\n'
      ); // unescape newlines
      trigger().then((valid) => {
        if (valid) {
          onSaveField({
            key: formData[id ? `${id}-key` : 'key']?.trim(),
            value: formData[id ? `${id}-value` : 'value']?.trim(),
            description: formData[id ? `${id}-description` : 'description']?.trim(),
            index: keyValue.index,
          });
          setState('view');
        }
      });
    },
    [onSaveField, trigger, id, keyValue.index, setValue]
  );

  /**
   * delete field handler
   */
  const handleDeleteField = (): void => {
    onDeleteField(keyValue);
  };

  const submitEntry = () => {
    handleSaveField(formValues);
  };

  return (
    <EntryEditor
      id={keyValue.key}
      readonly={readonly}
      formComponent={
        <FormProvider {...methods}>
          <KeyValueForm
            {...props}
            handleSaveField={handleSaveField}
            editMode={state === 'edit'}
            keyExtension={keyExtension}
          />
        </FormProvider>
      }
      viewComponent={
        <KeyValueEditorViewComponent
          keyValue={keyValue}
          isSecret={isSecret}
          enableValueCopy={keyValue.value?.length > 50}
          autoCompleteOptions={autoCompleteOptions}
          showDescription={showDescription}
          showSecret={showSecret}
        />
      }
      entryState={[state, setState]}
      onDeleteEntry={handleDeleteField}
      onSubmitEntry={submitEntry}
      onClickOutside={submitEntry}
      disableSubmit={keyValue.key === ''}
      cardStyle={isSecret ? 'transparent' : 'default'}
    />
  );
};

export default KeyValueEditor;
