import { EmptyStateCard, WalTable, WalTableColumn, WalTableRow } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import useApiTokensByUserIdQuery from '@src/hooks/react-query/api-tokens/queries/useApiTokensByUserIdQuery';
import { NewApiToken } from '@src/models/api-token';
import { Role } from '@src/models/role';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const Text = styled.p<{ translucent?: boolean }>`
  margin-bottom: ${units.margin.md};
  ${({ theme, translucent }) =>
    translucent &&
    css`
      color: ${theme.color.textTranslucent};
    `}
`;

const ScrollingArea = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

interface ConfirmDeleteServiceUsersModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  onDeleteConfirmed: () => void;
  serviceUserToBeDeleted: Role | undefined;
  onCancel?: () => void;
}
const ConfirmDeleteServiceUsersModal = ({
  openState,
  onDeleteConfirmed,
  serviceUserToBeDeleted,
  onCancel,
}: ConfirmDeleteServiceUsersModalProps) => {
  // i18n
  const { t } = useTranslation();
  const conflictModalTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.CONFIRM_DELETE_SERVICE_USER;
  // State
  const [open, setOpen] = openState;
  // React Query
  const { data: affectedTokens } = useApiTokensByUserIdQuery(serviceUserToBeDeleted?.id);

  const columns: WalTableColumn<NewApiToken>[] = [
    {
      label: conflictModalTranslations.API_TOKEN_ID,
      prop: 'id',
      ellipsisTooltip: {
        maxWidth: 220,
        maxCharacters: 20,
        text: (row) => row.data.id,
      },
      template: (row) => <span>{row.data.id || '-'}</span>,
    },
    {
      label: conflictModalTranslations.DATE_CREATED,
      justifyContent: 'flex-start',
      prop: 'created_at',
      template: (row) => (
        <span>
          {formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE) || '-'}
        </span>
      ),
    },
  ];

  return (
    <ConfirmDeleteModal
      title={conflictModalTranslations.TITLE}
      state={[open, setOpen]}
      deleteConfirmedCallback={onDeleteConfirmed}
      onCancel={onCancel}
      showWarningText
      customContentComponent={
        // todo: add disableClickOutside to component
        <>
          <Text>
            {' '}
            <Trans
              defaults={conflictModalTranslations.DESCRIPTION}
              values={{ userName: serviceUserToBeDeleted?.name }}
            />
          </Text>
          <Text>{conflictModalTranslations.API_TOKENS_AFFECTED}:</Text>
          <ScrollingArea>
            {affectedTokens && affectedTokens.length > 0 ? (
              <WalTable
                caption={conflictModalTranslations.API_TOKENS_AFFECTED}
                columns={columns}
                tableRowStyle={'base'}
                rows={(affectedTokens || [])?.map(
                  (deleteChange): WalTableRow => ({
                    data: deleteChange,
                  })
                )}
                disableScrolling
              />
            ) : (
              <EmptyStateCard>{conflictModalTranslations.NO_API_TOKENS_AFFECTED}</EmptyStateCard>
            )}
          </ScrollingArea>
        </>
      }
    />
  );
};

export default ConfirmDeleteServiceUsersModal;
