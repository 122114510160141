import { rem } from 'polished';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import imageLg from '@src/assets/images/sign-up-bg-lg.png';
import imageMd from '@src/assets/images/sign-up-bg-md.png';
import imageSm from '@src/assets/images/sign-up-bg-sm.png';
import HumanitecPlatformLogo from '@src/components/shared/HumanitecPlatformLogo';
import useAuthenticateUserMutation from '@src/hooks/react-query/user/mutations/useAuthenticateUserMutation';
import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';

import SignUpForm from '../../components/SignUpForm/SignUpForm';

const BREAKPOINT_SM = `(max-width: 480px)`;
const BREAKPOINT_MD = `(min-width: 481px) and (max-width: 1000px)`;
const BREAKPOINT_LG = `(min-width: 1001px)`;

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  justify-content: center;
  height: 100vh;
  overflow: hidden;

  @media ${BREAKPOINT_LG} {
    grid-template-columns: 1fr 1fr;
  }
`;

const SignUpWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  order: 2;
  padding-top: ${rem(85)};

  @media ${BREAKPOINT_LG} {
    order: 0;
    height: 100vh;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  order: 1;
  img {
    display: none;
  }

  @media ${BREAKPOINT_SM} {
    .small {
      display: block;
    }
  }

  @media ${BREAKPOINT_MD} {
    .medium {
      display: block;
    }
  }

  @media ${BREAKPOINT_LG} {
    .large {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  overflow: hidden;
`;

const HumanitecLogoLarge = styled(HumanitecPlatformLogo)`
  display: none;
  @media ${BREAKPOINT_LG} {
    font-size: 1.5rem;
    display: block;
  }
`;

const HumanitecLogoSmallMedium = styled(HumanitecPlatformLogo)`
  display: none;
  @media ${BREAKPOINT_MD} {
    font-size: 1.5rem;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 8%;
  }

  @media ${BREAKPOINT_SM} {
    font-size: 1rem;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 5%;
  }
`;

const LogoSignUpWrapper = styled.div`
  @media ${BREAKPOINT_LG} {
    display: flex;
    flex: 1;
    transform: translateY(15vh);
    flex-direction: column;
  }
`;

const Wrapper1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface SignUpProps {
  disableFreeTrial?: boolean;
}

export const SignUp = ({ disableFreeTrial = false }: SignUpProps) => {
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  const { authErrorMessage: authenticateFailedReason } = useCurrentUserStore();

  const { mutate: authenticateUser } = useAuthenticateUserMutation({
    authenticationType: 'register',
    provider: 'google',
    disableFreeTrial,
  });

  const googleAuthSuccess = useCallback(
    (googleToken: string): void => {
      authenticateUser(googleToken);
    },
    [authenticateUser]
  );

  return (
    <Wrapper>
      <SignUpWrapper>
        <Wrapper1>
          <HumanitecLogoLarge overrideColor={'main'} />
          <LogoSignUpWrapper>
            <h1 className={'mb-xl'}>{authTranslations.SIGN_UP_FOR_FREE}</h1>
            <SignUpForm
              isInvite={false}
              googleAuthSuccess={googleAuthSuccess}
              authenticateFailedReason={authenticateFailedReason}
              isMvp={disableFreeTrial}
            />
          </LogoSignUpWrapper>
        </Wrapper1>
      </SignUpWrapper>
      <ImageWrapper>
        <HumanitecLogoSmallMedium overrideColor={'white'} />
        <Image className={'small'} src={imageSm} />
        <Image className={'medium'} src={imageMd} />
        <Image className={'large'} src={imageLg} />
      </ImageWrapper>
    </Wrapper>
  );
};
