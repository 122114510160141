import { set } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import type { DeploymentSetModuleResource, Workload } from '@src/models/deployment-set';
import type { ResourceType } from '@src/models/resources';

import useAppValuesQuery from './react-query/shared-values/queries/useAppValuesQuery';
import { useDeltaUtils } from './useDeltaUtils/useDeltaUtils';

export interface ContextPLaceholders {
  app: {
    description: string;
    id: string;
  };
  env: {
    description: string;
    id: string;
    type: string;
  };
  org: {
    description: string;
    id: string;
  };
  deploy: {
    description: string;
    id: string;
    set: {
      id: string;
    };
  };
  res?: {
    description: string;
    id: string;
  };
}
export const getContextPlaceholders = (
  translations: Record<string, string>,
  isDriverInput?: boolean
) => {
  const placeholders: ContextPLaceholders = {
    app: {
      description: translations.CURRENT_APP!,
      id: '',
    },
    env: {
      description: translations.CURRENT_ENV!,
      id: '',
      type: '',
    },
    org: {
      description: translations.CURRENT_ORG!,
      id: '',
    },
    deploy: {
      description: translations.CURRENT_DEPLOY!,
      id: '',
      set: {
        id: '',
      },
    },
  };

  if (isDriverInput) {
    placeholders.res = {
      description: translations.CURRENT_RESOURCE!,
      id: '',
    };
  }
  return placeholders;
};

/**
 * whether the placeholders are used in an input in the labels section of a workload
 *
 * @param isLabel
 */
const usePlaceholders = (isLabels?: boolean) => {
  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: appValues = [] } = useAppValuesQuery();

  const { data: workload, workloadIds, sharedResources } = useDeltaUtils<Workload>();

  // i18n
  const { t } = useTranslation();
  const placeholderDescriptionsTranslations = t('PLACEHOLDER_DESCRIPTIONS');
  const placeholders = useMemo(() => {
    const obj: any = {
      modules: {
        description: placeholderDescriptionsTranslations.MODULES_DESCRIPTION,
      },
    };
    for (const workloadId of workloadIds) {
      if (obj) {
        obj.modules[workloadId] = {
          service: {
            name: '',
            description: placeholderDescriptionsTranslations.MODULES_SERVICE_NAME_DESCRIPTION,
          },
          credentials: {
            username: '',
            password: '',
          },
          database: {
            name: '',
            username: '',
            password: '',
          },
        };
        obj.context = {
          description: placeholderDescriptionsTranslations.CONTEXT_DESCRIPTION,
          ...getContextPlaceholders(placeholderDescriptionsTranslations, false),
        };
      }
    }
    // get externals changes from set
    const moduleExternals: Record<string, DeploymentSetModuleResource> = workload?.externals
      ? { ...workload?.externals }
      : {};

    if (moduleExternals && resourceTypes) {
      obj.externals = {
        description: placeholderDescriptionsTranslations.EXTERNALS_DESCRIPTION,
        ...createResourceObject(moduleExternals, resourceTypes),
      };
    }

    if (appValues) {
      obj.values = {
        description: placeholderDescriptionsTranslations.VALUES_DESCRIPTION,
      };
      for (const appValue of appValues) {
        obj.values[appValue.key] = {
          description: appValue.description,
        };
      }
    }

    if (sharedResources && resourceTypes && !isLabels) {
      obj.shared = {
        description: placeholderDescriptionsTranslations.SHARED_DESCRIPTION,
        ...createResourceObject(sharedResources, resourceTypes),
      };
    }

    obj.pod = { hostIp: '', metadata: { name: '', namespace: '' } };
    obj.containers = { main: { limits: { cpu: '', ram: '' } } };
    return obj;
  }, [
    placeholderDescriptionsTranslations,
    workload?.externals,
    resourceTypes,
    appValues,
    sharedResources,
    isLabels,
    workloadIds,
  ]);
  return placeholders;
};

const createResourceObject = (
  externals: Record<string, DeploymentSetModuleResource>,
  resourceTypes: ResourceType[]
): Record<string, Record<string, string>> => {
  const obj = {};
  for (const externalResourcesEntry of Object.entries(externals)) {
    const [externalId, externalResource]: [string, DeploymentSetModuleResource] =
      externalResourcesEntry;
    set(obj, externalId, {});
    const resourceTypeItem: ResourceType | undefined = resourceTypes.find(
      (resourceType) => resourceType.type === externalResource?.type
    );

    const resourceTypeProps =
      resourceTypeItem && resourceTypeItem.outputs_schema
        ? {
            ...resourceTypeItem.outputs_schema.properties,
          }
        : {};
    for (const typeProp in resourceTypeProps) {
      if (resourceTypeProps.hasOwnProperty(typeProp)) {
        if (resourceTypeProps[typeProp]?.properties) {
          for (const subProp in resourceTypeProps[typeProp]?.properties) {
            if (resourceTypeProps[typeProp]?.properties?.hasOwnProperty(subProp)) {
              set(obj, `${externalId}.${subProp}`, '');
            }
          }
        } else {
          set(obj, `${externalId}.${typeProp}`, '');
        }
      }
    }
  }
  return obj;
};
export default usePlaceholders;
