import { useQuery } from '@tanstack/react-query';

import { Org } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import useGetCurrentUserQuery from '../../user/queries/useGetCurrentUserQuery';
import { orgQueryKeys } from '../orgQueryKeys';

const useGetOrgsQuery = () => {
  const { data: user } = useGetCurrentUserQuery();

  return useQuery({
    queryKey: orgQueryKeys.list(),
    queryFn: () => makeRequest<Org[]>('GET', '/orgs'),
    select: (data) => data.data,
    enabled: Boolean(user),
  });
};

export default useGetOrgsQuery;
