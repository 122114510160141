import {
  Button,
  CopyText,
  WalDropdownMenu,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useRBAC } from '@src/hooks/useRBAC';
import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { useRolesStore } from '@src/hooks/zustand/useRolesStore';
import { OrgRoles, Role } from '@src/models/role';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { useWalhallForm } from '@src/utilities/form';

import ApiTokenSection from './ApiTokenSection/ApiTokenSection';
import ConfirmDeleteServiceUsersModal from './ConfirmDeleteServiceUsersModal/ConfirmDeleteServiceUsersModal';

interface ServiceUsersTableProps {
  serviceUsers: Role<OrgRoles>[];
}

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ExpandedContentTitle = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  word-break: break-all;
  font-size: ${units.fontSize.sm};
`;

const ExpandedContentDetail = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const ServiceUsersTable = ({ serviceUsers }: ServiceUsersTableProps) => {
  // i18n
  const { t } = useTranslation();
  const navBarTranslations = t('NAVIGATION');
  const serviceUsersTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS;

  // Component state
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [serviceUserToBeDeleted, setServiceUserToBeDeleted] = useState<Role | undefined>();

  // Hooks
  const methods = useWalhallForm();
  const roleItems = useRoleDropdownOptions(true);
  const { lastCreatedServiceUsersIds } = useRolesStore();

  // Context
  const { orgRole } = useGetUserRoles();

  // RBAC
  const canAssignAdministrator = useRBAC('assignAdministrator');
  const canCreateUpdateDeleteServiceUsers = useRBAC('createUpdateDeleteServiceUsers');

  // React Query
  const { mutate: updateRole } = useRoleUpdateMutation();
  const {
    mutate: deleteServiceUser,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useRoleDeleteMutation(
    undefined,
    // successCallback
    () => {
      setExpandedRow(undefined);
    }
  );

  useEffect(() => {
    // open delete confirmation modal
    if (serviceUserToBeDeleted !== undefined) setConfirmDeleteModalOpen(true);
  }, [serviceUserToBeDeleted]);

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const columns: WalTableColumn<Role<OrgRoles>>[] = [
    {
      label: serviceUsersTranslations.NAME,
      prop: 'name',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 35,
        text: (row) => row.data.name,
      },
    },
    {
      label: serviceUsersTranslations.CREATED_ON,
      prop: 'created_at',
      template: (row) => (
        <p>
          {formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE) || (
            <StyledDash>-</StyledDash>
          )}
        </p>
      ),
    },
    {
      label: serviceUsersTranslations.ROLE,
      prop: 'role',
      tabIndex: -1,
      justifyContent: 'flex-start',
      template: (row) => (
        <WalDropdownMenu
          buttonSize={'small'}
          defaultValue={row.data.role}
          items={roleItems}
          onItemClick={(role) => updateRole({ roleId: row.data.id, newRole: role })}
          // Disable changing administrator role for a non-administrator
          disabled={
            (row.data.role === 'administrator' && !canAssignAdministrator) ||
            !canCreateUpdateDeleteServiceUsers
          }
        />
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      hide: !canCreateUpdateDeleteServiceUsers,
      template: (row) => (
        <Button
          iconLeft={'delete'}
          size={'small'}
          variant={'secondary'}
          ariaLabel={serviceUsersTranslations.DELETE_SERVICE_USER}
          // Disable deleting administrator role for a non-administrator
          disabled={row.data.role === 'administrator' && !canAssignAdministrator}
          onClick={(e) => {
            e.stopPropagation();
            setServiceUserToBeDeleted(row.data);
          }}
        />
      ),
    },
  ];

  const canAddAndRevokeApiToken = (roleOfServiceUser: OrgRoles): boolean => {
    /*
     * The Add new and revoke token buttons are only visible to users whose role is equal to or “higher” than the service user’s:
     * As a manager I can’t add tokens to a service user with an administrator role.
     * As a manager I can add tokens to a service user with the member or manager role.
     * As an administrator I can add tokens to any service user
     */
    return (
      orgRole === 'administrator' ||
      (orgRole === 'manager' && (roleOfServiceUser === 'manager' || roleOfServiceUser === 'member'))
    );
  };

  const renderExpandableContent = (user: Role<OrgRoles>) => {
    return (
      <>
        <ExpandedContentTitle>{serviceUsersTranslations.NAME}</ExpandedContentTitle>
        <ExpandedContentDetail>{user.name}</ExpandedContentDetail>
        <ExpandedContentTitle>{serviceUsersTranslations.ID}</ExpandedContentTitle>
        <ExpandedContentDetail className={'flex'}>
          <span data-testid={`user_id_${user.id}`}>{user.id}</span>{' '}
          <CopyText text={user.id} iconSize={12} />
        </ExpandedContentDetail>
        <ExpandedContentTitle>{serviceUsersTranslations.CREATED_ON}</ExpandedContentTitle>
        <ExpandedContentDetail>
          {formatDate(user.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        </ExpandedContentDetail>
        <ApiTokenSection user={user} readOnly={!canAddAndRevokeApiToken(user.role)} />
      </>
    );
  };

  const handleDeleteConfirmation = () => {
    if (serviceUserToBeDeleted !== undefined) deleteServiceUser(serviceUserToBeDeleted.id);
  };

  return (
    <FormProvider {...methods}>
      <WalTable
        showCount
        caption={navBarTranslations.SERVICE_USERS}
        columns={columns}
        expandedRowState={[expandedRow, setExpandedRow]}
        disableScrolling
        tableStyle={'expandable'}
        rows={
          serviceUsers?.map((user) => ({
            data: user,
            isNew: lastCreatedServiceUsersIds.includes(user.id),
            expandableContent: renderExpandableContent(user),
          })) ?? []
        }
      />

      {confirmDeleteModalOpen && (
        <ConfirmDeleteServiceUsersModal
          openState={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
          onDeleteConfirmed={handleDeleteConfirmation}
          serviceUserToBeDeleted={serviceUserToBeDeleted}
          onCancel={() => setServiceUserToBeDeleted(undefined)}
        />
      )}
    </FormProvider>
  );
};

export default ServiceUsersTable;
