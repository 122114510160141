import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  AuthenticationProviders,
  AuthenticationTypes,
  useCurrentUserStore,
} from '@src/hooks/zustand/useCurrentUserStore';
import { isAxiosError } from '@src/types/type-guards';
import {
  getLastVisitedURL,
  removeFreeTrialDisabled,
  setFreeTrialDisabled,
} from '@src/utilities/local-storage';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../userQueryKeys';

interface AuthenticateUserProps {
  token?: string | null;
  provider?: AuthenticationProviders;
  authenticationType?: AuthenticationTypes;
  privacyPolicyAccepted?: boolean;
  disableFreeTrial?: boolean;
}

const useAuthenticateUserMutation = (props?: AuthenticateUserProps) => {
  const queryClient = useQueryClient();
  const { provider, authenticationType, privacyPolicyAccepted, disableFreeTrial } = props || {};
  const { setAuthErrorMessage, setAuthType } = useCurrentUserStore();

  const payload: {
    provider: AuthenticationProviders | undefined;
    privacy_policy_accepted: boolean | undefined;
    trial_expires_at?: null;
  } = {
    provider,
    privacy_policy_accepted:
      privacyPolicyAccepted || authenticationType === 'register' ? true : undefined,
  };

  // Router hooks
  const navigate = useNavigate();

  return useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: (token: string) => {
      // Clean up any previous disabled free trial local storage
      // In the case where the user didn't create an org after going through the /auth/registermvp route.
      removeFreeTrialDisabled();
      return makeRequest('POST', `/auth/${authenticationType}`, { ...payload, token });
    },
    onSuccess: () => {
      if (disableFreeTrial) {
        setFreeTrialDisabled(true);
      }
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser() });
      queryClient.invalidateQueries({ queryKey: userQueryKeys.currentUser() });

      if (authenticationType) {
        setAuthType(authenticationType);
      }

      const lastURL = getLastVisitedURL();

      if (lastURL) {
        navigate(lastURL);
      }
    },
    onError: (error: AxiosError<{ message: string }> | Error) => {
      datadogRum.addError(error);

      const isFromAxios = isAxiosError(error);
      const responseCode = isFromAxios ? error.response?.status : undefined;

      if (responseCode === 400) {
        setAuthErrorMessage('bad-request');
      } else if (responseCode === 403 && provider) {
        setAuthErrorMessage(`${provider}-not-exist`);
      } else {
        setAuthErrorMessage(isFromAxios ? error.response?.data?.message : error.message);
      }
    },
  });
};

export default useAuthenticateUserMutation;
