import React from 'react';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

interface HeaderProps {
  sticky: boolean;
  backgroundColor: 'darker' | 'brighter' | 'brightest' | 'overlay' | 'transparent' | undefined;
}

interface SectionHeaderProps {
  children: any;
  sticky?: boolean;
  backgroundColor?: 'darker' | 'brighter' | 'brightest' | 'overlay' | 'transparent';
  className?: string;
}

const Header = styled.label<HeaderProps>`
  width: 100%;
  display: flex;
  background-color: transparent;
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.xs};
  color: ${({ theme }) => theme.color.textTranslucent};
  background-color: ${({ theme }) => theme.color.base};

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `};

  ${({ backgroundColor, theme }) => {
    switch (backgroundColor) {
      case 'darker':
        return css`
          background-color: ${theme.color.baseDarker};
        `;
      case 'brighter':
        return css`
          background-color: ${theme.color.baseBrighter};
        `;
      case 'brightest':
        return css`
          background-color: ${theme.color.baseOutline};
        `;
      case 'overlay':
        return css`
          background-color: ${theme.color.baseLayer};
        `;
      case 'transparent':
        return css`
          background-color: transparent;
        `;
      // no default
    }
  }};
`;

const SectionHeader = ({
  children,
  backgroundColor,
  sticky = true,
  className,
}: SectionHeaderProps) => {
  return (
    <Header className={className} sticky={sticky} backgroundColor={backgroundColor}>
      {children}
    </Header>
  );
};

export default SectionHeader;
