import {
  Accordion,
  AccordionItem,
  CardStyles,
  DiffViewer,
  EmptyStateCard,
  WalCard,
  WalDropdownMenu,
  WalLabel,
} from '@humanitec/ui-components';
import { t } from 'i18next';
import yaml from 'js-yaml';
import jsonPointer from 'json-pointer';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { DeploymentDelta, DeploymentDeltaChange } from '@src/models/deployment-delta';
import { DeploymentSet } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-column-gap: ${units.margin.md};
`;

const WorkloadLabel = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.sm};
`;
const WorkloadWrapper = styled(WalCard)`
  margin-bottom: ${units.margin.lg};
`;

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${units.margin.md} 0;
  justify-content: space-between;
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface NewDiffContentProps {
  /** the deployment delta to show the diff from */
  delta: DeploymentDelta;
  /** the deployment set to compare to */
  rightDeploymentSet?: DeploymentSet;
  /**
   * style for the accrodion cards
   */
  accordionCardStyle?: CardStyles;
}

const NewDiffContent = ({ rightDeploymentSet, delta, accordionCardStyle }: NewDiffContentProps) => {
  // Translation
  const uiTranslations = t('UI');

  // Form
  const methods = useWalhallForm({
    defaultValues: {
      viewAs: 'yaml',
    },
  });
  const { watch } = methods;

  const viewAs = watch('viewAs');

  const hasChanges = useMemo(() => {
    return (
      (delta.shared && delta.shared?.length !== 0) ||
      (delta.modules.update && Object.keys(delta.modules.update).length !== 0) ||
      (delta.modules.add && Object.keys(delta.modules.add).length !== 0) ||
      delta.modules.remove?.length !== 0
    );
  }, [delta]);

  const getValueFromWorkloadInSet = (path: string, workloadId: string) => {
    return jsonPointer.has(rightDeploymentSet?.modules[workloadId], path)
      ? rightDeploymentSet?.modules[workloadId] &&
          jsonPointer.get(rightDeploymentSet.modules[workloadId], path)
      : undefined;
  };

  const getValueFromSharedResourcesInSet = (path: string) => {
    return rightDeploymentSet?.shared && jsonPointer.has(rightDeploymentSet.shared, path)
      ? jsonPointer.get(rightDeploymentSet.shared, path)
      : undefined;
  };

  const generateDeltaUpdateDisplayedString = (value: string) => {
    if (value === null) return undefined;
    return viewAs === 'yaml'
      ? yaml.dump(value, { lineWidth: -1 })
      : JSON.stringify(value, undefined, 2);
  };

  const renderChanges = (changes: DeploymentDeltaChange[], workloadId?: string) =>
    changes.map((change) => (
      <DiffViewer
        key={change.path}
        label={change.path}
        newValue={generateDeltaUpdateDisplayedString(change.value) || ''}
        oldValue={generateDeltaUpdateDisplayedString(
          workloadId
            ? getValueFromWorkloadInSet(change.path, workloadId)
            : getValueFromSharedResourcesInSet(change.path)
        )}
      />
    ));

  const renderDeltaUpdates = () =>
    delta?.modules.update &&
    Object.entries(delta?.modules.update).map(([workloadId, changes]) => (
      <WorkloadWrapper key={workloadId} cardStyle={'transparent'}>
        <WorkloadLabel>{workloadId}</WorkloadLabel>
        {renderChanges(changes, workloadId)}
      </WorkloadWrapper>
    ));

  const renderDeltaAdditions = () =>
    delta?.modules.add &&
    Object.entries(delta.modules.add).map(([workloadId, addition]) => (
      <WorkloadWrapper key={workloadId} cardStyle={'transparent'}>
        <WorkloadLabel>{workloadId}</WorkloadLabel>
        <DiffViewer
          key={workloadId}
          newValue={`${
            viewAs === 'yaml' ? yaml.dump(addition) : JSON.stringify(addition, undefined, 2)
          }`}
        />
      </WorkloadWrapper>
    ));

  const renderDeltaSharedChanges = () =>
    delta?.shared && renderChanges(Object.values(delta.shared));

  const renderDeltaRemovals = () =>
    delta?.modules.remove?.map((workloadId) => (
      <DiffViewer key={workloadId} oldValue={workloadId} />
    ));

  const accordionItems: AccordionItem[] = [
    {
      id: 'update',
      headerContent: (
        <CardHeaderWrapper>
          <span>{uiTranslations.UPDATED_WORKLOADS}</span>
          <span className={'txt-translucent txt-sm'}>
            {!delta?.modules.update ? (
              uiTranslations.NO_UPDATES
            ) : (
              <>
                {Object.keys(delta.modules.update).length === 0
                  ? uiTranslations.NO_UPDATES
                  : `${Object.keys(delta.modules.update).length} ${uiTranslations.UPDATED}`}
              </>
            )}
          </span>
        </CardHeaderWrapper>
      ),
      disableExpansion: !delta?.modules.update || Object.keys(delta.modules.update).length === 0,
      expandedByDefault: true,
      sortIndex: delta?.modules.update ? Object.keys(delta?.modules.update).length : 0,
      content: renderDeltaUpdates(),
      cardStyle: accordionCardStyle || 'base',
    },
    {
      id: 'add',
      headerContent: (
        <CardHeaderWrapper>
          <span>{uiTranslations.ADDED_WORKLOADS}</span>
          <span className={'txt-translucent txt-sm'}>
            {!delta?.modules.add ? (
              uiTranslations.NO_WORKLOADS_ADDED
            ) : (
              <>
                {Object.keys(delta.modules.add).length === 0
                  ? uiTranslations.NO_WORKLOADS_ADDED
                  : `${Object.keys(delta.modules.add).length} ${uiTranslations.ADDED}`}
              </>
            )}
          </span>
        </CardHeaderWrapper>
      ),
      disableExpansion: !delta?.modules.add,
      expandedByDefault: true,
      content: renderDeltaAdditions(),
      sortIndex: delta?.modules.add ? Object.keys(delta.modules.add).length : 0,
      cardStyle: accordionCardStyle || 'base',
    },
    {
      id: 'remove',
      headerContent: (
        <CardHeaderWrapper>
          <span>{uiTranslations.REMOVED_WORKLOADS}</span>
          <span className={'txt-translucent txt-sm'}>
            {delta?.modules.remove?.length === 0 ? (
              uiTranslations.NO_WORKLOADS_REMOVED
            ) : (
              <>
                {delta?.modules.remove?.length} {uiTranslations.REMOVED}
              </>
            )}
          </span>
        </CardHeaderWrapper>
      ),
      disableExpansion: delta?.modules.remove?.length === 0,
      expandedByDefault: true,
      content: renderDeltaRemovals(),
      sortIndex: delta?.modules.remove?.length,
      cardStyle: accordionCardStyle || 'base',
    },
    {
      id: 'shared',
      headerContent: (
        <CardHeaderWrapper>
          <span>{uiTranslations.SHARED_RESOURCES}</span>
          <span className={'txt-translucent txt-sm'}>
            {!delta?.shared ? (
              uiTranslations.NO_CHANGES_SHARED
            ) : (
              <>
                {delta.shared.length === 0
                  ? uiTranslations.NO_CHANGES_SHARED
                  : `${delta.shared.length} ${
                      delta.shared.length === 1
                        ? uiTranslations.CHANGE_DIFF
                        : uiTranslations.CHANGES
                    }`}
              </>
            )}
          </span>
        </CardHeaderWrapper>
      ),
      disableExpansion: !delta?.shared || Object.values(delta.shared).length === 0,
      sortIndex: delta?.shared?.length,
      expandedByDefault: true,
      content: renderDeltaSharedChanges(),
      cardStyle: accordionCardStyle || 'base',
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        {hasChanges && (
          <TopBarWrapper>
            <Wrapper>
              <WalLabel>{uiTranslations.SHOW_CHANGES_AS}</WalLabel>
              <WalDropdownMenu
                name={'viewAs'}
                maxWidth={600}
                items={[
                  {
                    id: 'yaml',
                    label: 'YAML',
                    value: 'yaml',
                  },
                  {
                    id: 'json',
                    label: 'JSON',
                    value: 'json',
                  },
                ]}
              />
            </Wrapper>
          </TopBarWrapper>
        )}
        {!hasChanges ? (
          <EmptyStateCard>{uiTranslations.NO_CHANGES}</EmptyStateCard>
        ) : (
          <Accordion items={accordionItems} />
        )}
      </FormProvider>
    </>
  );
};

export default NewDiffContent;
