import { useQueryClient } from '@tanstack/react-query';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import AppHeader from '@src/components/shared/AppHeader/AppHeader';
import ErrorBoundary from '@src/components/shared/ErrorBoundary/ErrorBoundary';
import LoadingBar from '@src/components/shared/LoadingBar';
import NavigationBar from '@src/components/shared/NavigationBar/NavigationBar';
import { NotificationsSocketContext } from '@src/components/socket/contexts';
import { WebSocketEventTypes } from '@src/components/socket/event-types';
import SocketProvider from '@src/components/socket/socketProvider';
import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';
import { windowEnv } from '@src/environment';
import { applicationsQueryKeys } from '@src/hooks/react-query/applications/applicationsQueryKeys';
import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import useArtefactVersionsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactVersionsQuery';
import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import { pipelinesQueryKeys } from '@src/hooks/react-query/pipelines/pipelinesQueryKeys';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/queries/useGetCurrentUserQuery';
import { userQueryKeys } from '@src/hooks/react-query/user/userQueryKeys';
import { useDatadogReplay } from '@src/hooks/useDatadogReplay';
import { MatchParams } from '@src/models/routing';
import { setSelectedOrganization } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';

const Wrapper = styled.div`
  display: flex;
  grid-template-columns: ${rem(300)} 1fr;
  flex: 1;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LoadingStateContainer = styled.div`
  margin: auto auto;
  text-align: center;
`;

const Orgs = () => {
  // router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const navigate = useNavigate();

  // i18n
  const { t } = useTranslation();
  const UITranslations = t('UI');

  // Context
  const { data: orgs = [] } = useGetOrgsQuery();

  // State
  const [artefactIdFromWebSocket, setArtefactIdFromWebSocket] = useState<string>('');

  // React query
  const queryClient = useQueryClient();
  const { refetch: refetchOrgRoles } = useOrgRolesQuery();
  const {
    data: user,
    refetch: refetchCurrentUser,
    isLoading: userLoading,
  } = useGetCurrentUserQuery();
  useArtefactsQuery();

  // @TODO: Look into this implementaion more. If artefactIdFromWebSocket stays the same, the query might not re-run unless archived pararmater changes.
  useArtefactVersionsQuery(
    artefactIdFromWebSocket,
    false,
    undefined,
    undefined,
    !!artefactIdFromWebSocket
  ); // this query would only be enabled when artefactIdFromWebSocket has a value

  useDatadogReplay();

  useEffect(() => {
    const findOrgIdFromUrl = orgs.find((org) => org.id === orgId);
    // redirect to appRoot if the org doesn't exist
    if (!findOrgIdFromUrl && orgs?.[0]?.id) {
      navigate(generateAppURL(orgs[0].id));
    }
  }, [navigate, orgId, orgs]);

  useEffect(() => {
    setSelectedOrganization(orgId);
  }, [orgId]);

  const handleWebSocketMessage = (message: WebSocketMessageEvent) => {
    const parsedMessage = JSON.parse(message.data);
    const data = parsedMessage.data;
    if (data.organization_id === orgId) {
      // loads the artefacts versions if we get an event that an artefact version has been added or updated
      if (
        parsedMessage.type === WebSocketEventTypes.ARTEFACT_VERSION_ADDED ||
        parsedMessage.type === WebSocketEventTypes.ARTEFACT_VERSION_UPDATED ||
        parsedMessage.type === WebSocketEventTypes.BUILD_ADDED
      ) {
        setArtefactIdFromWebSocket(data.artefact_id);
      }
      // load applications if an application is created or deleted
      if (
        parsedMessage.type === WebSocketEventTypes.APP_CREATED ||
        parsedMessage.type === WebSocketEventTypes.APP_DELETED
      ) {
        queryClient.invalidateQueries({
          queryKey: applicationsQueryKeys.list(data.organization_id),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_UPDATED) {
        if (parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.updated`) {
          queryClient.invalidateQueries({
            queryKey: pipelinesQueryKeys.details(
              data.organization_id,
              data.application_id,
              data.pipeline_id
            ),
          });
        }
        queryClient.invalidateQueries({
          queryKey: pipelinesQueryKeys.list(data.organization_id, data.application_id),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_RUN_UPDATED) {
        if (
          parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.run.created`
        ) {
          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.all(data.organization_id, data.application_id),
          });
        }
        if (
          parsedMessage.routing_key ===
          `organization.${data.organization_id}.pipeline.run.completed`
        ) {
          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.applicationEnvironments(
              data.organization_id,
              data.application_id
            ),
          });

          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.all(data.organization_id, data.application_id),
          });
        }
        if (
          parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.run.updated`
        ) {
          queryClient.invalidateQueries({
            queryKey: pipelineRunsQueryKeys.pipelineRunDetails(
              data.organization_id,
              data.application_id,
              data.pipeline_id,
              data.run_id
            ),
          });
        }
        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRuns(
            data.organization_id,
            data.application_id,
            data.pipeline_id
          ),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_JOB_UPDATED) {
        // invalidate the pipeline runs by org query to get the latest pipeline run status in the deployments page
        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRunsByOrg(data.organization_id, {
            appId: data.application_id,
          }),
        });

        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRunDetails(
            data.organization_id,
            data.application_id,
            data.pipeline_id,
            data.run_id
          ),
        });

        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRunJobs(
            data.organization_id,
            data.application_id,
            data.pipeline_id,
            data.run_id
          ),
        });

        queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.all(data.organization_id, data.application_id),
        });
      }
      if (
        parsedMessage.type === WebSocketEventTypes.USER_UPDATED ||
        parsedMessage.type === WebSocketEventTypes.USER_DELETED ||
        parsedMessage.type === WebSocketEventTypes.USER_CREATED
      ) {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.userPermissions(data.organization_id, data.user_id),
        });
        refetchOrgRoles();
        // refetch current user to get possibly updated roles
        refetchCurrentUser();
      }
    }
  };

  return (
    <Wrapper>
      <NavigationBar />
      <ErrorBoundary>
        <SocketProvider
          url={`${windowEnv.BASE_WEBSOCKET_URL}/notifications/watch`}
          scope={orgId}
          context={NotificationsSocketContext}
          onMessage={handleWebSocketMessage}
          sendPayload={{
            event: 'organization.common',
            routing_key: `organization.${orgId}.#`,
            command: 'listen',
            data: {
              organization_id: orgId,
            },
          }}>
          {userLoading || !user?.id ? (
            <>
              <LoadingBar />
              <LoadingStateContainer>
                <div>{UITranslations.LOADING}</div>
              </LoadingStateContainer>
            </>
          ) : (
            <MainContent id={'main-content'}>
              <AppHeader />
              <Outlet />
            </MainContent>
          )}
        </SocketProvider>
      </ErrorBoundary>
    </Wrapper>
  );
};

export default Orgs;
