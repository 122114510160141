export const artefactQueryKeys = {
  // e.g. ['orgs', 'my-org', 'artefacts']
  all: (orgId: string | undefined) => ['orgs', orgId, 'artefacts'],
  // e.g. ['orgs', 'my-org', 'artefacts', 'list']
  listArtefacts: (orgId: string | undefined) => [...artefactQueryKeys.all(orgId), 'list'],
  // e.g. ['orgs', 'my-org', 'artefacts', 'my-artefact', 'versions', 'my-artefact', { archived: true }]
  listArtefactVersions: (
    orgId: string | undefined,
    artefactId: string | undefined,
    filters?: { archived?: boolean; limit?: number; reference?: string }
  ) => [...artefactQueryKeys.all(orgId), artefactId, 'versions', filters],
};
