import {
  CopyText,
  EditorToggle,
  FormGenerator,
  Input,
  WalCard,
  WalLabel,
  WalModal,
} from '@humanitec/ui-components';
import cronstrue from 'cronstrue';
import { rem } from 'polished';
import {
  ClipboardEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import SectionTile from '@src/components/shared/SectionTile';
import ContainerSwitcher from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/CronJobs/components/ContainerSwitcher/ContainerSwitcher';
import { CronJob } from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/CronJobs/CronJobs';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { UpdateWorkloadChanges, useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { Schedule, WorkloadContainer } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { CommandsOverridesArgumentsType } from '@src/types/commandsOverrides';
import { useWalhallForm } from '@src/utilities/form';
import {
  CRONJOB_HOUR_REGEX,
  CRONJOB_MINUTE_REGEX,
  CRONJOB_MONTH_DAY_REGEX,
  CRONJOB_MONTH_REGEX,
  CRONJOB_WEEK_DAY_REGEX,
} from '@src/utilities/string-utility';

const ContainerInputs = styled.div<{ $selectedContainer?: boolean }>`
  margin: ${units.margin.md} 0;
  ${({ $selectedContainer }) =>
    !$selectedContainer &&
    css`
      display: none;
    `}
`;

const InfoText = styled.div`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.lg};
  margin-bottom: ${units.margin.sm};
`;

const PreviewCard = styled(WalCard)`
  margin-top: ${units.margin.md};
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  flex-direction: row;
`;

const CronJobFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewCardLabel = styled(WalLabel)`
  margin-bottom: 0;
  margin-right: ${units.margin.xs};
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  height: ${rem(32)};
`;

const EditorToggleWrapper = styled.div`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.lg};
`;

const SectionTileWrapper = styled.div`
  margin-top: ${units.margin.md};
`;

interface CronJobFormData {
  name: string;
  minute: string;
  hour: string;
  month: string;
  monthday: string;
  weekday: string;
  containers: Record<
    string,
    {
      'command-wrapper': string[];
      'args-wrapper': string[];
    }
  >;
}

interface CronJobsModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  cronJobs: CronJob[];
  deltaPath: string;
  cronJob?: CronJob;
}

export const CronJobModal = ({ state, cronJob, cronJobs, deltaPath }: CronJobsModalProps) => {
  const [openModal, setOpenModal] = state;
  const [selectedContainerId, setSelectedContainerId] = useState<string>();

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  const uiTranslations = t('UI');

  const { updateWorkload } = useDeltaUtils<Record<string, Schedule>>(deltaPath);
  const { data: containerData } =
    useDeltaUtils<Record<string, WorkloadContainer>>('spec/containers');

  // form
  const methods = useWalhallForm<CronJobFormData>({
    defaultValues: {
      name: cronJob?.name ?? '',
      minute: cronJob?.schedule?.split(' ')[0] ?? '*',
      hour: cronJob?.schedule?.split(' ')[1] ?? '*',
      monthday: cronJob?.schedule?.split(' ')[2] ?? '*',
      month: cronJob?.schedule?.split(' ')[3] ?? '*',
      weekday: cronJob?.schedule?.split(' ')[4] ?? '*',
    },
  });

  const {
    setValue,
    watch,
    formState: { dirtyFields },
  } = methods;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  useEffect(() => {
    if (containerData) {
      setSelectedContainerId(Object.keys(containerData || {})[0]);
    }
  }, [containerData]);

  const watchCronJob = watch(['minute', 'hour', 'monthday', 'month', 'weekday']).join(' ');
  const saveChanges = (formValues: CronJobFormData) => {
    const { name, minute, hour, monthday, month, weekday, containers: formContainers } = formValues;
    const payload: Schedule = {
      schedule: `${minute} ${hour} ${monthday} ${month} ${weekday}`,
      containers: {},
    };

    if (formContainers) {
      Object.entries(formContainers).forEach(([containerKey, containerValue]) => {
        payload.containers[containerKey] = {
          args: containerValue['args-wrapper'],
          command: containerValue['command-wrapper'],
        };
      });
    }

    const deltaToPatch: UpdateWorkloadChanges = [];

    if (cronJob) {
      deltaToPatch.push({
        key: cronJob.name,
        op: 'remove',
      });
    }

    deltaToPatch.push({
      key: name,
      op: cronJob ? 'replace' : 'add',
      value: payload,
    });

    updateWorkload(deltaToPatch);

    setOpenModal(false);
  };

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const [minute, hour, monthday, month, weekday] = e.clipboardData.getData('text')?.split(' ');
    if (minute) setValue('minute', minute);
    if (hour) setValue('hour', hour);
    if (monthday) setValue('monthday', monthday);
    if (month) setValue('month', month);
    if (weekday) setValue('weekday', weekday);
  };

  const translateCronJob = () => {
    try {
      return cronstrue.toString(watchCronJob, { use24HourTimeFormat: true });
    } catch {
      return sectionsTranslations.CRONJOB_INVALID;
    }
  };

  const handleEditorChange = useCallback(
    (
      containerId: string,
      type: CommandsOverridesArgumentsType,
      value: string[] | undefined,
      shouldDirty?: boolean
    ) => {
      setValue(`containers.${containerId}.${type}-wrapper`, value || [], {
        shouldDirty: shouldDirty || false,
      });
    },
    [setValue]
  );

  const containerMapping: Record<string, { command: string[]; args: string[] }> = useMemo(
    () =>
      Object.entries(containerData || {}).reduce(
        (prevValue, [containerId, container]) => ({
          ...prevValue,
          [containerId]: {
            command:
              (cronJob ? cronJob.containers[containerId]?.command : container?.command) || [],
            args: (cronJob ? cronJob.containers[containerId]?.args : container?.args) || [],
          },
        }),
        {}
      ),
    [containerData, cronJob]
  );

  useEffect(() => {
    Object.entries(containerMapping).forEach(([id, val]) => {
      if (val.args) {
        handleEditorChange(id, 'args', val.args);
      }
      if (val.command) {
        handleEditorChange(id, 'command', val.command);
      }
    });
  }, [containerMapping, handleEditorChange]);

  return (
    <FormProvider {...methods}>
      <WalModal
        openState={[openModal, setOpenModal]}
        size={'large'}
        handleFormSubmit={saveChanges}
        title={
          cronJob
            ? draftModeActive
              ? sectionsTranslations.EDIT_CRONJOB_SCHEDULE
              : sectionsTranslations.VIEW_CRONJOB_SCHEDULE
            : sectionsTranslations.ADD_CRONJOB_SCHEDULE
        }
        content={
          <>
            <Input
              name={'name'}
              label={sectionsTranslations.SCHEDULE_NAME}
              required
              readonly={!draftModeActive}
              standardValidation={[
                { type: 'id' },
                {
                  type: 'existingId',
                  ids: cronJobs?.filter((c) => c.name !== cronJob?.name).map((c) => c.name),
                },
              ]}
            />
            <InfoText>{sectionsTranslations.CRONJOB_MODAL_INFO_TEXT}</InfoText>
            <CronJobFormWrapper>
              <FormGenerator
                errorMoreInformationLink={
                  'https://developer.humanitec.com/integration-and-extensions/workload-profiles/features/#schedules'
                }
                fields={[
                  {
                    type: 'input',
                    props: {
                      name: 'minute',
                      label: sectionsTranslations.MINUTE,
                      readonly: !draftModeActive,
                      onPaste: handlePaste,
                      validate: {
                        validateMinute: (value: string) =>
                          CRONJOB_MINUTE_REGEX.test(value) ||
                          sectionsTranslations.CRONJOB_MINUTE_INVALID,
                      },
                    },
                  },
                  {
                    type: 'input',
                    props: {
                      name: `hour`,
                      label: sectionsTranslations.HOUR,
                      readonly: !draftModeActive,
                      maxLength: 63,
                      onPaste: handlePaste,
                      validate: {
                        validateHour: (value: string) =>
                          CRONJOB_HOUR_REGEX.test(value) ||
                          sectionsTranslations.CRONJOB_HOUR_INVALID,
                      },
                    },
                  },
                  {
                    type: 'input',
                    props: {
                      name: `monthday`,
                      label: sectionsTranslations.DAY,
                      readonly: !draftModeActive,
                      maxLength: 63,
                      onPaste: handlePaste,
                      validate: {
                        validateMonthDay: (value: string) =>
                          CRONJOB_MONTH_DAY_REGEX.test(value) ||
                          sectionsTranslations.CRONJOB_MONTH_DAY_INVALID,
                      },
                    },
                  },
                  {
                    type: 'input',
                    props: {
                      name: `month`,
                      label: sectionsTranslations.MONTH,
                      readonly: !draftModeActive,
                      maxLength: 63,
                      onPaste: handlePaste,
                      validate: {
                        validateMonth: (value: string) =>
                          CRONJOB_MONTH_REGEX.test(value) ||
                          sectionsTranslations.CRONJOB_MONTH_INVALID,
                      },
                    },
                  },
                  {
                    type: 'input',
                    props: {
                      name: `weekday`,
                      label: sectionsTranslations.WEEK,
                      readonly: !draftModeActive,
                      maxLength: 63,
                      onPaste: handlePaste,
                      validate: {
                        validateWeekDay: (value: string) =>
                          CRONJOB_WEEK_DAY_REGEX.test(value) ||
                          sectionsTranslations.CRONJOB_WEEK_DAY_INVALID,
                      },
                    },
                  },
                ]}
              />
              <CopyWrapper>
                <CopyText text={watchCronJob} iconSize={14} />
              </CopyWrapper>
            </CronJobFormWrapper>
            <PreviewCard cardStyle={'transparent'}>
              <PreviewCardLabel>{sectionsTranslations.CRONJOB_OUTPUT}</PreviewCardLabel>
              {translateCronJob()}
            </PreviewCard>
            <SectionTileWrapper>
              <SectionTile
                title={sectionsTranslations.CONTAINER_OVERRIDES}
                infoPopup={
                  draftModeActive
                    ? {
                        text: sectionsTranslations.CONTAINER_OVERRIDES_INFO_TEXT,
                        link: sectionsTranslations.CONTAINER_CONFIG_INFO_LINK,
                        width: 500,
                      }
                    : {
                        text: sectionsTranslations.CONTAINER_OVERRIDES_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
                        link: sectionsTranslations.CONTAINER_CONFIG_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
                        width: 500,
                      }
                }
                editable={draftModeActive}>
                <ContainerSwitcher
                  currentContainerIdState={[selectedContainerId, setSelectedContainerId]}
                  readonly
                  transparentBackground
                  hideTitle
                  noMarginBottom
                />
                {Object.keys(containerData || {}).length > 0 ? (
                  Object.keys(containerData || {}).map((containerId) => {
                    return (
                      <ContainerInputs
                        key={containerId}
                        $selectedContainer={containerId === selectedContainerId}>
                        <EditorToggleWrapper>
                          <EditorToggle
                            title={sectionsTranslations.COMMANDS}
                            name={`containers.${containerId}.command`}
                            value={containerMapping?.[containerId]?.command ?? []}
                            onSave={(value) =>
                              handleEditorChange(containerId, 'command', value, true)
                            }
                            readonly={!draftModeActive}
                            height={'80px'}
                            emptyStateText={sectionsTranslations.CONTAINER_OVERRIDES_NO_COMMANDS}
                          />
                        </EditorToggleWrapper>
                        <EditorToggleWrapper>
                          <EditorToggle
                            title={sectionsTranslations.ARGUMENTS}
                            name={`containers.${containerId}.args`}
                            value={containerMapping?.[containerId]?.args ?? []}
                            onSave={(value) => handleEditorChange(containerId, 'args', value, true)}
                            readonly={!draftModeActive}
                            height={'80px'}
                            emptyStateText={sectionsTranslations.CONTAINER_OVERRIDES_NO_ARGUMENTS}
                          />
                        </EditorToggleWrapper>
                      </ContainerInputs>
                    );
                  })
                ) : (
                  <WalCard cardStyle={'transparent'}>
                    <span className={'txt-sm'}>{sectionsTranslations.CRONJOB_NO_CONTAINERS}</span>
                  </WalCard>
                )}
              </SectionTile>
            </SectionTileWrapper>
          </>
        }
        actions={{
          main: {
            props: {
              type: 'submit',
              disabled: cronJob && !Boolean(Object.keys(dirtyFields).length),
            },
            hideButton: !draftModeActive,
            text: uiTranslations.SAVE_CHANGES,
          },
          cancel: {
            text: draftModeActive ? uiTranslations.CANCEL : uiTranslations.CLOSE,
          },
        }}
      />
    </FormProvider>
  );
};

export default CronJobModal;
