import { WalTable } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useGroupsQuery } from '@src/hooks/react-query/groups/queries/useGroupsQuery';
import { useUsersInGroupQuery } from '@src/hooks/react-query/groups/queries/useUsersInGroupQuery';
import { MatchParams } from '@src/models/routing';
import { generateOrgMembersURL } from '@src/utilities/navigation';
import { capitalize } from '@src/utilities/string-utility';

import OrgRoleModal from '../../components/role-modals/OrgRoleModal/OrgRoleModal';
import { AddGroupModal } from './components/AddGroupModal';

const UsersColumn = ({ userId }: { userId: string }) => {
  const { data: users } = useUsersInGroupQuery(userId);
  return users?.map((user) => user.name).join(', ');
};

export const Groups = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  // React Query
  const { data: groups } = useGroupsQuery();

  // i18n
  const { t } = useTranslation('orgMembers');
  const orgMembersTranslation = t('ORG_MEMBERS_LIST').ORG_ROLE_MODAL;
  return (
    <div>
      <AddGroupModal />
      <WalTable
        showCount
        caption={'Groups'}
        rows={groups?.map((group) => ({ data: group })) ?? []}
        columns={[
          {
            prop: 'name',
            label: 'Name',
            template: ({ data }) => (
              <Link to={generateOrgMembersURL(orgId, 'groups', data.id, 'users')}>{data.name}</Link>
            ),
          },
          {
            prop: 'members',
            label: 'Members',
            template: ({ data }) => <UsersColumn userId={data.id} />,
          },
          {
            prop: 'role',
            label: 'Organization role',
            template: ({ data }) =>
              data.role === 'orgViewer' ? orgMembersTranslation.ORG_VIEWER : capitalize(data.role),
          },
          {
            prop: 'actions',
            template: ({ data }) => (
              <OrgRoleModal
                mode={{
                  name: 'change',
                  buttonVariant: 'small',
                  user: data,
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
