import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../userQueryKeys';
import useGetCurrentUserQuery from './useGetCurrentUserQuery';

export type PermissionKeys =
  | 'createApplication'
  | 'readArtefactVersions'
  | 'contributeImages'
  | 'deleteImages'
  | 'manageEnvironmentTypes'
  | 'manageUsers'
  | 'read'
  | 'manageResources';

export const useUserPermissionsQuery = (userIdParam?: string, orgIdParam?: string) => {
  const { orgId: routerOrgId } = useParams<keyof MatchParams>() as MatchParams;
  const orgId = orgIdParam || routerOrgId;

  const { data: currentUser } = useGetCurrentUserQuery();
  const userId = userIdParam || currentUser?.id;

  return useQuery({
    queryKey: userQueryKeys.userPermissions(orgId, userId),
    queryFn: () =>
      makeRequest<{ objects: Record<string, { permissions: PermissionKeys[] }> }>(
        'GET',
        `/orgs/${orgId}/users/${userId}/perms`
      ),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};

export const useUserOrgPermissionsQuery = (orgIdParam?: string) => {
  const { orgId: routerOrgId } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = orgIdParam || routerOrgId;

  const { data: userPermissions, isSuccess: isUserPermissionsLoaded } = useUserPermissionsQuery(
    undefined,
    orgId
  );

  return {
    isUserPermissionsLoaded,
    userOrgPermissions: userPermissions?.objects?.[`/orgs/${orgId}`]?.permissions,
  };
};

export const useUserAppPermissionsQuery = (appIdParam?: string) => {
  const { orgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;
  const appId = appIdParam || routerAppId;

  // React Query
  const { data: userPermissions } = useUserPermissionsQuery();

  return userPermissions?.objects[`/orgs/${orgId}/apps/${appId}`]?.permissions;
};
