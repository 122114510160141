import {
  Button,
  ComboSelect,
  ComboSelectMenuItem,
  ComboSelectProps,
  DropdownItem,
  Input,
  WalDropdownMenu,
} from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PipelineCriteria } from '@src/models/pipeline';
import { ResourceCriteria } from '@src/models/resources';
import { cl } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { underscore } from '@src/utilities/string-utility';

interface NewCriteriaRowEntryProps<T> {
  label: string;
  criteriaKey: keyof T;
  index: number;
  criteriaOptions: DropdownItem<keyof T>[];
  onDeleteCriteria: () => void;
  onSelectDropdownItem: (id: string, item: DropdownItem<keyof T>) => void;
  showSelectDropdown?: boolean;
  itemsMap: Partial<Record<keyof T, ComboSelectMenuItem<string>[]>>;
  showDelete?: boolean;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr 50px;
  align-items: flex-start;
  margin-top: ${units.margin.md};
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ListItemText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const NewCriteriaRowEntry = <T = PipelineCriteria | ResourceCriteria,>({
  label,
  criteriaKey,
  criteriaOptions,
  onSelectDropdownItem,
  onDeleteCriteria,
  itemsMap,
  showSelectDropdown,
  showDelete,
  index,
}: NewCriteriaRowEntryProps<T>) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('UI');

  const commonComboSelectProps: Partial<ComboSelectProps> = {
    mode: 'set-selected-value',
    standardValidation: [{ type: 'id', exceptions: ['*', ''] }],
  };

  const handleSelectDropdownItem = (id: string, item: DropdownItem<keyof T>) => {
    onSelectDropdownItem(id, item);
  };

  return (
    <Grid key={criteriaKey as string} role={'group'} aria-labelledby={criteriaKey as string}>
      <WalDropdownMenu
        id={criteriaKey as string}
        hideLabel
        buttonVariant={'input'}
        fullWidth
        defaultValue={label}
        items={criteriaOptions.map((dropdownItem) => {
          return {
            id: dropdownItem.id,
            label: dropdownItem.label,
            value: dropdownItem.value,
            previousValue: underscore(dropdownItem.label.toLowerCase()),
          };
        })}
        onItemClick={handleSelectDropdownItem}
      />
      <div className={'mx-md mt-sm'}>{translations.IS}</div>
      {!showSelectDropdown ? (
        <Input
          name={`criteria.${index}.value`}
          hideLabel
          placeholder={label}
          standardValidation={
            criteriaKey === 'res_id'
              ? [{ type: 'resourceId', exceptions: ['*', ''] }]
              : [{ type: 'id', exceptions: ['*', ''] }]
          }
        />
      ) : (
        <ComboSelect
          {...cl('pt-0')}
          hideLabel
          key={criteriaKey as string}
          inputPlaceholder={label}
          name={`criteria.${index}.value`}
          {...commonComboSelectProps}
          items={itemsMap[criteriaKey] as ComboSelectMenuItem[]}
        />
      )}
      {showDelete ? (
        <Button
          {...cl('ml-md')}
          iconLeft={{ name: 'delete' }}
          variant={'secondary'}
          onClick={() => onDeleteCriteria()}
        />
      ) : (
        <div />
      )}
    </Grid>
  );
};

export default NewCriteriaRowEntry;
