import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { PageContainer } from '@src/styles/layout/PageContainer';

const TRANSITION_DELAY = '300ms';

export const Content = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ disableOverflow }) =>
    !disableOverflow &&
    css`
      overflow: auto;
    `}
  &.settings-enter {
    opacity: 0;
  }
  &.settings-enter-active {
    opacity: 1;
    transition: all ease-in ${TRANSITION_DELAY};
  }

  &.settings-exit {
    display: none;
  }
  &.settings-exit-active {
    opacity: 0;
    transition: all ease-in ${TRANSITION_DELAY};
  }
`;

const SectionsSettingWrapper = ({
  children,
  rightContent,
  subtitles,
}: {
  children: ReactNode;
  rightContent?: ReactNode;
  subtitles?: string[];
}) => {
  return (
    <Content tabIndex={-1}>
      <PageHeader rightContent={rightContent} subtitles={subtitles} />
      {children}
    </Content>
  );
};

export default SectionsSettingWrapper;
