import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AppHeader from '@src/components/shared/AppHeader/AppHeader';
import NavigationBar from '@src/components/shared/NavigationBar/NavigationBar';
import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import WorkflowLayout from '@src/components/shared/WorkflowLayout';
import { PageContainer } from '@src/styles/layout/PageContainer';

import ProfileSettingsMain from './containers/ProfileSettingsMain';
import ProfileSettingsSidebar from './containers/ProfileSettingsSidebar';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Container = styled(PageContainer)`
  flex-direction: column;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ProfileSettings = () => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('ACCOUNT_SETTINGS');

  return (
    <Wrapper>
      <NavigationBar />
      <MainContent>
        <AppHeader />
        <Container>
          <PageHeader customHeading={settingsTranslations.PAGE_TITLE} />
          <WorkflowLayout
            mainContent={<ProfileSettingsMain />}
            sidebarContent={<ProfileSettingsSidebar />}
          />
        </Container>
      </MainContent>
    </Wrapper>
  );
};

export default ProfileSettings;
