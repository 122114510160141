import { has } from 'lodash';
import { rem } from 'polished';
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import { ErrorOption, useFormContext } from 'react-hook-form';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { InputExtraError } from '@src/components/shared-types/input';

import WalArgumentsInput, { ArgumentsInputProps } from '../../base/ArgumentsInput/ArgumentsInput';
import { Button, ButtonProps } from '../../base/Button/Button';
import { Checkbox, CheckboxProps } from '../../base/Checkbox/Checkbox';
import { Input, INPUT_LABEL_MARGIN } from '../../base/Input/Input';
import {
  DisplayFieldErrors,
  InputDescription,
  SharedInputProps,
} from '../../base/Input/InputWrapper';
import { MonacoInput, MonacoInputProps } from '../../base/MonacoInput/MonacoInput';
import { Spacer } from '../../base/Spacer/Spacer';
import { Textarea, TextareaProps } from '../../base/Textarea/Textarea';
import { Toggle, ToggleProps } from '../../base/Toggle/Toggle';
import ComboSelect, { ComboSelectProps } from '../ComboSelect/ComboSelect';
import WalDropdownMenu, { WalDropdownMenuProps } from '../Menu/DropdownMenu/DropdownMenu';
import MultiSelectMenu, { MultiSelectMenuProps } from '../Menu/MultiSelectMenu/MultiSelectMenu';

const FormSpacer = styled(Spacer)<{ addLabelMargin?: boolean }>`
  ${({ addLabelMargin }) =>
    addLabelMargin &&
    css`
      padding-top: ${INPUT_LABEL_MARGIN};
    `}
`;

const formCss = (
  numberOfFields: number,
  gridTemplate?: FlattenSimpleInterpolation,
  hasSubmitButton?: boolean,
  separatorStyle?: boolean
) => css`
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: ${Array(numberOfFields)
      .fill(0)
      .map(
        (_: number, i: number) =>
          `1fr${
            separatorStyle && (numberOfFields - 1 !== i || hasSubmitButton) ? ` ${rem(20)}` : ''
          }`
      )
      .join(' ')} ${hasSubmitButton ? 'auto' : ''};

  align-items: flex-start;
  ${!separatorStyle &&
  css`
    grid-column-gap: ${rem(1)};
  `}
  width: 100%;
  ${gridTemplate};
`;

const borderCss = ({
  firstField,
  lastField,
  hasSubmitButton,
  hasAdjacentToggle,
  formVariant,
}: FormElementProps) => css`
  ${firstField
    ? css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `
    : lastField && !hasSubmitButton
      ? css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
      : css`
          border-radius: 0;
        `}

  ${hasAdjacentToggle === 'all'
    ? css`
        border-radius: ${rem(4)};
      `
    : hasAdjacentToggle === 'left'
      ? css`
          border-top-left-radius: ${rem(4)};
          border-bottom-left-radius: ${rem(4)};
        `
      : hasAdjacentToggle === 'right' &&
        css`
          border-top-right-radius: ${rem(4)};
          border-bottom-right-radius: ${rem(4)};
        `}

      ${formVariant === 'separators' &&
  css`
    border-radius: ${rem(4)};
  `}
`;

interface WrapperStyle {
  numberOfFields: number;
  gridTemplate?: FlattenSimpleInterpolation;
  formVariant: 'default' | 'separators';
  hasSubmitButton?: boolean;
}
const Form = styled.form<WrapperStyle>`
  ${({ numberOfFields, gridTemplate, formVariant, hasSubmitButton }) =>
    formCss(numberOfFields, gridTemplate, hasSubmitButton, formVariant === 'separators')}
`;

const Wrapper = styled.div<WrapperStyle>`
  ${({ numberOfFields, gridTemplate, formVariant, hasSubmitButton }) =>
    formCss(numberOfFields, gridTemplate, hasSubmitButton, formVariant === 'separators')}
`;

interface FormElementProps {
  firstField: boolean;
  lastField: boolean;
  hasSubmitButton: boolean;
  hasAdjacentToggle: false | 'all' | 'right' | 'left';
  formVariant: 'default' | 'separators';
}

const FormInput = styled(Input)<FormElementProps>`
  .input-element {
    ${(props) => borderCss(props)}
  }
`;

const FormTextarea = styled(Textarea)<FormElementProps>`
  .input-element {
    ${(props) => borderCss(props)}
  }
`;

const FormArgsInput = styled(WalArgumentsInput)<FormElementProps>`
  .input-element {
    ${(props) => borderCss(props)};
  }
`;

const FormDropdown = styled(WalDropdownMenu)<FormElementProps>`
  button {
    ${(props) => borderCss(props)};
  }
`;

const FormMultiSelect = styled(MultiSelectMenu)<FormElementProps & { hideLabels?: boolean }>`
  button {
    ${(props) => borderCss(props)};
  }
`;

const FormMonacoInput = styled(MonacoInput)<FormElementProps>`
  .input-element {
    ${(props) => borderCss(props)};
  }
`;

const FormComboSelect = styled(ComboSelect)<FormElementProps>`
  .input-element {
    ${(props) => borderCss(props)};
  }
`;

const FormCheckbox = styled(Checkbox)<{ hideLabel?: boolean }>`
  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${INPUT_LABEL_MARGIN};
    `}
  align-self: center;
  justify-self: center;
`;

const FormToggle = styled(Toggle)<{ hideLabel?: boolean }>`
  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${INPUT_LABEL_MARGIN};
    `}
  align-self: center;
  justify-self: center;
`;

const SubmitButton = styled(Button)<{ addBorderRadius?: boolean; hideLabel?: boolean }>`
  ${({ addBorderRadius }) =>
    !addBorderRadius &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
  margin: 0;

  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${INPUT_LABEL_MARGIN};
    `}
`;

interface BaseDefinition {
  hide?: boolean | { maintainSpace?: boolean };
}

export interface InputDefinition extends BaseDefinition {
  type: 'input';
  props: SharedInputProps;
}

export interface TextareaDefinition extends BaseDefinition {
  type: 'textarea';
  props: TextareaProps;
}

export interface MonacoInputDefinition extends BaseDefinition {
  type: 'monaco-input';
  props: MonacoInputProps;
}

interface DropdownDefinition extends BaseDefinition {
  type: 'dropdown';
  props: WalDropdownMenuProps<any>;
}

interface ArgsInputDefinition extends BaseDefinition {
  type: 'args-input';
  props: ArgumentsInputProps;
}

interface MultiSelectDefinition extends BaseDefinition {
  type: 'multiselect';
  props: MultiSelectMenuProps;
}

interface ToggleDefinition extends BaseDefinition {
  type: 'toggle';
  props: ToggleProps;
}

interface CheckboxDefinition extends BaseDefinition {
  type: 'checkbox';
  props: CheckboxProps;
}

interface ComboSelectDefinition extends BaseDefinition {
  type: 'combo-select';
  props: ComboSelectProps;
}

interface SpacerDefinition extends BaseDefinition {
  type: 'spacer';
  props: {
    name: string;
    component: ReactElement;
  };
}

export type FormFieldGeneratorDefinition =
  | DropdownDefinition
  | InputDefinition
  | ArgsInputDefinition
  | MultiSelectDefinition
  | MonacoInputDefinition
  | TextareaDefinition
  | ToggleDefinition
  | CheckboxDefinition
  | ComboSelectDefinition
  | SpacerDefinition;

interface FormGeneratorProps {
  fields: FormFieldGeneratorDefinition[];
  formVariant?: 'default' | 'separators';
  submitButton?: ButtonProps;
  onSubmit?: (formValues: any) => void;
  size?: 'small' | 'medium' | 'large';
  gridTemplate?: FlattenSimpleInterpolation;
  className?: string;
  // function that it is triggered when the user clicks outside the form
  clickedOutside?: () => void;
  formSubmitting?: boolean;
  /** Hides labels for all inputs */
  hideLabels?: boolean;
  description?: string;
  dataTestId?: string;
  /** more information link for error */
  errorMoreInformationLink?: string;
  /** Sometimes an extra error(one that is not associated with any of the inputs) needs to be displayed */
  extraErrors?: InputExtraError[];
}

interface FormFieldProps {
  fields: FormFieldGeneratorDefinition[];
  size: 'small' | 'medium' | 'large';
  hasSubmitButton: boolean;
  hideLabels: boolean;
  formVariant: 'default' | 'separators';
  /** Description for whole form */
  description?: string;
}

const RenderField = ({
  field,
  commonProps,
  size,
  hideLabels,
  description,
}: {
  field: FormFieldGeneratorDefinition;
  commonProps: FormElementProps;
  size: 'small' | 'medium' | 'large';
  hideLabels: boolean;
  /** Description for whole form */
  description?: string;
}) => {
  switch (field.type) {
    case 'dropdown':
      return (
        <FormDropdown
          {...field.props}
          {...commonProps}
          menuSize={'parent'}
          buttonVariant={'input'}
          fullWidth
          buttonSize={size}
          hideLabel={hideLabels}
          hideError
        />
      );
    case 'args-input':
      return <FormArgsInput {...field.props} {...commonProps} size={size} />;
    case 'multiselect':
      return <FormMultiSelect {...field.props} {...commonProps} buttonSize={size} />;
    case 'monaco-input':
      return (
        <FormMonacoInput
          {...field.props}
          {...commonProps}
          size={size}
          hideLabel={field.props.hideLabel || hideLabels}
          hideError
        />
      );
    case 'toggle':
      return (
        <FormToggle
          {...field.props}
          {...commonProps}
          size={size === 'large' ? 'medium' : 'small'}
          hideLabel={hideLabels}
        />
      );
    case 'combo-select':
      return <FormComboSelect {...field.props} {...commonProps} inputSize={size} hideError />;
    case 'checkbox':
      return <FormCheckbox {...field.props} {...commonProps} />;
    case 'spacer':
      return field.props.component;
    case 'input':
      return (
        <FormInput
          {...field.props}
          {...commonProps}
          description={description ? '' : field.props.description}
          size={size}
          hideLabel={hideLabels}
          hideError
        />
      );
    case 'textarea':
      return (
        <FormTextarea
          {...field.props}
          {...commonProps}
          description={description ? '' : field.props.description}
          size={size}
          hideLabel={hideLabels}
          hideError
        />
      );
    default:
      return <></>;
  }
};

const FormFields = ({
  fields,
  size,
  hasSubmitButton,
  hideLabels,
  formVariant,
  description,
}: FormFieldProps) => (
  <>
    {fields.map((field, index) => {
      const adjacentTypesArray = ['toggle', 'checkbox', 'spacer'];
      const isFirstField = index === 0;
      const isLastField = index === fields.length - 1;

      const indexMinusOne = fields?.[index - 1]?.type;
      const indexPlusOne = fields?.[index + 1]?.type;
      const hasToggleOnLeft = Boolean(
        index !== 0 && indexMinusOne && adjacentTypesArray.includes(indexMinusOne)
      );
      const hasToggleOnRight = Boolean(
        index + 1 < fields.length && indexPlusOne && adjacentTypesArray.includes(indexPlusOne)
      );
      const hasAdjacentToggle =
        hasToggleOnLeft && hasToggleOnRight
          ? 'all'
          : hasToggleOnLeft
            ? 'left'
            : hasToggleOnRight && 'right';

      const commonProps: FormElementProps = {
        firstField: isFirstField,
        lastField: isLastField,
        hasSubmitButton,
        hasAdjacentToggle,
        formVariant,
      };
      const spacer = (
        <FormSpacer dataTestId={'field-spacer'} addLabelMargin={!Boolean(hideLabels)} />
      );
      if (typeof field.hide !== 'boolean' && field.hide?.maintainSpace) return spacer;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <RenderField
            field={field}
            commonProps={commonProps}
            size={size}
            hideLabels={hideLabels}
            description={description}
          />
          {formVariant === 'separators' &&
            (!isLastField || (isLastField && hasSubmitButton)) &&
            spacer}
        </Fragment>
      );
    })}
  </>
);

interface SubmitButtonProps {
  submitButton: ButtonProps;
  size: 'small' | 'medium' | 'large';
  formSubmitting?: boolean;
  /** By default, the left side is square. Set this to true to make the corners rounded */
  addBorderRadius?: boolean;
  hideLabels?: boolean;
}

const SubmitButtonElement = ({
  formSubmitting,
  submitButton,
  size,
  addBorderRadius,
  hideLabels,
}: SubmitButtonProps) => (
  <SubmitButton
    {...submitButton}
    loading={formSubmitting}
    disabled={formSubmitting || !!submitButton.disabled}
    size={size}
    hideLabel={hideLabels}
    addBorderRadius={addBorderRadius}
    type={'submit'}
  />
);

export const FormGenerator = ({
  fields,
  submitButton,
  onSubmit,
  size = 'medium',
  gridTemplate,
  className,
  clickedOutside,
  formSubmitting,
  hideLabels,
  description,
  dataTestId,
  formVariant = 'default',
  errorMoreInformationLink,
  extraErrors = [],
}: FormGeneratorProps) => {
  // Component state
  const [filteredFields, setFilteredFields] = useState(fields.filter((field) => !field.hide));

  // Form
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const node: any = useRef(null);

  useEffect(() => {
    setFilteredFields(
      fields.filter(
        (field) =>
          !field.hide || (typeof field.hide !== 'boolean' && field.hide && field.hide.maintainSpace)
      )
    );
  }, [fields]);

  useEffect(() => {
    if (!node.current) return;
    /**
     * Handles the user's click depending on whether it is inside or outside of the component.
     *
     * @param event the click event
     */
    const handleClickOutside = (event: Event) => {
      if (node.current.contains(event.target)) {
        // user clicked inside the submit input component
        return;
      } else {
        if (clickedOutside) {
          clickedOutside();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clickedOutside]);

  const isLastItemToggle = filteredFields[filteredFields.length - 1]?.type === 'toggle';

  const descriptionElement = description && <InputDescription>{description}</InputDescription>;

  const errorElement = [
    ...fields.map((field) => ({
      name: field.props.name,
      label: (field.props as SharedInputProps).label || '',
    })),
    ...extraErrors,
  ].filter(({ name }) => name && has(errors, name)).length > 0 &&
    Object.keys(errors || {}).length > 0 && (
      <DisplayFieldErrors
        fieldErrors={errors as ErrorOption}
        oneField={filteredFields.length === 1}
        testId={dataTestId}
        fields={filteredFields}
        errorMoreInformationLink={errorMoreInformationLink}
        extraErrors={extraErrors}
      />
    );

  const formInner = (
    <>
      <FormFields
        fields={filteredFields}
        size={size}
        hasSubmitButton={Boolean(submitButton)}
        hideLabels={Boolean(hideLabels)}
        formVariant={formVariant}
        description={description}
      />
      {submitButton && (
        <SubmitButtonElement
          formSubmitting={formSubmitting || isSubmitting}
          size={size}
          hideLabels={hideLabels}
          submitButton={submitButton}
          addBorderRadius={isLastItemToggle || formVariant === 'separators'}
        />
      )}
    </>
  );

  return onSubmit ? (
    <Form
      data-testid={dataTestId}
      className={className}
      formVariant={formVariant}
      numberOfFields={filteredFields.length}
      gridTemplate={gridTemplate}
      onSubmit={handleSubmit(onSubmit)}
      hasSubmitButton={Boolean(submitButton)}
      ref={node}>
      {formInner}
      {errorElement}
      {descriptionElement}
    </Form>
  ) : (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      numberOfFields={filteredFields.length}
      gridTemplate={gridTemplate}
      hasSubmitButton={Boolean(submitButton)}
      formVariant={formVariant}>
      {formInner}
      {errorElement}
      {descriptionElement}
    </Wrapper>
  );
};
