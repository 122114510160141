import { ReactNode } from 'react';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

import { WalCard } from '../../base/Card/Card';

const Card = styled(WalCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

interface EmptyStateCardProps {
  children: ReactNode;
  className?: string;
}

/**
 * Specific styles for empty state. Use when there are no entries defined.
 */
const EmptyStateCard = ({ children, className }: EmptyStateCardProps) => {
  return (
    <Card cardStyle={'transparent'} className={className}>
      {children}
    </Card>
  );
};

export default EmptyStateCard;
