import { Button, InfoPopup } from '@humanitec/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConnectCIPipelineModal from '@src/containers/Orgs/Artefacts/components/ConnectCIPipelineModal';
import {
  SectionTopBarSearchField,
  SectionTopBarTitle,
  SectionTopBarWrapper,
} from '@src/containers/Orgs/components/SectionTopBarWrapper';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { Artefact } from '@src/models/artefact';

import ManageArtefactsList from './containers/ManageArtefactsList';

const ManageArtefacts = () => {
  // Context

  // react query
  const { data: artefactsData } = useArtefactsQuery();
  const artefacts = useMemo(() => artefactsData || [], [artefactsData]);

  // Component state
  const [filteredArtefacts, setFilteredArtefacts] = useState<Artefact[]>(artefacts);
  const [openConnectCIPipelineModal, setOpenConnectCIPipelineModal] = useState<boolean>(false);

  // i18n
  const { t } = useTranslation();
  const manageImagesTranslations = t('ACCOUNT_SETTINGS').MANAGE_IMAGES;

  // RBAC
  const canConnectCIPipeline = useRBAC('connectCIPipeline');

  useEffect(() => {
    setFilteredArtefacts(artefacts);
  }, [artefacts]);

  const filterArtefacts = (value: string) => {
    setFilteredArtefacts(
      [...artefacts].filter((artefact) => artefact.name?.includes(value.toLowerCase()))
    );
  };

  return (
    <SectionsSettingWrapper
      rightContent={
        artefacts.length > 1 && (
          <SectionTopBarSearchField
            name={'filter-images'}
            placeholder={manageImagesTranslations.IMAGES_FILTER_PLACEHOLDER}
            onChange={filterArtefacts}
          />
        )
      }>
      {canConnectCIPipeline && (
        <SectionTopBarWrapper>
          <SectionTopBarTitle>
            {manageImagesTranslations.ADD_IMAGES}
            <InfoPopup
              text={manageImagesTranslations.ADD_IMAGES_DESCRIPTION}
              position={'bottom'}
              moreInformationLink={manageImagesTranslations.ADD_IMAGES_INFO_LINK}
            />
          </SectionTopBarTitle>
          <Button
            variant={'secondary'}
            size={'large'}
            onClick={() => setOpenConnectCIPipelineModal(true)}>
            {manageImagesTranslations.CONNECT_CI_PIPELINE}
          </Button>
        </SectionTopBarWrapper>
      )}

      <ManageArtefactsList artefacts={filteredArtefacts} />
      <ConnectCIPipelineModal
        openState={[openConnectCIPipelineModal, setOpenConnectCIPipelineModal]}
      />
    </SectionsSettingWrapper>
  );
};

export default ManageArtefacts;
