import { Button, Input, Toggle, WalDropdownMenu } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { VolumeMount } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const ButtonContainer = styled.div`
  margin-top: ${units.margin.xl};
  display: flex;
`;

const SecretFieldWrapper = styled.div`
  display: flex;
`;

const LeftButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const FieldDropdown = styled(WalDropdownMenu)`
  label {
    color: ${({ theme }) => theme.color.text};
  }
`;

const FieldInput = styled(Input)`
  textarea,
  input {
    margin-top: ${units.margin.sm};
    margin-bottom: ${units.margin.sm};
  }
  label {
    margin-top: ${units.margin.sm};
    color: ${({ theme }) => theme.color.text};
  }
`;

const FieldTitle = styled.div`
  font-size: ${units.fontSize.sm};
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.sm};
`;

export interface VolumeMountViewAddEditProps {
  /* list of existing mount paths to validate against because you can't add duplicate mount paths */
  existingMountPaths: string[];
  /* list of external volume resources */
  volumeResources: ResourceDependency[];
  /* volume mount to edit (required only in edit mode) */
  volumeMount?: VolumeMount & { mount_path: string };
  /* method called when the cancel button is clicked */
  onCancel?: () => void;
  /* switches to one of 3 possible mode */
  mode?: 'add' | 'edit' | 'view';
  deltaPath: string;
}

const VolumeMountViewAddEdit = ({
  existingMountPaths,
  volumeResources,
  volumeMount,
  onCancel,
  mode = 'view',
  deltaPath,
}: VolumeMountViewAddEditProps) => {
  // Form Hooks
  const methods = useWalhallForm();
  const { trigger, watch, handleSubmit, setValue, reset } = methods;
  const formValues = watch();

  // Component state
  const [selectedVolumeResource, setSelectedVolumeResource] = useState<ResourceDependency>();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const sectionsTranslations = t('VIEW_MODULE').VOLUME_MOUNTS;

  // Delta actions
  const { updateWorkload } = useDeltaUtils(deltaPath);

  // constants
  const readonly = mode === 'view';

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  useEffect(() => {
    const splitId = volumeMount?.id.split('.');
    const theResource = volumeResources.find((vr) => splitId?.length && vr.id === splitId[1]);

    setSelectedVolumeResource(theResource);
  }, [volumeMount, volumeResources]);

  useEffect(() => {
    setValue('id', selectedVolumeResource?.id || volumeResources[0]?.id);
  }, [selectedVolumeResource, volumeResources, setValue]);

  useEffect(() => {
    // Set initial state of Toggle
    setValue('read_only', !!volumeMount?.read_only);
  }, [volumeMount?.read_only, setValue]);

  const updateExisting = async () => {
    if (volumeMount) {
      if (!(await trigger())) return;

      const newMountPath = formValues.mount_path;

      delete formValues.mount_path;
      delete formValues.id;

      const volumeMountCopy: VolumeMount & { mount_path?: string } = { ...volumeMount };
      delete volumeMountCopy.mount_path;

      const newMount = {
        ...volumeMountCopy,
        ...formValues,
      };

      // Replace in delta
      if (volumeMount.mount_path !== newMountPath) {
        updateWorkload([
          {
            key: volumeMount.mount_path,
            op: 'remove',
          },
          {
            key: newMountPath,
            op: 'add',
            value: newMount,
          },
        ]);
      } else {
        updateWorkload([
          {
            key: newMountPath,
            op: 'replace',
            value: newMount,
          },
        ]);
      }
      onCancel?.();
    }
  };

  const createNew = () => {
    const key = formValues.mount_path;
    if (!key) return;

    const volumeResource = volumeResources.find(
      (r) => r.id === (volumeResources.length === 1 ? volumeResources[0]?.id : formValues.id)
    );
    const type = volumeResource?.type;
    const externalOrShared = volumeResource?.externalOrShared;
    delete formValues.mount_path;

    if (!formValues.sub_path) {
      delete formValues.sub_path;
    }

    const obj:
      | (Partial<VolumeMount> & { mount_path?: string })
      | Record<string, VolumeMount & { mount_path: string }> = {
      ...formValues,
      id: `${
        externalOrShared === 'shared' ? 'shared' : type === 'volume' ? 'externals' : 'volumes'
      }.${volumeResources.length === 1 ? volumeResources[0]?.id : formValues.id}`, // either volumes.{id} for emptyDir or externals.{id} for persistentVolumes
    };

    updateWorkload([
      {
        key,
        op: 'add',
        value: obj,
      },
    ]);
  };

  const handleConfirm = () => {
    if (volumeMount) {
      updateExisting();
    } else {
      createNew();
      reset();
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <FieldDropdown
          defaultText={'Select item'}
          name={'id'}
          label={sectionsTranslations.VOLUME_ID}
          disabled={readonly}
          items={volumeResources.map(({ id }) => ({ id, label: id, value: id }))}
          menuSize={'parent'}
          buttonVariant={'input'}
          fullWidth
          buttonSize={'medium'}
          hideError
        />
        <FieldInput
          labelAbove
          name={'mount_path'}
          label={sectionsTranslations.MOUNT_POINT}
          standardValidation={[
            { type: 'volume-path' },
            {
              type: 'existingId',
              ids: existingMountPaths.filter(
                (existingMountPath) => existingMountPath !== volumeMount?.mount_path
              ),
            },
          ]}
          readonly={readonly}
          required
          defaultValue={volumeMount?.mount_path || ''}
          validate={{
            noPrefix: (value: string) => {
              let hasPrefix = false;
              existingMountPaths
                .filter((existingMountPath) => existingMountPath !== volumeMount?.mount_path)
                .forEach((path) => {
                  if (path.indexOf(value) !== -1) {
                    hasPrefix = true;
                  }
                });
              if (hasPrefix) {
                return sectionsTranslations.NO_PREFIX;
              }

              return true;
            },
          }}
        />
        <FieldInput
          labelAbove
          name={'sub_path'}
          label={sectionsTranslations.SUBDIR}
          standardValidation={[{ type: 'sub-path' }]}
          defaultValue={volumeMount?.sub_path}
          readonly={readonly}
        />

        <FieldTitle id={'readonly-toggle'}>{sectionsTranslations.READONLY}</FieldTitle>
        <SecretFieldWrapper role={'group'} aria-labelledby={'readonly-toggle'}>
          <Toggle
            id={`read_only${volumeMount?.mount_path ? `_${volumeMount?.mount_path}` : ''}`}
            name={'read_only'}
            defaultChecked={volumeMount?.read_only}
            disabled={readonly}
            label={
              formValues.read_only
                ? sectionsTranslations.READONLY_TOGGLE_TEXT
                : sectionsTranslations.READWRITE_TOGGLE_TEXT
            }
          />
        </SecretFieldWrapper>

        {!readonly && (
          <ButtonContainer>
            {/* shows an indicator that the Save button was pressed */}
            {currentDeltaAction === 'add-volume-mount' ? (
              <LeftButton variant={'primary'} type={'submit'} disabled loading>
                {uiTranslations.SAVING}...
              </LeftButton>
            ) : (
              <LeftButton variant={'primary'} type={'submit'}>
                {uiTranslations.SAVE}
              </LeftButton>
            )}
            <Button variant={'secondary'} onClick={handleCancel}>
              {uiTranslations.CANCEL}
            </Button>
          </ButtonContainer>
        )}
      </form>
    </FormProvider>
  );
};

export default VolumeMountViewAddEdit;
