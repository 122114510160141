import { useTranslation } from 'react-i18next';

import ReadMore from '../ReadMore';

interface RoleDescriptionsProps {
  items: { role: string; description: string; hide?: boolean }[];
  roleType: 'org' | 'app' | 'env';
}

const RoleDescriptions = ({ items, roleType }: RoleDescriptionsProps) => {
  // i18n
  const { t } = useTranslation('orgMembers');

  return (
    <div className={'flex flex-column mb-xl'}>
      <span className={'py-xl'}>{t('DIFFERENT_ROLES')}</span>
      <span className={'pb-xl'}>
        <ReadMore roleType={roleType} />
      </span>
      <ul className={'pl-xl my-0'}>
        {items
          .filter((item) => !item.hide)
          .map((item, index) => (
            <li key={item.role} className={items.length - 1 !== index ? 'pb-md' : undefined}>
              <b>{item.role}</b>
              <div>{item.description}</div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default RoleDescriptions;
