import { Icon, Input } from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useRevertVersionHistoryMutation from '@src/hooks/react-query/shared-values/mutations/useRevertVersionHistoryMutation';
import {
  AppValueVersionDeleted,
  AppValueVersionPurged,
  AppValueVersionUnpurged,
} from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
import { units } from '@src/styles/variables';

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${units.margin.lg} 0;
  padding: ${units.padding.md};
  background: ${({ theme }) => theme.color.alertTransparentFlat};
`;

type SharedAppValueHistoryRevertProps = {
  keyValue: string;
  setOnSubmit: (onSubmitFunc: (onSubmitSuccess: () => void) => void) => void;
  /** If this component is used in Environment Override version values screen */
  isEnvironmentOverride: boolean;
  historyItem: AppValueVersionUnpurged | AppValueVersionPurged | AppValueVersionDeleted;
};
const SharedAppValueHistoryRevert = ({
  keyValue,
  setOnSubmit,
  isEnvironmentOverride,
  historyItem,
}: SharedAppValueHistoryRevertProps) => {
  const [revertError, setRevertError] = useState<string | undefined>(undefined);

  const { register, resetField, watch, setValue } = useFormContext();

  // translation
  const { t } = useTranslation();
  const valueHistoryTranslations = t('APP_SETTINGS').VALUE_HISTORY;

  const { mutate: revertVersion } = useRevertVersionHistoryMutation(
    keyValue,
    () => {
      resetField('comment');
      resetField('revertChangeId');
      // onRevertSubmit();
    },
    (err: AxiosError<{ message: string }>) => {
      setRevertError(err?.response?.data.message || '');
    }
  );

  const subscription = watch((formData) => {
    setOnSubmit((onSubmitSuccess) =>
      revertVersion(
        {
          comment: formData.comment,
          revertChangeId: formData.revertChangeId,
          isEnvironmentOverride,
        },
        { onSuccess: () => onSubmitSuccess() }
      )
    );
  });
  // reset comment value, in case moving from purge to revert modal. This also helps to solve the bug of loosing focus after entering first character
  useEffect(() => {
    setValue('comment', '');
  }, [setValue]);

  // unsubscribe form value subscription
  useEffect(() => () => subscription.unsubscribe(), [subscription, watch, revertVersion]);

  return (
    <>
      <p>
        {isEnvironmentOverride
          ? valueHistoryTranslations.REVERT_WARNING_ENV
          : valueHistoryTranslations.REVERT_WARNING}
      </p>
      <p>{valueHistoryTranslations.REVERT_WARNING2}</p>

      {revertError && <p>{revertError}</p>}
      {revertError !== undefined && (
        <ErrorWrapper>
          <Icon name={'warning'} marginRight={'md'} />
          <p>
            {valueHistoryTranslations.REVERT_ERROR + (revertError !== '' && ': ' + revertError)}
          </p>
        </ErrorWrapper>
      )}
      <Input
        fakeInput
        className={'mt-md'}
        fakeInputMinHeight={100}
        label={valueHistoryTranslations.VALUE}
        labelAbove
        name={'value'}
        readonly>
        {historyItem?.value}
      </Input>
      <Input
        fakeInput
        className={'mt-md'}
        fakeInputMinHeight={100}
        label={valueHistoryTranslations.DESCRIPTION}
        labelAbove
        name={'description'}
        readonly>
        {historyItem?.description}
      </Input>
      <input type={'hidden'} value={historyItem?.changeId} {...register('revertChangeId')} />
      <Input
        className={'mt-md'}
        label={valueHistoryTranslations.COMMENT}
        labelAbove
        name={'comment'}
        description={valueHistoryTranslations.REVERT_COMMENT_DESCRIPTION}
      />
    </>
  );
};
export default SharedAppValueHistoryRevert;
