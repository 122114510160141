import { SearchInput, WalTable, WalTableRow } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useUserDetailQuery from '@src/hooks/react-query/user/queries/useUserDetailQuery';
import useUserRolesQuery from '@src/hooks/react-query/user/queries/useUserRolesQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { EnvironmentType } from '@src/models/environment-type';
import { EnvTypeRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { capitalize } from '@src/utilities/string-utility';

import AdministratorWarning from '../../../components/AdministratorWarning';
import ChangeEnvTypeRoleModal from '../../../components/role-modals/ChangeEnvTypeRoleModal/ChangeEnvTypeRoleModal';

const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${units.padding.xl};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const OrgMemberEnvTypeRoles = () => {
  // Component state
  const [filterValue, setFilterValue] = useState<string>();

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // Form
  const formMethods = useWalhallForm();

  // React Query
  const { data: environmentTypes } = useEnvironmentTypesQuery();
  const { data: userRoles } = useUserRolesQuery();
  const { data: user } = useUserDetailQuery();

  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('ENVIRONMENT_TYPES_TAB');

  // RBAC
  const canCreateDeleteDeployer = useRBAC('createDeleteDeployer');

  return (
    <>
      <AdministratorWarning type={'env-type'} />
      <SearchInput
        className={'my-md'}
        name={'filter-env-type'}
        onChange={setFilterValue}
        placeholder={translations.FIND_ENV_TYPE}
      />
      <FormProvider {...formMethods}>
        <WalTable
          caption={translations.TABLE_CAPTION}
          columns={[
            {
              prop: 'environment-type',
              label: translations.ENVIRONMENT_TYPE,
              template: ({ data }: WalTableRow<EnvironmentType>) => data.id,
            },
            {
              prop: 'role',
              fixedWidth: 250,
              label: translations.ROLE,
              template: ({ data }: WalTableRow<EnvironmentType>) => {
                const envTypeRole = userRoles?.[`/orgs/${orgId}/env-types/${data.id}`] as
                  | EnvTypeRoles
                  | undefined;
                return (
                  <RoleGrid>
                    {capitalize(envTypeRole ?? '') || 'None'}
                    {user && canCreateDeleteDeployer && (
                      <ChangeEnvTypeRoleModal
                        user={user}
                        envTypeId={data.id}
                        envTypeRole={envTypeRole}
                      />
                    )}
                  </RoleGrid>
                );
              },
            },
          ]}
          rows={
            (filterValue
              ? environmentTypes?.filter((environmentType) =>
                  environmentType.id.toLowerCase()?.includes(filterValue.toLowerCase())
                )
              : environmentTypes
            )?.map((environmentType) => ({ data: environmentType })) ?? []
          }
        />
      </FormProvider>
    </>
  );
};

export default OrgMemberEnvTypeRoles;
