import { Icon } from '@humanitec/ui-components';
import { Mode } from '@humanitec/ui-components/src/base/WarningSection/WarningSection';
import React from 'react';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

const Wrapper = styled.div<{ mode?: Mode }>`
  display: flex;
  justify-content: flex-start;
  padding: ${units.padding.md};
  ${({ theme, mode }) =>
    mode === 'warning'
      ? css`
          border-left: 2px solid ${theme.color.yellow};
          background-color: ${theme.color.yellowTransparent};
        `
      : mode === 'info'
        ? css`
            border-left: 4px solid ${theme.color.main};
            background-color: ${theme.color.mainToast};
          `
        : mode === 'success'
          ? css`
              border-left: 2px solid ${theme.color.green};
              background-color: ${theme.color.greenTransparent};
            `
          : css`
              border-left: 4px solid ${theme.color.alert};
              background-color: ${theme.color.alertToast};
            `};
  color: ${({ theme }) => theme.color.text};
  width: 100%;
`;

const Title = styled.span`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.sm};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
interface ToastContentProps {
  data?: {
    title: string;
    message: string;
    mode?: Mode;
  };
}
export const ToastContent = ({ data }: ToastContentProps) => {
  return (
    <Wrapper mode={data?.mode || 'alert'}>
      <Icon
        size={20}
        name={data?.mode === 'info' ? 'info' : data?.mode === 'success' ? 'checkmark' : 'warning'}
        overrideColor={
          data?.mode === 'alert' ? 'alert' : data?.mode === 'success' ? 'green' : 'main'
        }
        marginRight={'md'}
      />
      <TextWrapper>
        <Title>{data?.title}</Title>
        <span className={'txt-sm txt-translucent'}>{data?.message}</span>
      </TextWrapper>
    </Wrapper>
  );
};
