import { useTranslation } from 'react-i18next';

import RoleDescriptions from './RoleDescriptions';

export const OrgRoleDescriptions = () => {
  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('ORG_MEMBERS_LIST').ORG_ROLE_MODAL;

  return (
    <RoleDescriptions
      roleType={'org'}
      items={[
        {
          role: translations.MEMBER,
          description: translations.MEMBER_DESC,
        },
        {
          role: translations.MANAGER,
          description: translations.MANAGER_DESC,
        },
        {
          role: translations.ORG_VIEWER,
          description: translations.ORG_VIEWER_DESC,
        },
        {
          role: translations.ADMINISTRATOR,
          description: translations.ADMINISTRATOR_DESC,
        },
      ]}
    />
  );
};
