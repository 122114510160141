import { Button, Spinner, WalCard, WarningSection } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AppHeader from '@src/components/shared/AppHeader/AppHeader';
import NavigationBar from '@src/components/shared/NavigationBar/NavigationBar';
import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useConfirmDeviceLoginMutation from '@src/hooks/react-query/user/mutations/useConfirmDeviceLoginMutation';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/queries/useGetCurrentUserQuery';
import useDeviceLoginQuery from '@src/hooks/react-query/user/queries/useGetDeviceLoginQuery';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Container = styled(PageContainer)`
  flex-direction: column;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Grid = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

interface SectionProps {
  title: string;
  data: string | undefined;
  removeMargin?: boolean;
}

const Section = ({ title, data, removeMargin }: SectionProps) => {
  return (
    <div className={!removeMargin ? 'mb-md' : undefined}>
      <div className={'txt-base'}>{title}</div>
      <div className={'txt-base txt-translucent'}>{data}</div>
    </div>
  );
};

const Device = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('DEVICE');
  // Component state
  const [acceptedOrRejected, setAcceptedOrRejected] = useState<'accepted' | 'rejected'>();

  // Router hooks
  const { hash } = useLocation();

  const searchParams = new URLSearchParams(hash);
  const login_code = searchParams.get('#login_code');

  // React Query
  const { data: currentUser } = useGetCurrentUserQuery();
  const {
    data: deviceData,
    isError: loadLoginError,
    isLoading: loginDeviceLoading,
  } = useDeviceLoginQuery(login_code);
  const {
    mutate: confirmLogin,
    isPending: isAuthenticating,
    isSuccess: confirmIsSuccess,
  } = useConfirmDeviceLoginMutation();

  useEffect(() => {
    if (acceptedOrRejected && login_code) {
      confirmLogin({ approved: acceptedOrRejected === 'accepted', login_code });
    }
  }, [acceptedOrRejected, confirmLogin, login_code]);

  return (
    <Wrapper>
      <NavigationBar />
      <MainContent>
        <AppHeader />
        <Container>
          <PageHeader customHeading={translations.AUTHORIZE_CLI} />
          {loginDeviceLoading ? (
            <Spinner />
          ) : loadLoginError ? (
            <div className={'txt-base'}>{translations.NO_LOGIN_FOUND}</div>
          ) : (
            deviceData &&
            login_code && (
              <>
                <div className={'txt-base mb-sm'}>
                  <Link
                    className={'txt-base'}
                    to={'https://developer.humanitec.com/platform-orchestrator/cli/'}
                    target={'_blank'}>
                    {translations.HUMANITEC_CLI}
                  </Link>
                  {translations.CLI_HAS_ACCESS}
                </div>
                <div className={'txt-base mb-lg'}>{translations.LOGIN_TOKENS_RESET}</div>
                <WalCard cardStyle={'transparent'} className={'mb-lg'}>
                  <Section title={translations.ACCOUNT_NAME} data={currentUser?.name} />
                  <Section title={translations.EMAIL} data={currentUser?.email} removeMargin />
                </WalCard>
                <WarningSection showIcon>
                  <div className={'txt-base mb-md'}>{translations.REQUESTED_FROM}</div>
                  <Section title={translations.DEVICE_CODE} data={login_code} />
                  <Grid>
                    <Section title={translations.COUNTRY} data={deviceData?.location.country} />
                    <Section title={translations.CITY} data={deviceData?.location.city} />
                    <Section title={translations.IP_ADDRESS} data={deviceData?.ip_address} />
                  </Grid>
                  <Section
                    title={translations.MADE_AT}
                    data={formatDate(
                      deviceData?.created_at,
                      DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
                    )}
                  />
                  <span className={'txt-base'}>{translations.TRUST_DEVICE}</span>
                </WarningSection>
                {confirmIsSuccess ? (
                  acceptedOrRejected === 'accepted' ? (
                    <WarningSection showIcon mode={'success'}>
                      {translations.SUCCESS}
                    </WarningSection>
                  ) : (
                    <WarningSection showIcon mode={'alert'}>
                      {translations.REJECTED}
                    </WarningSection>
                  )
                ) : (
                  <div>
                    <Button
                      size={'small'}
                      className={'mr-md'}
                      disabled={isAuthenticating}
                      onClick={() => {
                        setAcceptedOrRejected('accepted');
                      }}>
                      {translations.AUTHORIZE_BUTTON}
                    </Button>
                    <Button
                      size={'small'}
                      variant={'secondary'}
                      onClick={() => {
                        setAcceptedOrRejected('rejected');
                      }}>
                      {translations.REJECT_BUTTON}
                    </Button>
                  </div>
                )}
              </>
            )
          )}
        </Container>
      </MainContent>
    </Wrapper>
  );
};

export default Device;
