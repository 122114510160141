import { Button, MenuItem, WalMenu, WalTab } from '@humanitec/ui-components';
import { rgba } from 'polished';
import { KeyboardEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';
import { generateContainerURL } from '@src/utilities/navigation';

const Tab = styled(WalTab)`
  margin-right: ${units.margin.md};
  overflow: visible;
  ${({ removed, theme }) =>
    removed &&
    css`
      background-color: ${rgba(theme.color.baseOutline, 0.48)};
      pointer-events: none;
    `}
`;

const ContainerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ContainerTabName = styled.span<{ removed: boolean; $readonly?: boolean }>`
  ${({ $readonly }) =>
    !$readonly &&
    css`
      margin-right: ${units.margin.md};
    `};
  ${({ removed }) =>
    removed &&
    css`
      opacity: 0.48;
    `};
`;

const RestoreButton = styled(Button)`
  pointer-events: all;
`;

interface ContainerTabProps {
  container: { id: string; removed: boolean };
  containers: { id: string; removed: boolean }[];
  currentContainerId?: string;
  readonly?: boolean;
  onClick?: (e: MouseEvent, containerId: string) => void;
  onKeyDown?: (e: KeyboardEvent, containerId: string) => void;
}

const ContainerTab = ({
  container,
  containers,
  currentContainerId,
  readonly,
  onClick,
  onKeyDown,
}: ContainerTabProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Component state
  const containerMenuItems = [{ value: 'remove', label: uiTranslations.REMOVE }];

  // Router hooks
  const params = useParams<keyof MatchParams>() as MatchParams;
  const { moduleId } = params;

  const { updateWorkload } = useDeltaUtils('spec/containers');

  const handleContainerMenuClick = (option: MenuItem<string>, e: MouseEvent | KeyboardEvent) => {
    // Stop handleContainerTabClick from being called
    if (option.value === 'remove') {
      e.preventDefault();

      updateWorkload([
        {
          key: container.id,
          op: 'remove',
        },
      ]);
    }
  };

  const restoreContainer = () => {
    updateWorkload([
      {
        key: container.id,
        op: 'remove',
        value: { scope: 'delta' },
      },
    ]);
  };

  const handleKeyDown = (e: KeyboardEvent, containerId: string) => {
    if (e.keyCode === KeyBindings.ENTER && onKeyDown) {
      onKeyDown(e, containerId);
    }
  };

  const handleClick = (e: MouseEvent, containerId: string) => {
    if (onClick) {
      onClick(e, containerId);
    }
  };

  return (
    <Tab
      selected={currentContainerId === container.id}
      $noPadding
      removed={container.removed}
      onClick={(e) => handleClick(e, container.id)}
      onKeyDown={(e) => handleKeyDown(e, container.id)}
      tabIndex={onKeyDown ? 0 : undefined}
      dataTestId={`${container.id}-tab`}
      link={
        !onClick
          ? {
              to: generateContainerURL(params, moduleId, container.id),
            }
          : undefined
      }>
      <ContainerNameWrapper>
        <ContainerTabName $readonly={readonly} removed={container.removed}>
          {container.id}
        </ContainerTabName>
        {containers.length > 1 && !readonly && !container.removed && (
          <WalMenu
            onItemClick={(option, e) => handleContainerMenuClick(option, e)}
            standardToggle={{
              type: 'dots',
              objectType: 'container',
              objectName: container.id,
              preventDefault: true,
            }}
            items={containerMenuItems}
          />
        )}
        {container.removed && (
          <RestoreButton variant={'secondary'} size={'small'} onClick={restoreContainer}>
            {uiTranslations.RESTORE}
          </RestoreButton>
        )}
      </ContainerNameWrapper>
    </Tab>
  );
};

export default ContainerTab;
