import { Button, EmptyStateCard } from '@humanitec/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import NewDiffContent from '@src/components/NewDiffContent/NewDiffContent';
import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useDeploymentDeltaCreateMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaCreateMutation';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentSet } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { diffDeploymentSets } from '@src/utilities/deployment-delta-utils';
import makeRequest from '@src/utilities/make-request';

import SelectDeploymentCard from './components/SelectDeploymentCard';
import SelectDeploymentModal from './components/SelectDeploymentModal';

const Container = styled(PageContainer)`
  flex-direction: column;
  overflow: auto;
`;

const DeploymentsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${units.margin.md};
  margin-bottom: ${units.margin.lg};
`;

const CompareDeployments = () => {
  // i18n
  const { t } = useTranslation();
  const compareDeploymentsTranslations = t('COMPARE_DEPLOYMENTS');

  // Router
  const { leftDeploymentId, rightDeploymentId, appId, orgId, envId } = useParams<
    keyof MatchParams
  >() as MatchParams;

  // State
  const [currentDelta, setCurrentDelta] = useState<DeploymentDelta>();
  const [rightDeploymentSet, setRightDeploymentSet] = useState<DeploymentSet>();
  const [selectDeploymentsModalOpen, setSelectDeploymentsModalOpen] = useState<boolean>(false);
  const [selectingSide, setSelectingSide] = useState<'left' | 'right'>('left');

  // React Query
  const { mutate: createDeploymentDeltaMutation } = useDeploymentDeltaCreateMutation();
  const { data: environmentDeployments = [] } = useDeploymentsListQuery();

  // RBAC
  const canCreateDraft = useRBAC('accessDraftURL');

  const leftDeployment = useMemo(() => {
    return environmentDeployments.find((deploy) => deploy.id === leftDeploymentId);
  }, [environmentDeployments, leftDeploymentId]);

  const rightDeployment = useMemo(() => {
    return environmentDeployments.find((deploy) => deploy.id === rightDeploymentId);
  }, [environmentDeployments, rightDeploymentId]);

  useEffect(() => {
    if (rightDeployment && leftDeployment) {
      makeRequest<DeploymentSet>(
        'get',
        `/orgs/${orgId}/apps/${appId}/sets/${rightDeployment.set_id}`
      ).then((res) => {
        if (res) {
          setRightDeploymentSet(res.data);
        }
      });
      diffDeploymentSets(leftDeployment.set_id, rightDeployment.set_id, appId, orgId).then(
        (diffDelta) => {
          if (diffDelta) {
            setCurrentDelta(diffDelta);
          } else {
            setCurrentDelta(undefined);
          }
        }
      );
    }
  }, [rightDeployment, leftDeployment, appId, orgId]);

  const createDraft = () => {
    createDeploymentDeltaMutation({
      envId,
      fromDelta: currentDelta,
      name: `diff from ${envId}`,
      navigateAfterCreation: 'base-path',
    });
  };

  const openSelectDeplymentModal = (side: 'left' | 'right') => {
    setSelectDeploymentsModalOpen(true);
    setSelectingSide(side);
  };

  return (
    <Container>
      <PageHeader
        rightContent={
          canCreateDraft && (
            <Button variant={'primary'} onClick={createDraft} disabled={!currentDelta}>
              {compareDeploymentsTranslations.CREATE_DRAFT_FROM_DIFF}
            </Button>
          )
        }
      />

      <DeploymentsWrapper>
        <SelectDeploymentCard
          title={compareDeploymentsTranslations.COMPARE}
          deploy={leftDeployment}
          buttonText={compareDeploymentsTranslations.CHANGE}
          buttonVariant={'secondary'}
          buttonCallback={() => openSelectDeplymentModal('left')}
        />
        <SelectDeploymentCard
          title={compareDeploymentsTranslations.TO}
          deploy={rightDeployment}
          buttonText={compareDeploymentsTranslations.CHANGE}
          buttonVariant={'secondary'}
          buttonCallback={() => openSelectDeplymentModal('right')}
        />
      </DeploymentsWrapper>
      {rightDeploymentSet && currentDelta ? (
        <NewDiffContent
          delta={currentDelta}
          rightDeploymentSet={rightDeploymentSet}
          accordionCardStyle={'default'}
        />
      ) : (
        <EmptyStateCard>
          <span className={'txt-md'}>{compareDeploymentsTranslations.NO_DEPLOYMENTS_SELECTED}</span>
          <span>{compareDeploymentsTranslations.SELECT_TWO_DEPLOYMENTS}</span>
        </EmptyStateCard>
      )}
      {selectDeploymentsModalOpen && (
        <SelectDeploymentModal
          openState={[selectDeploymentsModalOpen, setSelectDeploymentsModalOpen]}
          leftDeployment={leftDeployment}
          rightDeployment={rightDeployment}
          selectingSide={selectingSide}
        />
      )}
    </Container>
  );
};

export default CompareDeployments;
