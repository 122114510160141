import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';

import DisplayDeploymentErrors from '@src/components/shared/DisplayDeploymentErrors';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';

import OldDeploymentBanner from './components/OldDeploymentBanner';
import ViewDeploymentOrDeltaTabs from './components/ViewDeploymentOrDeltaTabs/ViewDeploymentOrDeltaTabs';
import ViewDeploymentPageHeader from './components/ViewDeploymentPageHeader';

const Container = styled(PageContainer)`
  flex-direction: column;
  flex: 1;
`;

const ViewDeploymentAndDeltaCommon = () => {
  // Router hooks
  const { deployId } = useParams<keyof MatchParams>() as MatchParams;
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // React Query
  const { data: deploymentErrors } = useDeploymentErrorsQuery(deployId);

  return (
    <>
      <OldDeploymentBanner />
      <Container>
        <ViewDeploymentPageHeader />
        {deploymentErrors && !draftModeActive && deployId && (
          <DisplayDeploymentErrors errors={deploymentErrors} scope={'environment'} />
        )}
        <ViewDeploymentOrDeltaTabs />
        <Outlet />
      </Container>
    </>
  );
};

export default ViewDeploymentAndDeltaCommon;
