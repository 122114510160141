import { Input } from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

const NameChangeWarning = styled.div`
  margin-top: ${units.margin.md};
  font-size: ${units.fontSize.sm};
`;

interface OrganizationNameInputProps {
  error?: AxiosError | null;
}

const OrganizationNameInput = ({ error }: OrganizationNameInputProps) => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  // Form
  const { formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const onChange = () => {
    if (errors.organization_name) {
      clearErrors('organization_name');
    }
  };

  useEffect(() => {
    if (error?.response?.status === 409) {
      setError('organization_name', {
        type: 'validate',
        message: authTranslations.ORGANIZATION_NAME_IS_TAKEN,
      });
    }
  }, [authTranslations.ORGANIZATION_NAME_IS_TAKEN, error, setError]);

  return (
    <>
      <Input
        required
        label={authTranslations.ORGANIZATION_NAME}
        name={'organization_name'}
        standardValidation={[{ type: 'name' }]}
        onChange={onChange}
      />
      <NameChangeWarning>{authTranslations.ORGANIZATION_NAME_CHANGE}</NameChangeWarning>
    </>
  );
};

export default OrganizationNameInput;
