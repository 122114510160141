import { rem } from 'polished';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

export type DotColor = 'blue' | 'red' | 'green' | 'yellow' | 'base' | 'text' | 'text-translucent';
/**
 * A dot component which can appear in five colors 'red', 'green', 'blue', 'yellow', 'base'
 * change color by passing color property color="blue"
 */
export const Dot = styled.div<{ color: DotColor }>`
  background-color: ${({ theme, color }) => {
    switch (color) {
      case 'red':
        return theme.color.alert;
      case 'green':
        return theme.color.green;
      case 'yellow':
        return theme.color.yellow;
      case 'base':
        return theme.color.baseOutline;
      case 'text':
        return theme.color.text;
      case 'text-translucent':
        return theme.color.textTranslucent;
      default:
        return theme.color.main;
    }
  }};
  width: ${rem(6)};
  height: ${rem(6)};
  margin-right: ${units.margin.xs};
  border-radius: 50%;
`;
