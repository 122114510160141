import { InfoPopup } from '@humanitec/ui-components';
import { rem } from 'polished';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

interface SectionTileWrapperProps {
  editable?: boolean;
}

const SectionTileWrapper = styled.section<SectionTileWrapperProps>`
  position: relative;
  border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
  padding: ${units.padding.xl};
  margin-bottom: ${units.margin.lg};
  border-radius: ${rem(4)};
  width: 100%;
  ${({ editable }) =>
    editable &&
    css`
      background-color: ${({ theme }) => theme.color.baseLayerTransparent};
      border: 0;
    `}
`;

const SectionTitle = styled.h3`
  margin-top: 0;
  display: flex;
  align-items: center;
`;

const SectionTileContentWrapper = styled.div<{ removeTitleMargin?: boolean }>`
  margin-bottom: ${units.margin.md};
`;

export interface SectionTileProps {
  title?: string;
  editable?: boolean;
  children?: ReactNode;
  id?: string;
  infoPopup?: {
    text: string;
    link?: string;
    position?: 'top' | 'right' | 'bottom';
    width?: number;
  };
  className?: string;
}

const SectionTile = ({ id, title, editable, children, infoPopup, className }: SectionTileProps) => (
  <SectionTileWrapper editable={editable} aria-label={title} id={id} className={className}>
    <SectionTileContentWrapper>
      {title && (
        <SectionTitle>
          {title}
          {infoPopup?.text && (
            <InfoPopup
              moreInformationLink={infoPopup.link}
              text={infoPopup.text}
              position={infoPopup.position}
              width={infoPopup.width}
            />
          )}
        </SectionTitle>
      )}
    </SectionTileContentWrapper>
    {children}
  </SectionTileWrapper>
);

export default SectionTile;
