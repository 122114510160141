import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { Role, RoleType } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { groupsQueryKeys } from '../../groups/groupsQueryKeys';
import { userQueryKeys } from '../../user/userQueryKeys';
import { generateRoleQueryKeyBasedOnRoleType, generateRolesURL } from '../rolesQueryKeys';

interface UseRoleUpdateMutationVariables {
  roleId: string;
  newRole: string;
}

const useRoleUpdateMutation = (roleType?: RoleType) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Role>,
    AxiosError,
    UseRoleUpdateMutationVariables
  >({
    mutationFn: ({ roleId, newRole }) =>
      makeRequest('PATCH', `${generateRolesURL(orgId, roleType)}/${roleId}`, {
        role: newRole,
      }),
    onSuccess: (res) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: generateRoleQueryKeyBasedOnRoleType(orgId, roleType),
      });

      queryClient.invalidateQueries({
        queryKey: groupsQueryKeys.list(orgId),
      });

      // Invalidate org user
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.user(orgId, res.data.id),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useRoleUpdateMutation;
